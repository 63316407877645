import * as React from 'react'

import { ReactKeycloakProvider } from '@react-keycloak/web'

import keycloak from './keycloak'

const eventLogger = (event: unknown, error: unknown) => {
    if(error){
        console.error('onKeycloakEvent', error)
        if (error === 'onAuthRefreshError') {
          keycloak.login()
        }

        
    } else {
        console.log('onKeycloakEvent', event)
    }
}

const tokenLogger = () => {
  console.log('onKeycloakTokens') // no loggear tokens
}

const AuthProvider: React.FC = props => {
    return(
        
            <ReactKeycloakProvider
            authClient={keycloak}
            onEvent={eventLogger}
            onTokens={tokenLogger}
            initOptions={
              {
                checkLoginIframe: false,
                onLoad: 'login-required'
              }
            }
            >
                {props.children}
            </ReactKeycloakProvider>
        
    )
}
export default AuthProvider