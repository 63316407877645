import * as React from "react";
import { useState, useEffect } from 'react';
import { FieldErrorsImpl } from "react-hook-form";
import { SolicitudProducto3 } from "../../../../../types/productos/SolicitudProducto3";
import { Breadcrumb, Button, Card, Col, Row, Form, InputGroup } from "react-bootstrap";
import {
    Paper,
    Box,
    Grid,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem
  } from '@material-ui/core';
import { Solicitud } from "../../../../../types/productos/SolicitudProducto";
interface Props {
    title:string;
    register : any;
    errors : FieldErrorsImpl<SolicitudProducto3>;
    setValue : any;
    data : SolicitudProducto3;
    trigger:any;
    setError : any;
    clearErrors: any;
    reset : any;
    solicitud : Solicitud;
}

export const SolicitudHead: React.FC<Props> = ({title,register,errors,setValue,data,trigger,setError,clearErrors,reset,solicitud}) => {
    const [moneda,setMoneda]            = useState<number>((solicitud ? solicitud.moneda : null));
    const chgMoneda = (value: number) => {
        setMoneda(value);
        console.log(value)
        setValue("moneda",(value as SolicitudProducto3["moneda"]));
        clearErrors("moneda")
    }
    return (
        <Card className="custom-card">
            <Card.Body>
                <Row >
                    <Grid item xs={12} sm={12} md={12} style={{marginTop:"-20px",marginBottom:"15px", textAlign:"center"}} >
                        <h3>{title}</h3>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Form.Group className="form-group">
                            <p className="mg-b-1 fontSm" >Lugar:</p>
                            <Form.Control
                                size="sm"
                                type="text" 
                                id="lugar"
                                readOnly
                                defaultValue={"BUENOS AIRES"}
                                placeholder="Lugar"
                                className={(errors.lugar ? "form-control is-invalid state-invalid" : "form-control")}
                                {...register("lugar", { required: true, maxLength: 100 })}
                            />
                            {errors.lugar && errors.lugar.type.toString() === 'required' && <div className="invalid-feedback">Requerido</div> }
                            {errors.lugar && errors.lugar.type.toString() === 'maxLength' && <div className="invalid-feedback">Máximo 100 caracteres</div> }
                        </Form.Group>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <p className="mg-b-1 fontSm" >Moneda<span className="tx-danger">*</span>:</p>
                        <Row >
                            <Col lg={6} onClick={() => {chgMoneda(1)}}>
                                <Form.Check name="moneda" type="radio" value={1}
                                    label="En Pesos"
                                    checked={(moneda === 1)}
                                    className={(errors.moneda ? "is-invalid state-invalid" : "")}
                                    {...register("moneda", { required: true })}
                                />
                            </Col>
                            <Col lg={6}  onClick={() => {chgMoneda(2)}}>
                                <Form.Check name="moneda" type="radio" value={2}
                                    label="En dólares estadounidenses"
                                    id="radUs"
                                    checked={(moneda === 2)}
                                    className={(errors.moneda ? "is-invalid state-invalid" : "")}
                                    {...register("moneda", { required: true })}
                                />
                            </Col>
                        </Row>
                        {errors.moneda && errors.moneda.type.toString() === 'required' && <div style={{fontSize: "80%",color: "#f16d75"}}>Requerido</div> }
                    </Grid>
                </Row>
                {/*<Row >
                    <Grid item xs={12} sm={12} md={12}>

                        <p className="mg-b-1 fontSm" >Solicito(amos) la apertura de una Caja de Ahorros a la orden de<span className="tx-danger">*</span>:</p>
                        <Form.Control
                            size="sm"
                            type="text" 
                            id="alaordende"
                            placeholder="A la orden de"
                            className={(errors.aLaOrdenDe ? "form-control is-invalid state-invalid" : "form-control")}
                            {...register("aLaOrdenDe", { required: true, maxLength: 255 })}
                        />
                        {errors.aLaOrdenDe && errors.aLaOrdenDe.type.toString() === 'required' && <div className="invalid-feedback">Requerido</div> }
                        {errors.aLaOrdenDe && errors.aLaOrdenDe.type.toString() === 'maxLength' && <div className="invalid-feedback">Máximo 255 caracteres</div> }

                    </Grid>
                </Row>
                <Row >
                    <Grid item xs={12} sm={6} md={6} style={{marginTop:"5px"}}>

                        <p className="mg-b-1 fontSm" >Aclaración Firma 1<span className="tx-danger">*</span>:</p>
                        <Form.Control
                            size="sm"
                            type="text" 
                            id="aclaracionFirma1"
                            placeholder="Aclaración Firma 1"
                            className={(errors.aclaracionFirma1 ? "form-control is-invalid state-invalid" : "form-control")}
                            {...register("aclaracionFirma1", { required: true, maxLength: 100 })}
                        />
                        {errors.aclaracionFirma1 && errors.aclaracionFirma1.type.toString() === 'required' && <div className="invalid-feedback">Requerido</div> }
                        {errors.aclaracionFirma1 && errors.aclaracionFirma1.type.toString() === 'maxLength' && <div className="invalid-feedback">Máximo 100 caracteres</div> }

                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{marginTop:"5px"}}>

                        <p className="mg-b-1 fontSm" >Aclaración Firma 2<span className="tx-danger"></span>:</p>
                        <Form.Control
                            size="sm"
                            type="text" 
                            id="aclaracionFirma2"
                            placeholder="Aclaración Firma 2"
                            className={(errors.aclaracionFirma2 ? "form-control is-invalid state-invalid" : "form-control")}
                            {...register("aclaracionFirma2", {  maxLength: 100 })}
                        />
                        {errors.aclaracionFirma2 && errors.aclaracionFirma2.type.toString() === 'maxLength' && <div className="invalid-feedback">Máximo 100 caracteres</div> }

                    </Grid>
                </Row>
                */}
            </Card.Body>
        </Card>
    );
}