export interface ResponseContainer<T> {
    data: T;
    pageNo: number;
    recordsFiltered: number;
    recordsTotal: number;
    totalPages: number;
}


export interface GenericKeyValue<T,R> {
    key: T;
    value: R;
}

export interface tableData {
    name? : string;
    selector?: any;
    cell?:any;
    sortable: boolean;
  }

  export enum EnumTipoDocumento {
    PDF_DOC_MAIN = 1,//1=form/pdf que el cliente debe completar principal (form web )
    PDF_DOC_ADDITIONAL = 2,//form/pdf que el cliente debe completar adicional
    ONLY_UPLOAD_DOC = 3 //documento que no se baja, el cliente debe subirlo, ej: foto DNI, COMPROBANTE CUIT, ETC
  }

  export enum EnumEstadoCivil {
    CASADO = "Casado/a"
  }