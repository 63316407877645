import { Solicitud } from "../../../../types/productos/SolicitudProducto";
import * as React from "react";
import { useState, useEffect } from 'react';
import { Button, Col, Modal, Row } from "react-bootstrap";
import { fechaScreen } from "../../../../utils/GeneralUtils";
import { EnumWorkflow } from "../../../../types/general/Workflow";

interface Props {
    solicitud: Solicitud;

}


export const ModalHeaderSolicitud = ({solicitud} : Props) => {
    
    return (<>
    
    <Row className="row-sm">
        <Col md={4} lg={4} xl={4} >
        <div className="">
            <div className="form-group">
            <label className="fontBold">Cliente</label>
            <p>{solicitud.perfilCliente.givenName + ' ' + solicitud.perfilCliente.familyName}</p>
            </div>
        </div>
        </Col>
        <Col md={4} lg={4} xl={4} >
        <div className="">
            <div className="form-group">
            <label className="fontBold">CUIT/CUIL</label>
            <p>{solicitud.perfilCliente.cuilCuit}</p>
            </div>
        </div>
        </Col>
        <Col md={4} lg={4} xl={4} >
        <div className="">
            <div className="form-group">
            <label className="fontBold">Fecha Solicitud</label>
            <p>{solicitud.fechaSolicitud && fechaScreen(new Date(solicitud.fechaSolicitud))}</p>
            </div>
        </div>
        </Col>
        <Col md={4} lg={4} xl={4} >
        <div className="">
            <div className="form-group">
            <label className="fontBold">Fecha Respuesta</label>
            <p>{solicitud.productoSolicitudRespuesta && fechaScreen(new Date(solicitud.productoSolicitudRespuesta.fechaRespuesta))}</p>
            </div>
        </div>
        </Col>
        <Col md={4} lg={4} xl={4} >
            <div className="">
                <div className="form-group">
                <label className="fontBold">Estado</label>
                <p>{solicitud.workflow.descripcion}</p>
                </div>
            </div>
        </Col>
        {solicitud.workflow.id === EnumWorkflow.RECHAZADO &&
            <>
                <Col md={4} lg={4} xl={4} >
                    <div className="">
                        <div className="form-group">
                        <label className="fontBold">Motivo Rechazo</label>
                        <p>{solicitud.productoSolicitudRespuesta.motivoRechazo}</p>
                        </div>
                    </div>
                </Col>
            </>
        }
        {solicitud.workflow.id === EnumWorkflow.APROBADO &&
            <>
                <Col md={4} lg={4} xl={4} >
                    <div className="">
                        <div className="form-group">
                        <label className="fontBold">Obs. de aprobación</label>
                        <p>{solicitud.productoSolicitudRespuesta.observacion}</p>
                        </div>
                    </div>
                </Col>
            </>
        }
        {solicitud.workflow.id === EnumWorkflow.PRODUCTO_ACTIVO &&
            <>
                <Col md={4} lg={4} xl={4} >
                    <div className="">
                        <div className="form-group">
                        <label className="fontBold">CBU / Nro. de cuenta</label>
                        <p>{solicitud.productoSolicitudRespuesta.cbu} / {solicitud.productoSolicitudRespuesta.nroCa}</p>
                        </div>
                    </div>
                </Col>
            </>
        }
        {(solicitud.workflow.id > 6) &&
            <>
                <Col md={4} lg={4} xl={4} >
                    <div className="">
                        <div className="form-group">
                        <label className="fontBold">Fecha Solicitud Baja</label>
                        <p>{solicitud.productoSolicitudRespuesta && fechaScreen(new Date(solicitud.productoSolicitudRespuesta.fechaSolicitudBaja))}</p>
                        </div>
                    </div>
                </Col>
            </>
        }
    </Row>
    </>)
}