import * as React from "react";
import { Breadcrumb, Button, Card, Col, Row, Form, InputGroup,Table } from "react-bootstrap";

import {Grid} from '@material-ui/core';
import { formRequired, InputCustom } from "../../../../../Commons/InputsCustom";
import { SolicitudProducto3 } from "../../../../../../types/productos/SolicitudProducto3";
import { FieldErrorsImpl } from "react-hook-form";

interface Props {
    errors: FieldErrorsImpl<SolicitudProducto3>;
    register:any;
}

export const DomicilioReal: React.FC<Props> = ({errors,register}) => {

    return (
        <>
        <Grid item xs={12} sm={12} md={12} style={{marginTop:"0px",marginBottom:"15px", textAlign:"center"}} >
            <h5>Domicilio Real</h5>
        </Grid>
        <Grid item xs={12} sm={12} md={6} >
            <Row>
                <Grid item xs={7} sm={7} md={8}>
                    <InputCustom 
                        validationList={[{message:"Requerido",type:"required"},{message:"Máximo 128 caracteres",type:"maxLength"}] as formRequired[]} 
                        errors={errors} id="calle" 
                        label="Calle" 
                        register={register("calle", { required:true,maxLength: 128 })} 
                        />
                </Grid>
                <Grid item xs={5} sm={5} md={4}>
                    <InputCustom 
                        validationList={[{message:"Requerido",type:"required"},{message:"Máximo 12 caracteres",type:"maxLength"}] as formRequired[]} 
                        errors={errors} id="altura" 
                        label="Altura" 
                        register={register("altura", { required:true,maxLength: 12 })} 
                        />
                </Grid>
            </Row>
        </Grid>
        

        <Grid item xs={12} sm={12} md={6} >
            <Row>
                <Grid item xs={4} sm={4} md={4}>
                    <InputCustom 
                        validationList={[{message:"Máximo 12 caracteres",type:"maxLength"}] as formRequired[]} 
                        errors={errors} id="piso" 
                        label="Piso" 
                        register={register("piso", { maxLength: 12 })} 
                        />
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <InputCustom 
                        validationList={[{message:"Máximo 12 caracteres",type:"maxLength"}] as formRequired[]} 
                        errors={errors} id="dpto" 
                        label="Dpto" 
                        register={register("dpto", { maxLength: 12 })} 
                        />
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <InputCustom 
                        validationList={[{message:"Requerido",type:"required"},{message:"Máximo 24 caracteres",type:"maxLength"}] as formRequired[]} 
                        errors={errors} id="codPostal" 
                        label="C.P. ampliado" 
                        register={register("codPostal", {required:true, maxLength: 24 })} 
                        />
                </Grid>
            </Row>
        </Grid>

        <Grid item xs={12} sm={12} md={12} style={{marginBottom:"15px"}} >
            <Row>
                <Grid item xs={12} sm={12} md={6}>
                    <InputCustom 
                        validationList={[{message:"Requerido",type:"required"},{message:"Máximo 32 caracteres",type:"maxLength"}] as formRequired[]} 
                        errors={errors} id="localidad" 
                        label="Localidad" 
                        register={register("localidad", {required:true, maxLength: 32 })} 
                        />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <InputCustom 
                        validationList={[{message:"Requerido",type:"required"},{message:"Máximo 32 caracteres",type:"maxLength"}] as formRequired[]} 
                        errors={errors} id="provincia" 
                        label="Provincia" 
                        register={register("provincia", {required:true, maxLength: 32 })} 
                        />
                </Grid>
            </Row>
        </Grid>
        </>
    )
}