import * as React from "react";
import { Breadcrumb, Button, Card, Col, Row, Form, InputGroup,Table } from "react-bootstrap";

import {Grid} from '@material-ui/core';
import { formRequired, InputCustom, RadioBooleanCustom, SelectCustom } from "../../../../../Commons/InputsCustom";
import { SolicitudProducto3 } from "../../../../../../types/productos/SolicitudProducto3";
import { FieldErrorsImpl } from "react-hook-form";
import PerfilCliente from "../../../../../../types/personas/PerfilCliente";
import { prestadorCelularList } from "../../../../../../data/forms/GeneralData";

interface Props {
    errors: FieldErrorsImpl<SolicitudProducto3>;
    register:any;
    setValue:any;
    prestadorCelular:any;
    cb:any
}

export const DatosContacto: React.FC<Props> = ({errors,register,setValue,prestadorCelular,cb}) => {
    return (<>
        <Grid item xs={12} sm={12} md={6} >
            <Row>
                <Grid item xs={12} sm={12} md={6}>
                    <InputCustom 
                        validationList={[{message:"Requerido",type:"required"},{message:"Máximo 45 caracteres",type:"maxLength"}] as formRequired[]} 
                        errors={errors} id="telefono" 
                        label="Teléfono Línea" 
                        setValue={setValue}
                        register={register("telefono", { required:true,maxLength: 45 })} 
                        />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <InputCustom 
                        validationList={[{message:"Requerido",type:"required"},{message:"Máximo 32 caracteres",type:"maxLength"}] as formRequired[]} 
                        errors={errors} id="celular" 
                        label="Celular"
                        setValue={setValue}
                        register={register("celular", { required:true,maxLength: 32 })} 
                        />
                </Grid>
            </Row>
        </Grid>
        <Grid item xs={12} sm={12} md={6} >
            <Row>
                <Grid item xs={12} sm={12} md={12}>
                    {/*<InputCustom 
                        validationList={[{message:"Requerido",type:"required"},{message:"Máximo 32 caracteres",type:"maxLength"}] as formRequired[]} 
                        errors={errors} id="prestadorCelular" 
                        label="Prestador Celular" 
                       
                        register={register("prestadorCelular", { required:true,maxLength: 32 })} 
                        />*/}

                            <SelectCustom 
                                validationList={[{message:"Requerido",type:"required"}] as formRequired[]} 
                                errors={errors} id="prestadorCelular" 
                                label="Prestador Celular"
                                value={prestadorCelular}
                                register={register("prestadorCelular", { required: true })}
                                options={prestadorCelularList} 
                                wrapper={cb}/>
                        
                </Grid>
            </Row>
        </Grid>
        <Grid item xs={12} sm={12} md={12} >
            <Row>
                <Grid item xs={12} sm={6} md={6}>
                    <InputCustom 
                        validationList={[{message:"Máximo 65 caracteres",type:"maxLength"}] as formRequired[]} 
                        errors={errors} id="emailLaboral" 
                        label="Correo Electrónico Laboral" 
                        
                        register={register("emailLaboral", { maxLength: 65 })} 
                        />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <InputCustom 
                        validationList={[{message:"Requerido",type:"required"},{message:"Máximo 65 caracteres",type:"maxLength"}] as formRequired[]} 
                        errors={errors} id="email" 
                        label="Correo Electrónico Particular" 
                        autoComplete="nope"
                        register={register("email", { required:true,maxLength: 65 })} 
                        />
                </Grid>
            </Row>
        </Grid>
    </>)
}