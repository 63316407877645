import * as React from "react";
import { useState, useEffect } from 'react';
import { listCaracterUsuEmpresa } from "../../../../data/forms/GeneralData";
import Asociados from "../../../../types/empresas/Asociados"
import { tableData } from "../../../../types/general/GeneralTypes";
import PerfilCliente from "../../../../types/personas/PerfilCliente";
import { getObjectFromList } from "../../../../utils/GeneralUtils";
import DataTable, { TableColumn } from "react-data-table-component";
import { Button, Modal } from "react-bootstrap";
import PerfilEmpresa from "../../../../types/empresas/PerfilEmpresa";
import DataTableExtensions from "react-data-table-component-extensions";
import { Solicitud } from "../../../../types/productos/SolicitudProducto";
import Producto from "../../../../types/productos/Producto";
import * as moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

interface Props {
  solicitudes: Solicitud[];
  estado: boolean;
  setEstado: any;
}
export const ModalViewSolicitudProdByPersona = ({solicitudes,estado,setEstado} : Props) => {
    const [data, setData]               = useState<Solicitud[]>(solicitudes);
    
    const toogleModal = (t:boolean) => {
        setEstado(t);
    }

    const handleClick = (id : number) => {
      console.log(id)
    }
  const columns : tableData[] = [
    {
      name: "Producto",
      selector: row => {
        let t = row as Solicitud;
        let obj = (t.producto as any) as Producto
        return obj.nombre
        },
      sortable: false
    },
    {
        name: "Fecha Solicitud",
        selector: row => {
          let t = row as Solicitud;
          /*let obj = (t. as any) as Producto*/
          console.log(t.fechaSolicitud)
          return moment(t.fechaSolicitud).format('DD/MM/YYYY HH:MM')
      },
      sortable: false
    },
    {
        name: "Estado",
        selector: row => {
          let t = row as Solicitud;
          return t.workflow.descripcion;
      },
      sortable: false
    },
    {
        name: "Estado",
        selector: row => {
          let t = row as Solicitud;
          return  <Link to={'/solicitudes/' + t.id}>
                    <Button title="Ver Solicitud" variant="primary" size="sm">
                      <FontAwesomeIcon icon={faInfo}></FontAwesomeIcon>
                    </Button>
                  </Link>
      },
      sortable: false
    }
  ];


  useEffect(() => {
    setData(solicitudes);
  },[solicitudes]);

  const tableDatas = {
    columns,
    data,
  };
  return (
    <>
        <Modal show={estado} size="lg">
            <Modal.Header
            closeButton
            onClick={() => {
                toogleModal(false);
            }}
            >
            <h6>Ver Solicitud de productos</h6>
            </Modal.Header>
            <Modal.Body>
            <DataTableExtensions {...tableDatas} >
                <DataTable
                    title
                    noDataComponent="No se encontraron registros"
                    columns={columns}
                    data={data}
                    pagination
                    highlightOnHover
                />
            </DataTableExtensions>
            </Modal.Body>
            <Modal.Footer>
            <Button
                variant="primary"
                onClick={() => {
                    toogleModal(false);
                }}
                className="text-center"
            >
                Cerrar
            </Button>

            </Modal.Footer>
        </Modal>
    </>
  )
    //
}