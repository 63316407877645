import * as React from "react";
import { Breadcrumb, Button, Card, Col, Row, Form, InputGroup,Table } from "react-bootstrap";

import {Grid} from '@material-ui/core';
import { formRequired, InputCustom, RadioBooleanCustom, SelectCustom } from "../../../../../Commons/InputsCustom";
import { SolicitudProducto3 } from "../../../../../../types/productos/SolicitudProducto3";
import { FieldErrorsImpl } from "react-hook-form";
import PerfilCliente from "../../../../../../types/personas/PerfilCliente";
import { getLabelByList, OptionType, tipoCuilCuitListCDI } from "../../../../../../data/forms/GeneralData";
import { handleChangeTipoCuilCuit } from "../../../../../../utils/GeneralUtils";

interface Props {
    errors: FieldErrorsImpl<SolicitudProducto3>;
    register:any;
    cliente: PerfilCliente;
    setValue: any;
    trigger: any;
    chgRadios: any;
}

export const DatosDeclaracioJurada: React.FC<Props> = ({errors,register,cliente,setValue,trigger,chgRadios}) => {
    const [anexoCrsFatcaOp,setAnexoCrsFatcaOp]                  = React.useState<number>((cliente.anexoCrsFatcaOp));
    const [prevencionLavadoActivos,setPrevencionLavadoActivos]  = React.useState<number>((cliente.prevencionLavadoActivos));
    const [motivo,setMotivo]                                    = React.useState<boolean>((cliente.anexoCrsFatcaOp === 3));
    const [crs,setCrs]                                          = React.useState<boolean>(cliente.crsConformeLegislacion === 1);
    const [fatca,setFatca]                                      = React.useState<boolean>(cliente.fatcaConformeLegislacion === 1);
    const handleCrs = (val, id) => {
        chgRadios(val,id)
        setCrs(val === "1")
    }
    const handleFatca = (val, id) => {
        chgRadios(val,id)
        setFatca(val === "1")
    }

    const handleChangeAnexoCrsFatcaOp = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.checked)
        console.log(event.target.id)
        console.log(event.target.value)
        setAnexoCrsFatcaOp(Number(event.target.value))
        setMotivo(event.target.value === "3" ? true : false)
      }
      const handleChangePrevencionLavadoActivos = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrevencionLavadoActivos(Number(event.target.value))
      }
      
    return (<>
        <Grid item xs={12} sm={12} md={12} style={{marginTop:"15px"}}>
            <Row>
                <Grid item xs={12} sm={12} md={6}>
                    Solicito emisión de Tarjeta de Débito
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <RadioBooleanCustom chgRadio={chgRadios} chk1={cliente.solEmisionTarjetaDebito === 1} chk2={cliente.solEmisionTarjetaDebito === 0}
                            errors={errors}
                            id="solEmisionTarjetaDebito"
                            lbl1="Si"
                            lbl2="No"
                            register={register("solEmisionTarjetaDebito", { required: true})}
                            val1="1"
                            val2="0"
                            validationList={[{message:"Requerido",type:"required"}] as formRequired[]}
                            colMd1={3}
                            colMd2={3}
                                />
                </Grid>
            </Row>
        </Grid>
        <Grid item xs={12} sm={12} md={12} style={{marginTop:"15px"}}>
            <Row>
                <Grid item xs={12} sm={12} md={6}>
                <b>CRS</b>: Indique conforme legislación de cada jurisdicción SI NO posee residencia a efectos fiscales en una o más jurisdicciones distintas de Argentina (en caso afirmativo, completar Anexo CRS/FATCA)
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <RadioBooleanCustom chgRadio={handleCrs} chk1={cliente.crsConformeLegislacion === 1} chk2={cliente.crsConformeLegislacion === 0}
                            errors={errors}
                            id="crsConformeLegislacion"
                            lbl1="Si"
                            lbl2="No"
                            register={register("crsConformeLegislacion", { required: true})}
                            val1="1"
                            val2="0"
                            validationList={[{message:"Requerido",type:"required"}] as formRequired[]}
                            colMd1={3}
                            colMd2={3}
                                />
                </Grid>
            </Row>
        </Grid>
        <Grid item xs={12} sm={12} md={12} style={{marginTop:"15px"}}>
            <Row>
                <Grid item xs={12} sm={12} md={6}>
                <b>FATCA</b>: Indique conforme legislación de cada jurisdicción SI NO posee residencia a efectos fiscales en EEUU (en caso afirmativo, completar Anexo CRS/FATCA y el Formulario W9)
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <RadioBooleanCustom chgRadio={handleFatca} chk1={cliente.fatcaConformeLegislacion === 1} chk2={cliente.fatcaConformeLegislacion === 0}
                            errors={errors}
                            id="fatcaConformeLegislacion"
                            lbl1="Si"
                            lbl2="No"
                            register={register("fatcaConformeLegislacion", { required: true})}
                            val1="1"
                            val2="0"
                            validationList={[{message:"Requerido",type:"required"}] as formRequired[]}
                            colMd1={3}
                            colMd2={3}
                                />
                </Grid>
            </Row>
        </Grid>
        <Grid item xs={12} sm={12} md={12} style={{marginTop:"15px"}}>
            <Row>
                <b>Declaración Jurada de Personas Expuestas Políticamente (PEP’S)</b>
            </Row>
        </Grid>
        <Grid item xs={12} sm={12} md={12} style={{marginTop:"15px"}}>
            <Row>
                <Grid item xs={12} sm={12} md={6}>
                Que SI / NO me encuentro incluido y/o alcanzado dentro de la “Nómina de Personas Expuestas Políticamente”, aprobada por la Unidad de Información Financiera, en la Resolución N°134/2018 y modificatorias, que se detalla a continuación.
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <RadioBooleanCustom chgRadio={chgRadios} chk1={cliente.pep === 1} chk2={cliente.pep === 0}
                            errors={errors}
                            id="pep"
                            lbl1="Si"
                            lbl2="No"
                            register={register("pep", { required: true})}
                            val1="1"
                            val2="0"
                            validationList={[{message:"Requerido",type:"required"}] as formRequired[]}
                            colMd1={3}
                            colMd2={3}
                                />
                </Grid>
            </Row>
        </Grid>



        <Grid item xs={12} sm={12} md={12} style={{marginTop:"15px"}}>
            <Row>
                <b>Cumplimiento Normas y Políticas para la Prevención del Lavado de Activos y de Financiamiento del Terrorismo</b>
            </Row>
        </Grid>
        <Grid item xs={12} sm={12} md={12} style={{marginTop:"15px"}}>
            <Row>
                <Grid item xs={12} sm={12} md={6}>
                SI / NO me encuentro alcanzado como Sujeto Obligado conforme al artículo 20 de la ley 25.246 y modificatorias.
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    {
                        /*
                        <Form.Check name="anexoCrsFatcaOp" type="radio" value={0}
                        onClick={handleChangePrevencionLavadoActivos}
                        label="NO me encuentro alcanzado como Sujeto Obligado conforme al artículo 20 de la ley 25.246 y modificatorias."
                        checked={prevencionLavadoActivos === 0}
                        id="prevencionLavadoActivos1"
                        {...register("prevencionLavadoActivos")}
                    />
                        */
                    }
                <RadioBooleanCustom chgRadio={chgRadios} chk1={cliente.prevencionLavadoActivos === 1} chk2={cliente.prevencionLavadoActivos === 0}
                            errors={errors}
                            id="prevencionLavadoActivos"
                            lbl1="Si"
                            lbl2="No"
                            register={register("prevencionLavadoActivos", { required: true})}
                            val1="1"
                            val2="0"
                            validationList={[{message:"Requerido",type:"required"}] as formRequired[]}
                            colMd1={3}
                            colMd2={3}
                                />
                </Grid>
            </Row>
        </Grid>


{crs || fatca ? <>
        <Grid item xs={12} sm={12} md={12} style={{marginTop:"25px"}}>
            <Row>
            <b><p>ANEXO: CRS (Common Reporting Standard) / FATCA (Foreign Account Tax Compliance Act</p>
            <p>Indique a continuación todos aquellos países en los cuales tiene residencia a efectos fiscales de acuerdo con las leyes de dichas jurisdicciones o sea considerado “U.S. Person” bajo la regulación de los EEUU “Foreign Account Tax Compliance Act” (FATCA)</p></b>
            </Row>
        </Grid>
        <Grid item xs={12} sm={12} md={12} >
            <Row>
                <Grid item xs={12} sm={6} md={6}>
                    <InputCustom 
                        validationList={[{message:"Máximo 45 caracteres",type:"maxLength"}] as formRequired[]} 
                        errors={errors} id="anexoCrsFatcaPais" 
                        setValue={setValue}
                        label="País de residencia a efectos fiscales"
                        Props={[{attr:"autoComplete",value:"nope"}]}
                        register={register("anexoCrsFatcaPais", { maxLength: 45 })} 
                        />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <InputCustom 
                        validationList={[{message:"Máximo 45 caracteres",type:"maxLength"}] as formRequired[]} 
                        errors={errors} id="anexoCrsFatcaCiudad" 
                        setValue={setValue}
                        label="Ciudad"
                        Props={[{attr:"autoComplete",value:"nope"}]}
                        register={register("anexoCrsFatcaCiudad", { maxLength: 45 })} 
                        />
                </Grid>
            </Row>
        </Grid>

        <Grid item xs={12} sm={12} md={6} >
            <Row>
                <Grid item xs={12} sm={12} md={8}>
                    <InputCustom 
                        validationList={[{message:"Máximo 65 caracteres",type:"maxLength"}] as formRequired[]} 
                        errors={errors} id="anexoCrsFatcaCalle" 
                        setValue={setValue}
                        label="Calle" 
                        Props={[{attr:"autoComplete",value:"nope"}]}
                        register={register("anexoCrsFatcaCalle", { maxLength: 65 })} 
                        />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <InputCustom 
                        validationList={[{message:"Máximo 12 caracteres",type:"maxLength"}] as formRequired[]} 
                        errors={errors} id="anexoCrsFatcaAltura" 
                        setValue={setValue}
                        label="Altura"
                        Props={[{attr:"autoComplete",value:"nope"}]}
                        register={register("anexoCrsFatcaAltura", { maxLength: 12 })} 
                        />
                </Grid>
            </Row>
        </Grid>

        <Grid item xs={12} sm={12} md={6} >
            <Row>
                <Grid item xs={12} sm={12} md={12}>
                    <InputCustom 
                        validationList={[{message:"Máximo 32 caracteres",type:"maxLength"}] as formRequired[]} 
                        errors={errors} id="anexoCrsFatcaNif" 
                        label="Número de identificación fiscal (NIF)" 
                        register={register("anexoCrsFatcaNif", { maxLength: 32 })} 
                        />
                </Grid>
            </Row>
        </Grid>
        <Grid item xs={12} sm={12} md={12} >
            <Row className="row-sm">
                <Col lg={12} >
                    <Form.Check name="anexoCrsFatcaOp" type="radio" value={1}
                        onClick={handleChangeAnexoCrsFatcaOp}
                        label="El país de RF no emite N° de NIF a sus residentes"
                        checked={anexoCrsFatcaOp === 1}
                        id="anexoCrsFatcaOp1"
                        {...register("anexoCrsFatcaOp")}
                    />
                </Col>
                <Col lg={12} >
                    <Form.Check name="anexoCrsFatcaOp" type="radio" value={2}
                        onClick={handleChangeAnexoCrsFatcaOp}
                        label="No requiere N° NIF"
                        checked={anexoCrsFatcaOp === 2}
                        id="anexoCrsFatcaOp2"
                        {...register("anexoCrsFatcaOp")}
                    />

                </Col>
                <Col lg={12} >
                    <Form.Check name="anexoCrsFatcaOp" type="radio" value={3}
                        onClick={handleChangeAnexoCrsFatcaOp}
                        label="El cliente no puede obtener el NIF de RF."
                        checked={anexoCrsFatcaOp === 3}
                        id="anexoCrsFatcaOp3"
                        {...register("anexoCrsFatcaOp")}
                    />

                </Col>
                {
                    motivo &&
                    (
                        <Col lg={12} >
                            <InputCustom 
                                validationList={[{message:"Requerido",type:"required"},{message:"Máximo 128 caracteres",type:"maxLength"}] as formRequired[]} 
                                errors={errors} id="anexoCrsFatcaOp3Nota" 
                                label="Explique los motivos" 
                                register={register("anexoCrsFatcaOp3Nota", { required:true,maxLength: 128 })} 
                                />
                        </Col>
                    )
                    
                }
            </Row>
        </Grid>
    </>: <></>}
    </>)
}