import Documento from "../documentos/Documento";
import PerfilCliente from "../personas/PerfilCliente";
import { Solicitud } from "./SolicitudProducto";

export default interface Producto {
    id: number;
    tipoPersonaId: 1|2;
    nombre: string;
    habilitado: 0|1;
    urlBackend: string;
    documentos: Documento[];
    solicitudes: Solicitud[];
}

export const getTipoPersona = (id : Producto["tipoPersonaId"]) => {
    return (id === 1 ? "Personas" : (id === 2 ? "Empresas" : "Tipo desconocido"))
}