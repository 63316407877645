import * as React from "react";
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from "react-router";
import DatePicker from "react-datepicker";
import { Breadcrumb, Button,Tab,Nav, Card, Col, Row, Form, InputGroup } from "react-bootstrap";
import { useForm,Controller } from "react-hook-form";
import Select , { SingleValue } from "react-select";
import {
    Paper,
    Box,
    Grid,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem
  } from '@material-ui/core';
import Producto from "../../../../types/productos/Producto";
import PerfilCliente from "../../../../types/personas/PerfilCliente";
import PerfilEmpresa from "../../../../types/empresas/PerfilEmpresa";
import { Solicitud } from "../../../../types/productos/SolicitudProducto";
import { LoDashExplicitNumberArrayWrapper } from "lodash";
import { getObjectType, ProductContext, toastError, toastSuccess, validateCuit } from "../../../../utils/GeneralUtils";
import { useAxios } from "../../../../hooks/useAxios";
import { SolicitudProducto3 } from "../../../../types/productos/SolicitudProducto3";
import { SolicitudHead } from "./components/SolicitudHead";
import { SolicitudMain } from "./components/SolicitudMain";
import { EnumWorkflow } from "../../../../types/general/Workflow";
import { DetalleProducto } from "./components/DetalleProducto";
import { getSolActiveByProduct } from "../../../../utils/DocumentoUtils";
import { EnumEstadoCivil } from "../../../../types/general/GeneralTypes";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


interface Props {
    producto: Producto;
    cliente: PerfilCliente;
    fetchProductRef : any;
}
const getActiveKeyByDefault = (prod : Producto) : string => {
    return (prod.solicitudes.length === 0 ? "tab2" : "tab1");
}
const isShowInfo = (prod : Producto) : boolean => {
    return (prod.solicitudes.length === 0 ? false : true);
}
const SolicitudProductoId3: React.FC<Props> = ({producto,cliente,fetchProductRef} : Props) => {
    const navigate                              = useNavigate();
    const { reloadProduct }                     = React.useContext(ProductContext);//context definido en FormProductChoice
    const axiosInstance                         = useAxios();
    const [lproducto,setLproducto]                = useState<Producto>(producto);
    const [lcliente,setLcliente]                  = useState<PerfilCliente>(cliente);
    const [domComIgualReal,setDomComIgualReal]  = useState<boolean>(false);
    
    const [activeKey,setActiveKey]                      = useState<string>(getActiveKeyByDefault(lproducto));
    const [isShowInfoProducto,setIsShowInfoProducto]    = useState<boolean>(isShowInfo(lproducto));
    var sol = null;

    /*const fetchProducto = async (idProd : number,idCli : number) =>{
      const res : {data: Producto} = await axiosInstance.current.get('apiv2/productoById/' + idProd + '/byClient/' + (idCli === null ? 0 : idCli));
      setLproducto(res.data);
      console.log(res.data)
    }*/
    sol = getSolActiveByProduct(lproducto)//en el form la solicitud es parte del header
    const { register,  handleSubmit,reset,control,setValue,getValues,clearErrors,trigger,setError,formState: { errors } } = useForm<SolicitudProducto3>({ defaultValues: {...cliente as any,...sol} });

    const onSubmit = (data: SolicitudProducto3) => {

      if (data.crsConformeLegislacion == 0 && data.fatcaConformeLegislacion == 0) {
        data.anexoCrsFatcaPais    = null;
        data.anexoCrsFatcaCiudad  = null;
        data.anexoCrsFatcaCalle   = null;
        data.anexoCrsFatcaAltura  = null;
        data.anexoCrsFatcaNif     = null;
        data.anexoCrsFatcaOp      = null;
        data.anexoCrsFatcaOp3Nota = null;
      }
      if (domComIgualReal) {
        data.calleComercial       = data.calle;
        data.alturaComercial      = data.altura;
        data.pisoComercial        = data.piso;
        data.dptoComercial        = data.dpto;
        data.codPostalComercial   = data.codPostal;
        data.localidadComercial   = data.localidad;
        data.provinciaComercial   = data.provincia;
      }
        data.moneda = Number(data.moneda)

        
        Object.keys(data).map((key, index) => {
            const type : String = getObjectType(data[key]);

            if (type.toLocaleLowerCase() !== "string" && type.toLocaleLowerCase() !=='number' && type.toLocaleLowerCase() !=='date') {
                delete data[key];
            }
        })
        if (data.estadoCivil !== EnumEstadoCivil.CASADO) {
          data.conyugeCuilCuit      = "";
          data.conyugeNomApe        = "";
          data.conyugeTipoCuilCuit  = "";
          data.conyugeTipoNroDoc    = "";
        }

          axiosInstance.current.put('/apiv2/producto/' + lproducto.id + '/solicitud/' + cliente.id, data)
            .then((response  ) => {

              setTimeout (function () {
                fetchProductRef().then( () => {
                  setTimeout(function () {
                    setActiveKey ("tab1")
                    setIsShowInfoProducto(true);
                  },300)
                  
                }).catch ((e) => console.log(e));
              },500)
              
              toastSuccess("Datos Guardados");
              
            })
            .catch(error => {
              toastError(error.response.data.message);
            })

        
    }
    useEffect(() => {
      setLproducto(producto);
    },[producto])

    useEffect(() => {
      setLproducto(producto);
    },[])
    const handleSelectNav = (eventKey) => {
        setActiveKey (eventKey);
    }
    return (
    <>
        <Row className=" sidemenu-height">
            <Col lg={12}>
            <form>
                
                <Card className="custom-card">
                    <Card.Body>
                      {cliente && cliente.id !== null &&
                    <div className="panel panel-primary tabs-style-2">
                    <div className=" tab-menu-heading">
                      <div className="tabs-menu1">
                        <Tab.Container activeKey={activeKey}>
                          <Nav
                            variant="pills"
                            className="nav panel-tabs main-nav-line"
                            onSelect={handleSelectNav}
                          >
                            <Nav.Item>
                              <Nav.Link eventKey="tab2" className="mt-1">Formulario de solicitud</Nav.Link>
                            </Nav.Item>
                            <Nav.Item  >
                              <Nav.Link eventKey="tab1" className="mt-1">Información del producto</Nav.Link>
                            </Nav.Item>
                            
                          </Nav>
                          <Tab.Content>
                            <Tab.Pane eventKey="tab1" className="border">
                                {!isShowInfoProducto &&
                                    <p>
                                     Debe completar el formulario dentro de "Información del producto"
                                    </p>
                                }
                                {isShowInfoProducto &&
                                    <div>{lproducto.solicitudes.length>0 && <DetalleProducto producto={lproducto} setActiveKey={setActiveKey}/>}</div>
                                }
                              
                            </Tab.Pane>
                            <Tab.Pane eventKey="tab2" className="border">
                                <SolicitudHead title={"SOLICITUD DE APERTURA DE " + lproducto.nombre.toUpperCase()} clearErrors={clearErrors} solicitud={sol} register={register} errors={errors} setValue={setValue}  data={null} trigger={trigger} setError={setError} reset={reset}/>
                                <SolicitudMain domComIgualReal={domComIgualReal} setDomComIgualReal={setDomComIgualReal} title={"ANEXO – INTEGRANTE FIRMANTE"} subtitle={"Datos del firmante"} cliente={cliente} clearErrors={clearErrors} control={control} register={register} errors={errors} setValue={setValue}  data={null} trigger={trigger} setError={setError} reset={reset}/>
                                <Grid container justifyContent="center" spacing={2} style={{width:"100%",marginTop:"10px"}}>
                                    <Button className="btn btn-primary" type="button" onClick={handleSubmit(onSubmit)}>
                                    <FontAwesomeIcon icon={faSave}/> Guardar
                                    </Button>
                                </Grid>
                            </Tab.Pane>
                            

                          </Tab.Content>
                        </Tab.Container>
                      </div>
                    </div>
                  </div>}
                        {!cliente || cliente.id === null ?
                          <div className="page-header">
                            <h4>
                              <div className="alert alert-warning" role="alert">
                                <span className="alert-inner--icon">
                                  <i className="fe fe-info me-2"></i>
                                </span>
                                <span className="alert-inner--text">
                                  Debe completar sus datos en <strong>Mi Perfil</strong> para poder solicitar productos
                                </span>
                              </div>
                            </h4>
                        </div>
                          : ""}
                    </Card.Body>
                </Card>
                
            </form>
                    
                
            </Col>
        </Row>
    </>
    );
}

export default SolicitudProductoId3;