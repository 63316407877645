export interface Workflow {
    id: number;
    descripcion: string;
    tipo: number;
}

export enum EnumWorkflow {
    SOLICITADO      = 1,
    EN_VERIFICACION = 2,
    APROBADO        = 3,
    PRODUCTO_ACTIVO = 4,
    RECHAZADO       = 5,
    SIN_ENVIAR      = 6,
    BAJA_SOLICITADA = 7,
    BAJA_EN_PROCESO = 8,
    BAJA_CONFIRMADA = 9
  }