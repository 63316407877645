import * as React from "react";
import { useState, useEffect } from 'react';
import { useLocation } from "react-router";
import DatePicker from "react-datepicker";
import { useAxios } from "../../../hooks/useAxios";
import { Breadcrumb, Button, Card, Col, Row, Form, InputGroup, Spinner } from "react-bootstrap";
import Select , { SingleValue } from "react-select";
import {generoList,tipoDoc,getGeneroName,estadoCivilList,OptionType} from "../../../data/forms/GeneralData";
import {  DataTablePersona } from "./DataTablePersona";
import {ResponseContainer} from "../../../../src/types/general/GeneralTypes"
import {
  Paper,
  Box,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import PerfilCliente from "../../../types/personas/PerfilCliente";
import { any } from "prop-types";
interface Props {}

const PersonasRegistradas: React.FC<Props> = (props) => {
    const axiosInstance             = useAxios();
    const [loading,setLoading]      = useState(true);
    const [list,setList]            = useState<PerfilCliente[]>();
    
    useEffect(() => {
        (axiosInstance !== undefined && axiosInstance.current !== undefined && (
            axiosInstance.current.get<ResponseContainer<PerfilCliente[]>>('/api/personas?size=10&page=1')
              .then(res => {
                setLoading(false);
                setList(res.data.data);
              })
            .catch((err: { response: any; }) => console.dir(err.response))     ))
      }    
      , []);
    return <>
        {/* <!-- Page Header --> */}
        <div className="page-header">
            <div>
            <h2 className="main-content-title tx-24 mg-b-5">Personas Registradas</h2>
            {/*<Breadcrumb>
                <Breadcrumb.Item href="#"> Pages </Breadcrumb.Item>
                <Breadcrumb.Item active>Empty Page</Breadcrumb.Item>
            </Breadcrumb>*/}
            </div>

        </div>
      {/* <!-- End Page Header --> */}

    {/* <!-- Row --> */}
        <Row className=" sidemenu-height">
            <Col lg={12}>
                <Card className="custom-card">
                    <Card.Body>
                        {!loading && <DataTablePersona list={list}></DataTablePersona>}
                        {
                            loading && 
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        }
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>;
}

export default PersonasRegistradas;