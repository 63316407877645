import { Solicitud } from "../../../../types/productos/SolicitudProducto";
import * as React from "react";
import { useState, useEffect } from 'react';
import { Button, Col, Modal, ModalHeader, Row } from "react-bootstrap";
import { ModalHeaderSolicitud } from "./ModalHeader";
import { formRequired, InputCustom } from "../../../Commons/InputsCustom";
import { useForm,Controller } from "react-hook-form";
import {
    Paper,
    Box,
    Grid,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem
  } from '@material-ui/core';
import { TextareaCustom } from "../../../Commons/TextareaCustom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useAxios } from "../../../../hooks/useAxios";
import { toastSuccess } from "../../../../utils/GeneralUtils";
interface Props {
    solicitud: Solicitud;
    estado: boolean;
    setEstado: any;
    fetchSolicitudesReload: any;
    handleModals: any
}
interface Respuesta {
    motivoRechazo: string;
}

export const ModalViewSolicitudRechazo = ({solicitud,estado,setEstado,fetchSolicitudesReload} : Props) => {
    const axiosInstance                 = useAxios();
    const toogleModal = (t:boolean) => {
        setEstado(t);
    }
    const { register,  handleSubmit,reset,control,setValue,getValues,clearErrors,trigger,setError,formState: { errors } } = useForm<Respuesta>({
        defaultValues: {
            motivoRechazo: ''
        }});

    const postRechazo = (data : Respuesta) => {
        const formData = new FormData();
        formData.append("motivo", data.motivoRechazo);
        (axiosInstance !== undefined && axiosInstance.current !== undefined && (
            axiosInstance.current.post('apiv2/solicitud/rechazar/' + solicitud.id,formData)
            .then(() => {
                toastSuccess("Solicitud rechazada");
                setEstado(false);
                fetchSolicitudesReload();
            })
            .catch((err: { response: any; }) => {

            })     
        ))
    }
    const onSubmit = (data : Respuesta) => {
        console.log(solicitud.id)
        console.log(data)
        ///
        postRechazo(data)
    }
    const onInvalid = (errors) => console.error(errors)
    useEffect(() => {
        setValue('motivoRechazo','');
        
      },[solicitud]);
    return (<>
        {solicitud && (
            <form>
        <Modal show={estado} size="lg">
            
            <Modal.Header
            closeButton
            onClick={() => {
                toogleModal(false);
            }}
            >
            <h6>Solicitud {solicitud && solicitud.producto.nombre}</h6>
            </Modal.Header>
            <Modal.Body>
              {solicitud && 
              <>
                
                <ModalHeaderSolicitud solicitud={solicitud}></ModalHeaderSolicitud>

                <Row>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextareaCustom 
                            rows={3}
                            validationList={[{message:"Requerido",type:"required"},{message:"Máximo 500 caracteres",type:"maxLength"}] as formRequired[]} 
                            errors={errors} id="motivoRechazo" 
                            label="Motivo Rechazo"
                            register={register("motivoRechazo", { required:true,maxLength: 500 })} 
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <p>El motivo de rechazo es enviado al cliente</p>
                    </Grid>
                </Row>
                
              </>
              }
            </Modal.Body>
            <Modal.Footer>
            <Button variant="danger" type="button" onClick={handleSubmit(onSubmit)}><FontAwesomeIcon icon={faXmark}></FontAwesomeIcon> Rechazar</Button>
            <Button
                variant="light"
                onClick={() => {
                    toogleModal(false);
                }}
                className="text-center"
            >
                Cerrar
            </Button>

            </Modal.Footer>
            
        </Modal>
        </form>)}

    </>);
}