import * as React from "react";
import { useState, useEffect } from 'react';
import DataTable, { TableColumn } from "react-data-table-component";
import { Button, Modal } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";
import { GenericKeyValue, ResponseContainer,tableData } from "../../../../types/general/GeneralTypes";



interface Props {
  list: GenericKeyValue<String,String>[];
  estado: boolean;
  setShow : (a:boolean) => void;
}
export const ModalResponseUploadPdf = ({list,estado,setShow} : Props) => {
  const [data, setData]               = useState<GenericKeyValue<String,String>[]>(list);


  const toogleModal = (t:boolean) => {
    setShow(t);
  }

  const columns : tableData[] = [
    {
      name: "Campo",
      selector: row => [row.key],
      sortable: true
    },
    {
      name: "Error",
      selector: row => [row.value],
      sortable: true
    }
  ];


  useEffect(() => {
    setData(list);
  },[list]);
  return (
    <>
        <Modal show={estado} size="lg">
            <Modal.Header
            closeButton
            onClick={() => {
                toogleModal(false);
            }}
            >
            <h6>Basic Modal</h6>
            </Modal.Header>
            <Modal.Body>

                <DataTable
                    title
                    noDataComponent="No se encontraron documentos"
                    columns={columns}
                    data={data}
                    pagination
                    highlightOnHover
                />

            </Modal.Body>
            <Modal.Footer>
            <Button
                variant="primary"
                onClick={() => {
                    toogleModal(false);
                }}
                className="text-center"
            >
                Cerrar
            </Button>

            </Modal.Footer>
        </Modal>
    </>
  )
}