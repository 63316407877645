import * as React from "react";
import { useAxios } from "../../../../../../hooks/useAxios";

import Producto from "../../../../../../types/productos/Producto";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { ProductContext, toastError, toastSuccess } from "../../../../../../utils/GeneralUtils";
import { Solicitud } from "../../../../../../types/productos/SolicitudProducto";
import { EnumWorkflow } from "../../../../../../types/general/Workflow";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
interface Props {
    producto: Producto;
    refreshFn: any;
    solicitud: Solicitud;
    setModalVisivilidad?: (a:boolean) => void
    axiosInstance?:any
  }


  /**
   * este componente muestra para un documento requerido el btn de solicitud del producto
   * @param archivosSubidos son los archivos que subio el cliente para este documento, pero sin el blob
   * @returns 
   */
 export const BotonBajaSolicitud: React.FC<Props> = ({producto,solicitud,refreshFn,setModalVisivilidad,axiosInstance} : Props) => {
    const { reloadProduct } = React.useContext(ProductContext);//context definido en FormProductChoice
    //const axiosInstance             = useAxios();
    const handleValidation = () => {
        let ret : boolean = false;
        if (solicitud.workflow.id === EnumWorkflow.PRODUCTO_ACTIVO) {//recorro los documentos que solicita el producto
            return true;
        }
        return ret;
    }
    const test = (d: any) => {
        toastError(d)
    }
    const handleClickSendRequest = () => {
        setModalVisivilidad(false)

        if (!handleValidation()) {
            toastError("Error de estado de la solicitud");
        } else {

            Swal.fire({
                title: "¿Confirma dar de baja el producto?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#00144C",
                cancelButtonColor: "gray",
                confirmButtonText: "Sí",
                cancelButtonText: "Cancelar",
                allowOutsideClick: false,
              }).then((result) => {
                console.log(axiosInstance)
                console.log(axiosInstance.current)
                if (result.isConfirmed) {
                    (axiosInstance !== undefined && axiosInstance.current !== undefined && (
                        axiosInstance.current.get('/apiv2/solicitud/solicitarBaja/' + solicitud.id)
                          .then(res => {
                            toastSuccess("Baja solicitada");
                            reloadProduct();
                          })
                        .catch((err: { response: {data : {message : string}}; }) => {
                            console.log(err)
                            console.log(err.response)
                            test(err.response.data.message);
                            }
                        )
                    ))
                  
                } 
              });

            
        }
        //
        
    }
    return (<>
        <Button variant="primary" className="btn ripple" onClick={handleClickSendRequest}>
            <FontAwesomeIcon icon={faClose}   /> Solicitar Baja
        </Button>
    </>)
 }