import * as React from "react";
import { useState, useEffect } from 'react';
import { listCaracterUsuEmpresa } from "../../../../data/forms/GeneralData";
import Asociados from "../../../../types/empresas/Asociados"
import { tableData } from "../../../../types/general/GeneralTypes";
import PerfilCliente from "../../../../types/personas/PerfilCliente";
import { getObjectFromList } from "../../../../utils/GeneralUtils";
import DataTable, { TableColumn } from "react-data-table-component";
import { Button, Modal } from "react-bootstrap";
import PerfilEmpresa from "../../../../types/empresas/PerfilEmpresa";
import DataTableExtensions from "react-data-table-component-extensions";
interface Props {
    asociados: Asociados[];
    estado: boolean;
    setEstado: any;
}
export const ModalViewEmpresasByPersona = ({asociados,estado,setEstado} : Props) => {
    const [data, setData]               = useState<Asociados[]>(asociados);
    
    const toogleModal = (t:boolean) => {
        setEstado(t);
    }

  const columns : tableData[] = [
    {
      name: "CUIT",
      selector: row => {
        let t = row as Asociados;
        let cli = (t.empresa as any) as PerfilEmpresa
        return cli.cuit;
        },
      sortable: true
    },
    {
        name: "Razon Social",
        selector: row => {
          let t = row as Asociados;
          let cli = (t.empresa as any) as PerfilEmpresa
          return cli.razonSocial;
      },
      sortable: true
    },
    {
        name: "Caracter",
        selector: row => {
          let t = row as Asociados;
          let cli = getObjectFromList(listCaracterUsuEmpresa,t.idCaracter);
          return cli.label;
      },
      sortable: true
    },
    {
      name: "Email",
      selector: row => {
        let t = row as Asociados;
        let cli = (t.empresa as any) as PerfilEmpresa
        return cli.email
      },
      sortable: true
    }
  ];


  useEffect(() => {
    setData(asociados);
  },[asociados]);

  const tableDatas = {
    columns,
    data,
  };
  return (
    <>
        <Modal show={estado} size="lg">
            <Modal.Header
            closeButton
            onClick={() => {
                toogleModal(false);
            }}
            >
            <h6>Ver Empresas</h6>
            </Modal.Header>
            <Modal.Body>
            <DataTableExtensions {...tableDatas} >
                <DataTable
                    title
                    noDataComponent="No se encontraron registros"
                    columns={columns}
                    data={data}
                    pagination
                    highlightOnHover
                />
            </DataTableExtensions>
            </Modal.Body>
            <Modal.Footer>
            <Button
                variant="primary"
                onClick={() => {
                    toogleModal(false);
                }}
                className="text-center"
            >
                Cerrar
            </Button>

            </Modal.Footer>
        </Modal>
    </>
  )
    //
}