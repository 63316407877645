import { Grid } from "@material-ui/core";
import * as React from "react";
import {useEffect,useState} from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useAxios } from "../../../hooks/useAxios";

interface Props {
    
}

const Setting: React.FC<Props> = ({}) => {
    const axiosInstance                         = useAxios();
    const [mailToSolicitudProducto,setMailToSolicitudProducto]  = useState<string>('');
    const [mailCaEmailCuerpo,setMailCaEmailCuerpo]              = useState<string>('');
    const [mailCaEmailTitulo,setMailCaEmailTitulo]              = useState<string>('');
    useEffect(() => {
        axiosInstance.current.get('/api/config')
          .then((res: { data: {mailCaEmailCuerpo: string,mailCaEmailTitulo: string,mailToSolicitudProducto:string} }) => {              
            console.log(res)
            setMailToSolicitudProducto(res.data.mailToSolicitudProducto)
            setMailCaEmailTitulo(res.data.mailCaEmailTitulo)
            setMailCaEmailCuerpo(getText(res.data.mailCaEmailCuerpo));
          })
          .catch((err: { response: any; }) => console.dir(err.response))    
    },[])

    const getText = (a:string) : string => {

        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(a, 'text/html');

        const textoSinHTML = htmlDoc.documentElement.textContent;
        return textoSinHTML;
    }
    return <>
    {/* <!-- Page Header --> */}
    <div className="page-header">
        <div>
            <h2 className="main-content-title tx-24 mg-b-5">Configuración</h2>
        </div>
    </div>
  {/* <!-- End Page Header --> */}

    {/* <!-- Row --> */}
        <Row className=" sidemenu-height">
            <Col lg={12} md={12}>
                <Card className="custom-card">
                    <Card.Body>
                        <Row >

                            <Grid item xs={12} sm={12} md={12}>
                                <Form.Group className="form-group">
                                    <p className="mg-b-1 fontSm" >Email a Operadores [app.mail.toSolicitudProducto]:</p>
                                    <Form.Control
                                        size="sm"
                                        type="text" 
                                        id="email"
                                        readOnly
                                        defaultValue={mailToSolicitudProducto}
                                        placeholder="Email"
                                        className={"form-control"}
                                    />

                                </Form.Group>
                            </Grid>
                        </Row>
                        <Row >

                            <Grid item xs={12} sm={12} md={12}>
                                <Form.Group className="form-group">
                                    <p className="mg-b-1 fontSm" >Email CA Asunto [app.mail.caEmail.titulo]:</p>
                                    <Form.Control
                                        size="sm"
                                        type="text" 
                                        id="email"
                                        readOnly
                                        defaultValue={mailCaEmailTitulo}
                                        placeholder="Email"
                                        className={"form-control"}
                                    />

                                </Form.Group>
                            </Grid>
                        </Row>
                        <Row >

                            <Grid item xs={12} sm={12} md={12}>
                                <Form.Group className="form-group">
                                    <p className="mg-b-1 fontSm" >Email CA Cuerpo [app.mail.caEmail.cuerpo]:</p>
                                    <Form.Control
                                        size="sm"
                                        type="text" 
                                        id="email"
                                        readOnly
                                        defaultValue={mailCaEmailCuerpo}
                                        placeholder="Email"
                                        className={"form-control"}
                                    />

                                </Form.Group>
                            </Grid>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>;
}

export default Setting;