
export interface  OptionType {
  value: string;
  label: string;
};
export interface  IOptionType {
  value: number;
  label: string;
};
export const generoList : OptionType[] = [
    { value: "1", label: "Masculino" },
    { value: "2", label: "Femenino" },
    { value: "3", label: "Indeterminado" }
  ];

  export const tipoDoc : OptionType[] = [
    { value: "DNI", label: "DNI" },
    { value: "LE", label: "LE" },
    { value: "LC", label: "LC" }
  ];
  
  export const estadoCivilList : OptionType[] = [
    { value: "Casado/a", label: "Casado/a" },
    { value: "Soltero/a", label: "Soltero/a" },
    { value: "Divorciado/a", label: "Divorciado/a" },
    { value: "Viudo/a", label: "Viudo/a"}
  ];
  export const tipoCuilCuitList : OptionType[] = [
    { value: "", label: "Seleccionar" },
    { value: "CUIL", label: "CUIL" },
    { value: "CUIT", label: "CUIT" }
  ];
  export const prestadorCelularList : OptionType[] = [
    { value: "", label: "Seleccionar" },
    { value: "Movistar", label: "Movistar" },
    { value: "Personal", label: "Personal" },
    { value: "Claro", label: "Claro" },
    { value: "Tuenti", label: "Tuenti" }
  ];
  export const tipoCuilCuitListCDI : OptionType[] = [
    { value: "", label: "Seleccionar" },
    { value: "CUIL", label: "CUIL" },
    { value: "CUIT", label: "CUIT" },
    { value: "CDI", label: "CDI" }
  ];
  export function getLabelByList(list: OptionType[],t : string) {
    let str = "Seleccionar...";
    list.forEach ( d => {
        if (d.value === t) {
            str = d.label;
        }
    });
    return str;
  }
  export function getGeneroName(t : string) {
    return getLabelByList(generoList,t);
    /*let str = "";
    generoList.forEach ( d => {
        if (d.value === t) {
            str = d.label;
        }
    });
    return str;*/
  }
export const listTipoSociedad : IOptionType[] = [
  { value: 1,label: "Sociedades civiles"},
  { value: 2,label: "Sociedades Unipersonales"},
  { value: 3,label: "Sociedades de hecho"},
  { value: 4,label: "Fideicomisos"},
  { value: 5,label: "Sucesiones"},
  { value: 6,label: "Fondos de inversión"},
  { value: 7,label: "Condominios"},
  { value: 8,label: "Consorcios"},
  { value: 9,label: "Asoc. Constituidas conforme al art.46 del Código Civil"},
  { value: 10,label: "SA en Formación"},
  { value: 11,label: "Sociedad Colectiva"},
  { value: 12,label: "Sociedad en Comandita simple"},
  { value: 13,label: "Sociedad de capital e industria"},
  { value: 14,label: "SRL"},
  { value: 15,label: "SA"},
  { value: 16,label: "SA con participación Estatal mayoritaria"},  
  { value: 17,label: "Sociedad en comandita por Acciones"},
  { value: 18,label: "Cooperativas"},
  { value: 19,label: "Distribuidores de diarios y revistas"},
  { value: 20,label: "Sociedades de Bolsa"},
  { value: 21,label: "Mutuales"},
  { value: 22,label: "Poder Judicial - Tribunales"}
];

export const listProvincias : IOptionType[] = [
  { value: 1,label: "Buenos Aires"},
  { value: 2,label: "Capital Federal"},
  { value: 3,label: "Catamarca"},
  { value: 4,label: "Chaco"},
  { value: 5,label: "Chubut"},
  { value: 6,label: "Córdoba"},
  { value: 7,label: "Corrientes"},
  { value: 8,label: "Entre Rios"},
  { value: 9,label: "Formosa"},
  { value: 10,label: "Jujuy"},
  { value: 11,label: "La Pampa"},
  { value: 12,label: "La Rioja"},
  { value: 13,label: "Mendoza"},
  { value: 14,label: "Misiones"},
  { value: 15,label: "Neuquen"},
  { value: 16,label: "Rio Negro"},  
  { value: 17,label: "Salta"},
  { value: 18,label: "San Juan"},
  { value: 19,label: "San Luis"},
  { value: 20,label: "Santa Cruz"},
  { value: 21,label: "Santa Fe"},
  { value: 22,label: "Santiago del Estero"},
  { value: 17,label: "Tierra del Fuego"},
  { value: 17,label: "Tucuman"}
];

export const listCaracterUsuEmpresa : IOptionType[] = [
  { value: 1,label: "Titular"},
  { value: 2,label: "Representante legal"},
  { value: 3,label: "Apoderado"}
];

export const workflowList : IOptionType[] = [
  { value: 1, label: "Solicitado" },
  { value: 2, label: "En Verificación" },
  { value: 3, label: "Aprobado" },
  { value: 4, label: "Finalizado" },
  { value: 5, label: "Rechazado" },
  { value: 6, label: "Sin Enviar" }
];