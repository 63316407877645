import { Solicitud } from "../../../../types/productos/SolicitudProducto";
import * as React from "react";
import { useState, useEffect } from 'react';
import { Button, Col, Modal, ModalHeader, Row } from "react-bootstrap";
import { ModalHeaderSolicitud } from "./ModalHeader";
import { formRequired, InputCustom } from "../../../Commons/InputsCustom";
import { useForm,Controller } from "react-hook-form";
import {
    Paper,
    Box,
    Grid,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem
  } from '@material-ui/core';
import { TextareaCustom } from "../../../Commons/TextareaCustom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useAxios } from "../../../../hooks/useAxios";
import { toastSuccess } from "../../../../utils/GeneralUtils";
import { SolicitudProductoRespuesta } from "../../../../types/productos/SolicitudProductoRespuesta";
interface Props {
    solicitud: Solicitud;
    estado: boolean;
    setEstado: any;
    fetchSolicitudesReload: any;
    handleModals: any
}


export const ModalViewSolicitudActivar = ({solicitud,estado,setEstado,fetchSolicitudesReload} : Props) => {
    const axiosInstance                 = useAxios();
    const toogleModal = (t:boolean) => {
        setEstado(t);
    }
    const { register,  handleSubmit,reset,control,setValue,getValues,clearErrors,trigger,setError,formState: { errors } } = useForm<SolicitudProductoRespuesta>({
        defaultValues: {
            cbu: '',
            nroCa: ''
        }});

    const postActivar = (data : SolicitudProductoRespuesta) => {

        (axiosInstance !== undefined && axiosInstance.current !== undefined && (
            axiosInstance.current.post('apiv2/solicitud/activar/' + solicitud.id,data)
            .then(() => {
                toastSuccess("Solicitud activada");
                setEstado(false);
                fetchSolicitudesReload();
            })
            .catch((err: { response: any; }) => {

            })     
        ))
    }
    const onSubmit = (data : SolicitudProductoRespuesta) => {

        postActivar(data)
    }
    const onInvalid = (errors) => console.error(errors)
    useEffect(() => {
        setValue('cbu','');
        setValue('nroCa','');
      },[solicitud]);
    return (<>
        {solicitud && (
            <form>
        <Modal show={estado} size="lg">
            
            <Modal.Header
            closeButton
            onClick={() => {
                toogleModal(false);
            }}
            >
            <h6>Solicitud {solicitud && solicitud.producto.nombre}</h6>
            </Modal.Header>
            <Modal.Body>
              {solicitud && 
              <>
                
                <ModalHeaderSolicitud solicitud={solicitud}></ModalHeaderSolicitud>

                <Row>
                    <Grid item xs={12} sm={12} md={12}>
                        <InputCustom 
                            validationList={[{message:"Requerido",type:"required"}
                                ,{message:"Máximo 22 dígitos",type:"maxLength"}
                                ,{message:"Máximo 22 dígitos",type:"minLength"}] as formRequired[]} 
                            errors={errors} id="cbu" 
                            label="CBU"
                            register={register("cbu", { required:true,maxLength: 22,minLength:22 })} 
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <InputCustom 
                            validationList={[{message:"Requerido",type:"required"},{message:"Máximo 30 caracteres",type:"maxLength"}] as formRequired[]} 
                            errors={errors} id="nroCa" 
                            label="Número de cuenta"
                            register={register("nroCa", { required:true,maxLength: 30 })} 
                            />
                    </Grid>
                </Row>
                
              </>
              }
            </Modal.Body>
            <Modal.Footer>
            <Button variant="success" type="button" onClick={handleSubmit(onSubmit)}><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Activar</Button>
            <Button
                variant="light"
                onClick={() => {
                    toogleModal(false);
                }}
                className="text-center"
            >
                Cerrar
            </Button>

            </Modal.Footer>
            
        </Modal>
        </form>)}

    </>);
}