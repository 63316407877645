import * as React from "react";
import Select , { SingleValue } from "react-select";
import { useState, useEffect } from 'react';
import { FieldErrorsImpl } from "react-hook-form";
import DatePicker from "react-datepicker";
import { SolicitudProducto3 } from "../../../../../types/productos/SolicitudProducto3";
import { Breadcrumb, Button, Card, Col, Row, Form, InputGroup,Table } from "react-bootstrap";
import {fechaNacValidate,cuilCuitValidate} from '../../../../../utils/GeneralUtils'
import {
    Paper,
    Box,
    Grid,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem
  } from '@material-ui/core';
import { estadoCivilList, generoList, getGeneroName, getLabelByList, OptionType, prestadorCelularList, tipoCuilCuitList, tipoDoc } from "../../../../../data/forms/GeneralData";
import { customStyles, handleChangeEstadoCivil, handleChangeTipoCuilCuit, validateCuit } from "../../../../../utils/GeneralUtils";
import PerfilCliente from "../../../../../types/personas/PerfilCliente";
import { formRequired, InputCustom, SelectCustom,RadioBooleanCustom } from "../../../../Commons/InputsCustom";
import { DomicilioReal } from "./inc/DomicilioReal";
import { DomicilioComercial } from "./inc/DomicilioComercial";
import { DatosTablaSolicitud } from "./inc/DatosTablaSolicitud";
import { DatosContacto } from "./inc/DatosContacto";
import { DatosConyuge } from "./inc/DatosConyuge";
import { DatosDeclaracioJurada } from "./inc/DatosDeclaracioJurada";
import { EnumEstadoCivil } from "../../../../../types/general/GeneralTypes";
import MaskInputCustom from "../../../Perfil/inc/MaskInputCustom";
interface Props {
    title:string;
    subtitle:string;
    register : any;
    errors : FieldErrorsImpl<SolicitudProducto3>;
    setValue : any;
    data : SolicitudProducto3;
    trigger:any;
    setError : any;
    clearErrors: any;
    reset : any;
    cliente : PerfilCliente;
    setDomComIgualReal: any;
    domComIgualReal: boolean;
    control:any
}


export const SolicitudMain: React.FC<Props> = ({title,subtitle,register,errors,setValue,data,trigger,control,setError,clearErrors,reset,cliente,setDomComIgualReal,domComIgualReal}) => {
    const [moneda,setMoneda]                    = useState<number>(null);
    const [tipoDni, setTipoDni]                 = useState<OptionType>({value:cliente.tipoDni,label:cliente.tipoDni});
    const [genero, setGenero]                   = useState<OptionType>({value:cliente.sexo.toString(),label:getGeneroName(cliente.sexo.toString())});
    const [estadoCivil,setEstadoCivil]          = useState<OptionType>({"value":cliente.estadoCivil,"label":cliente.estadoCivil});
    const [tipoCuilCuit,setTipoCuilCuit]        = useState<OptionType>({"value":cliente.tipoCuilCuit,"label":getLabelByList(tipoCuilCuitList,cliente.tipoCuilCuit)});
    const [prestadorCelular,setPrestadorCelular]= useState<OptionType>({"value":cliente.prestadorCelular,"label":getLabelByList(prestadorCelularList,cliente.prestadorCelular)});
    const [cuit1,setCuit1]                      = useState("");
    const [cuit2,setCuit2]                      = useState("");
    const [cuit3,setCuit3]                      = useState("");
    
    const refDomComReal                         = React.useRef(null);

    useEffect(() => {
        setCuit1(cliente.cuilCuit.split("-")[0]);
        setCuit2(cliente.cuilCuit.split("-")[1]);
        setCuit3(cliente.cuilCuit.split("-")[2]);
    },[])


    const handleChangeTipoDni = (event: OptionType) => {
        console.log(event)
        // @ts-ignore
        setTipoDni({"value":event.value,"label":event.value});
        // @ts-ignore
        cliente.tipoDni = event.value as PerfilCliente["tipoDni"];
        // @ts-ignore
        setValue("tipoDni",event.value as PerfilCliente["tipoDni"])
        console.log(cliente)
        //setValue('tipoDni', (event.target.value as PerfilCliente["tipoDni"]), { shouldValidate: true, shouldDirty: true });
      };
      const handleChangePrestadorCelular = (event: OptionType) => {
        console.log(event)
        // @ts-ignore
        setPrestadorCelular({"value":event.value,"label":event.value});
        // @ts-ignore
        cliente.prestadorCelular = event.value as PerfilCliente["prestadorCelular"];
        // @ts-ignore
        setValue("prestadorCelular",event.value as PerfilCliente["prestadorCelular"])
        console.log(cliente)
        //setValue('tipoDni', (event.target.value as PerfilCliente["tipoDni"]), { shouldValidate: true, shouldDirty: true });
      };
      const chgRadios = (value: number,id:string) => {
            setValue(id,value);
            clearErrors(id);
        }
      const handleChangeSexo = (event : OptionType) => {
        const val = (event !== null ? event.value : "");
        const t : unknown = val;
        setValue("sexo",t as PerfilCliente["sexo"]);
        cliente.sexo = t as PerfilCliente["sexo"];

        setGenero({"value":t.toString(),"label":getGeneroName(t.toString())});
      };
      const notCero = (nro :number) => {
        if (nro === 0 || nro === null) { return false;}
        return true;
      }
      const fnCuit1 = (e,id) => {
        console.log(e + " " + id)
        if (id === "cuit1") {
            setCuit1(e);
            clearErrors('cuilCuit');
            
        }
        if (id === "cuit2") {
            setCuit2(e);
            clearErrors('cuilCuit');
            
        }
        if (id === "cuit3") {
            setCuit3(e);
            clearErrors('cuilCuit');
            
        }
        console.log(validateCuit(cuit1,cuit2,cuit3))
        if (!validateCuit(cuit1,cuit2,cuit3)) {
            setError("cuilCuit",{
                type: "manual",
                message: "Cuit incorrecto"
              });
              trigger("cuilCuit")
        } else {
            setValue("cuilCuit", cuit1 + "-" + cuit2 + "-" + cuit3);
        }
      }
      const wrapperChangeEstadoCivil = (event: OptionType) => {
        handleChangeEstadoCivil({event,setEstadoCivil, cliente,setValue});
      }
      const wrapperChangeTipoCuilCuit = (event: OptionType) => {
        handleChangeTipoCuilCuit({event, setTipoCuilCuit,cliente,setValue,trigger});
      }
      const handleClickDomComReal = () => {
        setDomComIgualReal(!refDomComReal.current.checked)
        refDomComReal.current.checked = !refDomComReal.current.checked;
      }
    return (
        <Card className="custom-card">
            <Card.Body>
                <Row >
                    <Grid item xs={12} sm={12} md={12} style={{marginTop:"-20px",marginBottom:"15px", textAlign:"center"}} >
                        <h4>{title}</h4>
                        <h5>{subtitle}</h5>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                    <Row>
                    <Form.Group className="form-group">
                      <p className="mg-b-1 fontSm" >Nombre<span className="tx-danger">*</span>:</p>
                      <Form.Control
                        size="sm"
                        type="text" 
                        id="nombre"
                        placeholder="Nombre"
                        className={(errors.givenName ? "form-control is-invalid state-invalid" : "form-control")}
                        {...register("givenName", { required: true, maxLength: 50 })}
                        />
                        {errors.givenName && errors.givenName.type.toString() === 'required' && <div className="invalid-feedback">Requerido</div> }
                        {errors.givenName && errors.givenName.type.toString() === 'maxLength' && <div className="invalid-feedback">Máximo 50 caracteres</div> }
                        { errors.givenName?.type === "firstLetterUpper" && <div className="invalid-feedback">La primer letra debe ser mayuscula</div> }
                    </Form.Group>
                    </Row>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                  <Row>
                    <Form.Group className="form-group">
                      <p className="mg-b-1 fontSm" >Apellido<span className="tx-danger">*</span>:</p>
                      <Form.Control
                        size="sm"
                        type="text" 
                        id="familyName"
                        placeholder="Nombre"
                        className={(errors.familyName ? "form-control is-invalid state-invalid" : "form-control")}
                        {...register("familyName", { required: true, maxLength: 50 })}
                        />
                        {errors.familyName && errors.familyName.type.toString() === 'required' && <div className="invalid-feedback">Requerido</div> }
                        {errors.familyName && errors.familyName.type.toString() === 'maxLength' && <div className="invalid-feedback">Máximo 50 caracteres</div> }
                    </Form.Group>
                    </Row>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} >
                    <Row>
                        <Grid item xs={6} sm={6} md={6} >
                            <p className="mg-b-1 fontSm" >Tipo Doc<span className="tx-danger">*</span>:</p>
                            <Select  classNamePrefix="Select2" 
                            {...register("tipoDni", { required: true })}
                            value={tipoDni} options={tipoDoc} styles={customStyles} onChange={handleChangeTipoDni}/>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} >
                            <Form.Group className="form-group">
                            <p className="mg-b-1 fontSm" >Nro. de documento<span className="tx-danger">*</span>:</p>
                            <Form.Control
                            size="sm"
                            type="number" 
                            id="dniCuit"
                            placeholder="Nro. de documento"
                            className={(errors.dni ? "form-control is-invalid state-invalid" : "form-control")}
                            {...register("dni", { required: true, valueAsNumber: true,pattern: {
                                value: /^[0-9+-]+$/,
                                message: "This is not a valid mobile phone to me, try again!"
                            }})}
                            />
                            {errors.dni && errors.dni.type.toString() === 'required' && <div className="invalid-feedback">Requerido</div> }
                            {errors.dni && errors.dni.type.toString() === 'valueAsNumber' && <div className="invalid-feedback">Solo se permiten números sin punto ni coma</div> }
                        </Form.Group>
                        </Grid>
                    </Row>
                </Grid>
                <Grid item xs={12} sm={12} md={6} >
                    <Row>
                        <Grid item xs={6} sm={6} md={6} >
                            <Form.Group className="form-group" >
                                <p className="mg-b-1 fontSm" >Fecha de Nacimiento<span className="tx-danger">*</span>:</p>
                                <InputGroup className="input-group" size="sm">
                                    <Button variant="light" type="button" size="sm" style={{fontSize:"0.77rem"}}>
                                    <i className="fe fe-calendar lh--9 op-6"></i>
                                    </Button>
                                    <DatePicker
                                    autoComplete="off"
                                    dateFormat="dd/M/yyyy"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    locale="es"
                                    onFocus={e => e.target.blur()}
                                    selected={new Date(cliente.fechaNac)}
                                    className={(errors.fechaNac ? "form-control form-control-sm is-invalid state-invalid" : "form-control form-control-sm")}
                                    {...register("fechaNac", { required: true,validate: { fechaNacValidate }
                                    })}

                                    onChange={(value) => 
                                        {
                                        console.log(value)
                                            //cliente.dateBirth = value;
                                            setValue('fechaNac', value, { shouldValidate: true, shouldDirty: true });
                                        // console.log(cliente)
                                        cliente.fechaNac =value;
                                        }
                                    }
                                    
                                    
                                    />
                                </InputGroup>
                                {errors.fechaNac && errors.fechaNac.type.toString() === 'required' && <div style={{fontSize: "80%",color: "#f16d75"}}>Requerido</div> }
                                {errors.fechaNac?.message && <div style={{fontSize: "80%",color: "#f16d75"}}>{errors.fechaNac?.message}</div>}
                                </Form.Group>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} >
                            <Form.Group className="form-group">
                                <p className="mg-b-1 fontSm" >Género<span className="tx-danger">*</span>:</p>
                                <Select  classNamePrefix="Select2" placeholder="Seleccionar"
                                {...register("sexo", { required: true, validate: { notCero } }) }
                                onChange={handleChangeSexo}
                                value={genero} options={generoList} styles={customStyles} />
                            </Form.Group>
                        </Grid>
                    </Row>
                </Grid>

                
                <Grid item xs={12} sm={12} md={6} >
                    <Row>
                        <Grid item xs={6} sm={6} md={6} >
                            <p className="mg-b-1 fontSm" >Estado civil<span className="tx-danger">*</span>:</p>
                            <Select  classNamePrefix="Select2"  placeholder="Seleccionar"
                            {...register("estadoCivil", { required: true })}
                            value={estadoCivil} options={estadoCivilList} styles={customStyles} onChange={wrapperChangeEstadoCivil}/>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} >
                            <InputCustom 
                                validationList={[{message:"Requerido",type:"required"},{message:"Máximo 128 caracteres",type:"maxLength"}] as formRequired[]} 
                                errors={errors} id="nacionalidad" 
                                label="Nacionalidad" 
                                register={register("nacionalidad", { required: true,maxLength: 128 })} 
                                />

                        </Grid>
                    </Row>
                </Grid>
                <Grid item xs={12} sm={12} md={6} >
                    <Row>
                        <Grid item xs={6} sm={6} md={6} >
                            <InputCustom 
                                validationList={[{message:"Requerido",type:"required"},{message:"Máximo 45 caracteres",type:"maxLength"}] as formRequired[]} 
                                errors={errors} id="lugarNacimiento" 
                                label="Lugar de Nacimiento" 
                                register={register("lugarNacimiento", { required: true,maxLength: 45 })} 
                                />
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} >
                        <SelectCustom 
                                validationList={[{message:"Requerido",type:"required"}] as formRequired[]} 
                                errors={errors} id="tipoCuilCuit" 
                                label="Cuil/Cuit"
                                value={tipoCuilCuit}
                                register={register("tipoCuilCuit", { required: true })}
                                options={tipoCuilCuitList} 
                                wrapper={wrapperChangeTipoCuilCuit}/>
                        </Grid>
                    </Row>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Form.Group className="form-group">
                      <p className="mg-b-1 fontSm" >CUIT/CUIL<span className="tx-danger">*</span>:</p>
                      <MaskInputCustom name="cuilCuit"
                        register={{validate: { cuilCuitValidate } }as any} control={control}/>
                        {errors.cuilCuit && errors.cuilCuit.type.toString() === 'required' && <div className="invalid-feedback">Campo requerido</div> }
                        {errors.cuilCuit && errors.cuilCuit.type.toString() === 'cuilCuitValidate' && <div className="invalid-feedback">{errors.cuilCuit.message}</div> }
                    </Form.Group>
                  </Grid>
                <Grid item xs={12} sm={12} md={6} >
                    <Row>
                        <Grid item xs={12} sm={12} md={6} >
                            <InputCustom 
                                validationList={[{message:"Requerido",type:"required"},{message:"Máximo 45 caracteres",type:"maxLength"}] as formRequired[]} 
                                errors={errors} id="profesion" 
                                label="Profesión" 
                                register={register("profesion", { required: true,maxLength: 45 })} 
                                />

                        </Grid>
                    </Row>
                </Grid>
                <Grid item xs={12} sm={12} md={12} >
                    <DatosTablaSolicitud chgRadios={chgRadios} cliente={cliente} errors={errors} register={register} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} style={{marginTop:"15px",marginBottom:"15px"}}>
                    <InputCustom 
                        validationList={[{message:"Máximo 128 caracteres",type:"maxLength"}] as formRequired[]} 
                        errors={errors} id="nroIngresosBrutos" 
                        label="Número de Ingresos Brutos" 
                        register={register("nroIngresosBrutos", { maxLength: 128 })} 
                        />
                </Grid>
                <hr></hr>
                <DomicilioReal errors={errors} register={register}/>

                <hr></hr>
                <Grid item xs={12} sm={12} md={12} style={{marginTop:"0px",marginBottom:"15px", textAlign:"center"}} >
                    <h5>Domicilio Comercial/Laboral</h5>
                </Grid>
                <Grid item xs={12} sm={12} md={12} style={{marginTop:"-15px"}}>
                    <Row className="row-sm">
                        <Col lg={3} onClick={handleClickDomComReal}>
                        <Form.Check ref={refDomComReal} type="checkbox"  defaultChecked={domComIgualReal} onClick={handleClickDomComReal}
                            label="Utilizar los datos de domicilio real"
                        />
                        </Col>
                    </Row>
                </Grid>
                { !domComIgualReal &&
                    (
                        <DomicilioComercial errors={errors} register={register} setValue={setValue}/>
                    )
                }
                <hr></hr>
                <DatosContacto register={register} errors={errors} setValue={setValue} cb={handleChangePrestadorCelular} prestadorCelular={prestadorCelular}/>
                <hr style={{marginTop:"15px"}}></hr>
                {cliente.estadoCivil && cliente.estadoCivil === EnumEstadoCivil.CASADO && <DatosConyuge register={register} errors={errors} cliente={cliente} setValue={setValue} trigger={trigger} />}
                <DatosDeclaracioJurada register={register} errors={errors} cliente={cliente} setValue={setValue} trigger={trigger} chgRadios={chgRadios} />
                </Row>
            </Card.Body>
        </Card>
    );
}