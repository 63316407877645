import * as React from "react";
import { useState, useEffect } from 'react';
import Select , { InputProps, SingleValue } from "react-select";
import { Breadcrumb, Button, Card, Col, Row, Form, InputGroup } from "react-bootstrap";
import {
    Paper,
    Box,
    Grid,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem
  } from '@material-ui/core';
import { FieldErrorsImpl } from "react-hook-form";
import { SolicitudProducto3 } from "../../types/productos/SolicitudProducto3";
import { OptionType, tipoCuilCuitList } from "../../data/forms/GeneralData";
import { customStyles } from "../../utils/GeneralUtils";
interface Props {
    id:string;
    label?:string;
    register? : any;
    rows: number;
    errors : FieldErrorsImpl;
    validationList?: formRequired[];
    defaultValue?:any;
    onChangeFn?:any;
    idErrorCustom?:string;//si el id error no es el mismo que el id del componente
    Props?: {attr:string,value:any}[];
    autoComplete?: "nope"|"";
    setValue?:any;
}
export interface formRequired {
    type:string;
    message:string;
}
const chkIsRequired = (validationList,setRequited) => {
    validationList && (validationList.forEach( t => {
        if (t.type === 'required') {
            setRequited(true);
        }
    }))
}
export const TextareaCustom: React.FC<Props> = ({id,label,register,errors,validationList,defaultValue,onChangeFn,idErrorCustom,Props,autoComplete,setValue,rows} : Props) => {
    const [isRequired,setRequited] = useState<boolean>(false);
    let props = {} as InputProps;
    let tmp = {} as InputProps;
    const errId = (idErrorCustom === undefined ? id : idErrorCustom);
    if (defaultValue !== undefined && defaultValue !== null) {
        props["value"] = defaultValue;
    }
    if (Props !== undefined) {
        Props.forEach ( t => {
            props[t.attr] = t.value;
        })
    }
    if (register === undefined) {
        register = tmp;
    }
    useEffect(() => {
        chkIsRequired(validationList,setRequited);
    },[]);
    const onChange = (e) => {
        if (onChangeFn !== null && onChangeFn !== undefined) {
            onChangeFn(e,id);
        }
        if (setValue) {
            setValue(id,e);
        }
    }
    return (
        <>
            <Form.Group className="form-group">
                {label && <p className="mg-b-1 fontSm" >{label}{isRequired && <span className="tx-danger">*</span>}:</p>}
                <Form.Control
                size="sm"
                as="textarea" 
                id={id}
                rows={rows}
                autoComplete={autoComplete}
                placeholder={label}
                {...props}
                className={(errors[errId] ? "form-control is-invalid state-invalid" : "form-control")}
                {...register}
                onChange={(d : React.ChangeEvent<HTMLInputElement>) => {onChange(d.target.value)}}
                />
                {
                    validationList && validationList.map((t,index) => 
                        errors[errId] && errors[errId].type.toString() === t.type && <div key={index} className="invalid-feedback">{t.message}</div>
                    )
                }
            </Form.Group>
        </>
    )
}
