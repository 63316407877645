import * as React from "react";
import { Breadcrumb, Button, Card, Col, Row, Form, InputGroup,Table } from "react-bootstrap";

import {Grid} from '@material-ui/core';
import { formRequired, InputCustom, RadioBooleanCustom, SelectCustom } from "../../../../../Commons/InputsCustom";
import { SolicitudProducto3 } from "../../../../../../types/productos/SolicitudProducto3";
import { FieldErrorsImpl } from "react-hook-form";
import PerfilCliente from "../../../../../../types/personas/PerfilCliente";
import { getLabelByList, OptionType, tipoCuilCuitListCDI } from "../../../../../../data/forms/GeneralData";
import { handleChangeTipoCuilCuit } from "../../../../../../utils/GeneralUtils";

interface Props {
    errors: FieldErrorsImpl<SolicitudProducto3>;
    register:any;
    cliente: PerfilCliente;
    setValue: any;
    trigger: any;
}

export const DatosConyuge: React.FC<Props> = ({errors,register,cliente,setValue,trigger}) => {
    const [checkConyuge,setCheckConyuge]    = React.useState<boolean>(true);
    const refConyuge                        = React.useRef(null);
    const [tipoCuilCuit,setTipoCuilCuit]    = React.useState<OptionType>({"value":cliente.conyugeTipoCuilCuit,"label":getLabelByList(tipoCuilCuitListCDI,cliente.conyugeTipoCuilCuit)});
    const handleClickConyuge = () => {
        setCheckConyuge(!refConyuge.current.checked)
        refConyuge.current.checked = !refConyuge.current.checked;
      }
    const wrapperChangeTipoCuilCuit = (event: OptionType) => {
        setTipoCuilCuit({"value":event.value,"label":(event.value === "" ? "Seleccionar" : event.value)});//setCuilCuit
 
        
        cliente.conyugeTipoCuilCuit = event.value as PerfilCliente["conyugeTipoCuilCuit"];
        setValue("conyugeTipoCuilCuit",event.value as PerfilCliente["conyugeTipoCuilCuit"])

        trigger("conyugeTipoCuilCuit");
        //handleChangeTipoCuilCuit({event, setTipoCuilCuit,cliente,setValue,trigger});
     }
    React.useEffect(() => {
       /* const isConyugeData = () => {
            if (cliente.conyugeCuilCuit !== null || cliente.conyugeNomApe !== null || cliente.conyugeTipoCuilCuit !== null || cliente.conyugeTipoNroDoc !== null) {
                setCheckConyuge(true)
                refConyuge.current.checked = true;
            }
        }
        isConyugeData();*/
    },[])
    return (<>
        <Grid item xs={12} sm={12} md={12} style={{marginTop:"0px",marginBottom:"15px", textAlign:"center"}} >
            <h5>Datos del Cónyuge</h5>
        </Grid>
        {/*<Grid item xs={12} sm={12} md={12} style={{marginTop:"-15px"}}>
            <Row className="row-sm">
                <Col lg={12} onClick={handleClickConyuge}>
                <Form.Check ref={refConyuge} type="checkbox"  defaultChecked={checkConyuge} onClick={handleClickConyuge}
                    label="Checkear este campo en caso de declarar CASADO o UNION CIVIL"
                />
                </Col>
            </Row>
    </Grid>*/}
        {checkConyuge && 
            (
                <>
                <Grid item xs={12} sm={12} md={6} >
                    <Row>
                        <Grid item xs={12} sm={12} md={6}>
                            <InputCustom 
                                validationList={[{message:"Requerido",type:"required"},{message:"Máximo 255 caracteres",type:"maxLength"}] as formRequired[]} 
                                errors={errors} id="conyugeNomApe" 
                                label="Apellido y Nombres" 
                                register={register("conyugeNomApe", { required:true,maxLength: 255 })} 
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <InputCustom 
                                validationList={[{message:"Requerido",type:"required"},{message:"Máximo 45 caracteres",type:"maxLength"}] as formRequired[]} 
                                errors={errors} id="conyugeTipoNroDoc" 
                                label="N° documento" 
                                register={register("conyugeTipoNroDoc", { required:true,maxLength: 45 })} 
                                />
                        </Grid>
                    </Row>
                </Grid>
                <Grid item xs={12} sm={12} md={6} >
                <Row>
                    <Grid item xs={12} sm={12} md={6}>
                        <SelectCustom 
                            validationList={[{message:"Requerido",type:"required"}] as formRequired[]} 
                            errors={errors} id="conyugeTipoCuilCuit" 
                            label="Cuil/Cuit"
                            value={tipoCuilCuit}
                            register={register("conyugeTipoCuilCuit", { required: true })}
                            options={tipoCuilCuitListCDI} 
                            wrapper={wrapperChangeTipoCuilCuit}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <InputCustom 
                            validationList={[{message:"Requerido",type:"required"},{message:"Máximo 45 caracteres",type:"maxLength"}] as formRequired[]} 
                            errors={errors} id="conyugeCuilCuit" 
                            label="N° CUIL/CUIT" 
                            register={register("conyugeCuilCuit", { required:true,maxLength: 45 })} 
                            />
                    </Grid>
                </Row>
                </Grid>
                </>
            )
        }
    </>)
}