import * as React from "react";
import { useState, useEffect } from 'react';
import DataTable, { TableColumn } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Button, Col, Form, Row } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";
import PerfilCliente, { PerfilClientePlus } from "../../../types/personas/PerfilCliente";
import { fechaScreen, getObjectFromList } from "../../../utils/GeneralUtils";
import { useAxios } from "../../../hooks/useAxios";
import { ResponseContainer } from "../../../types/general/GeneralTypes";
import Searchable from "react-searchable-dropdown";
import PerfilEmpresa from "../../../types/empresas/PerfilEmpresa";
import { faPenSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Solicitud } from "../../../types/productos/SolicitudProducto";
import Asociados from "../../../types/empresas/Asociados";
import { IOptionType, listCaracterUsuEmpresa,  workflowList } from "../../../data/forms/GeneralData";
import { ModalViewEmpresasByPersona } from "./components/ModalViewEmpresasByPersona";
import { ModalViewSolicitudProdByPersona } from "./components/ModalViewSolicitudProdByPersona";
interface tableData {
  name : string;
  selector?: any;
  cell?:any;
  sortable: boolean;
}
interface Props {
  list: PerfilCliente[];
}


export const DataTablePersona = ({list} : Props) => {
  const axiosInstance                 = useAxios();
  const [totalRows, setTotalRows]     = useState(0);
  const [perPage, setPerPage]         = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading]         = useState(false);
  const [filterWf,setFilterWf]        = useState<IOptionType>(null);
  const [workflowListPlus,setWorkflowListPlus]= useState<IOptionType[]>([]);
  const [viewAsociados,setViewAsociados]      = useState(false);
  const [asociados,setAsociados]              = useState<Asociados[]>();
  const [viewSolicitudes,setViewSolicitudes]  = useState(false);
  const [solicitudes,setSolicitudes]          = useState<Solicitud[]>();
  const [data, setData]                   = useState<PerfilCliente[]>(list);

  const handleWorkflowListPlus = () => {
    setWorkflowListPlus(workflowListPlus => [...workflowListPlus, {value:null , label:"Seleccionar"}])

    workflowList.forEach(t => {
      setWorkflowListPlus(workflowListPlus => [...workflowListPlus, t])
    })
  }
  const viewEmpresas = (data : Asociados[]) => {
    /*console.log(data)
    console.log(data[0].idCaracter + " - " + getObjectFromList(listCaracterUsuEmpresa,data[0].idCaracter).label)
    console.log(data[0].empresa)*/
    setViewAsociados(true);
    setAsociados(data);
  }
  const handleViewSolicitudes = (data : Solicitud[]) => {
    console.log(data)
    setSolicitudes(data);
    setViewSolicitudes(true);
  }
  const fetchPersonas = (page, size = perPage) => {
    setLoading(true);
    (axiosInstance !== undefined && axiosInstance.current !== undefined && (
      axiosInstance.current.get<ResponseContainer<PerfilCliente[]>>('/api/personas?page=' + page + '&size=' + size + "&wf=" + (filterWf ? filterWf.value : ""))
        .then(res => {
          setLoading(true);
          setData(res.data.data);
          setTotalRows(res.data.recordsTotal);
          setLoading(false);
        })
      .catch((err: { response: any; }) => console.dir(err.response))     ))
  }

  const CellComponent = ({row}) => {
    let data = row as PerfilClientePlus;
    return (
      <>
      {data.empresas.length>0 ? (
      <>
        <Button variant="primary" size="sm" title="Ver Empresas"
          onClick={() => viewEmpresas(data.empresas)}
          style={{ marginRight: "5px" }}
        >
          <i className="fa fa-building"></i> 
        </Button>
      </>
      ): <></>
    }
    
    {data.productoSolicitudes && data.productoSolicitudes.length>0 ? 
              <>
                <Button variant="primary" size="sm" title="Ver Solicitudes"
                  onClick={() => handleViewSolicitudes(data.productoSolicitudes)}
                  style={{ marginRight: "5px" }}
                >
                  <FontAwesomeIcon icon={faPenSquare} />
                </Button>
              </> : 
    <></>
    }
    </>
    )
       
  
  }

  useEffect(() => {
    fetchPersonas(1,10);
    handleWorkflowListPlus();
  }, []);
  useEffect(() => {
    fetchPersonas(1,10);
  }, [filterWf]);
  const handlePageChange = page => {
    fetchPersonas(page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    
    fetchPersonas(page,newPerPage);
    setPerPage(newPerPage);
  };
  const columns : tableData[] = [
    {
      name: "Nombre",
      selector: row => [row.givenName],
      sortable: true
    },
    {
      name: "Apellido",
      selector: row => [row.familyName],
      sortable: true
    },
    {
      name: "CUIL/CUIT",
      selector: row => [row.cuilCuit],
      sortable: true
    },
    {
      name: "FEC. NAC.",
      selector: row => {
        let a = row as PerfilCliente;
        const fec = new Date(a.fechaNac);
        return fechaScreen(fec);
      },
      sortable: true
    },
    {
      name: "FEC. ALTA",
      selector: row => {
        let a = row as PerfilCliente;
        const fec = new Date(a.fechaAlta);
        return fechaScreen(fec);
      },
      sortable: true
    },
    {
      name:"",
      cell: row =>
        
         // let data = row as PerfilClientePlus;
         <CellComponent row={row}/>

      ,
      sortable:false
    }
  ];

  const tableDatas = {
    columns,
    data,
  };
 
  return (
    <>
    <Row className="row-lg">
        <Col lg={4} md={4}>
          <Form.Group className="form-group select2-sm">
          <p className="mg-b-10">Solicitud en estado:</p>
                <Searchable className="form-control select2"
                value="test"
                placeholder="Seleccionar" // by default "Search"
                notFoundText="No result found" // by default "No result found"
                noInput
                options={workflowListPlus}
                onSelect={(value) => {
                  let val : number = value as any
                  setFilterWf(getObjectFromList(workflowList,value));
                }}
                listMaxHeight={140} //by default 140
          />
          </Form.Group>

        </Col>
    </Row>
    <Row className="row-lg">
        <Col lg={12} md={12}>
   
      <DataTable
        title
        columns={columns}
        data={data}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={currentPage}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
      </Col>
    </Row>
    <ModalViewEmpresasByPersona asociados={asociados} estado={viewAsociados} setEstado={setViewAsociados}/>
    <ModalViewSolicitudProdByPersona solicitudes={solicitudes} setEstado={setViewSolicitudes} estado={viewSolicitudes} />
    </>
    
  );
}



