import * as React from "react";
import { useAxios } from "../hooks/useAxios";
import { GenericKeyValue } from "../types/general/GeneralTypes";
import { toastError, toastSuccess } from "./GeneralUtils";

interface IUploadFileUtil {
    formValues          : GenericKeyValue<string,string>[];
    extensionAccepted   : string[];
    onFinish?           : any;
    txtToast?           : string;
    idInput?            : string;
    path                : string;
    accept              : string;
    id?                 : number;
    onInitUpload?       : (id: number) => void
  }
  
  
  export const UploadFileUtil: React.FC<IUploadFileUtil> = ({formValues,extensionAccepted,onFinish,txtToast,idInput,path,accept,id,onInitUpload} : IUploadFileUtil) => {
    const axiosInstance                       = useAxios();
    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
  
        const selectedFile = e.target.files[0];
        const formData = new FormData();
        formData.append("file", selectedFile);
        formValues.map(t => {
          formData.append(t.key, t.value);
        })
        
          let nombreArchivo = selectedFile.name;
          let arrayNombre =  nombreArchivo.split(".")
          let extencionArchivo = arrayNombre[arrayNombre.length -1].toString().toLocaleUpperCase();
          const accept = extensionAccepted.filter(t => (t.toLocaleUpperCase()===extencionArchivo));
          if (
      
            accept.length>0
          ) {
            onInitUpload(id);
            axiosInstance.current.post(path ,formData).then((res) => {/*axiosInstance.current.post<GenericKeyValue<String,String>[]>*/
                if (onFinish !== undefined) {
                    onFinish(res.data,id);
                }
                if (txtToast) {
                  toastSuccess(txtToast);
                }
                
              
            })
            
          } else {
            
            toastError("El archivo debe ser " + extensionAccepted);
          }
      
      };

    return (<input
      onChange={(e) => handleFileUpload(e)}
      id={idInput === undefined ? "file_upload" : idInput}
      type="file"
      accept={accept}
      hidden
    />)
  }