import * as React from "react";
import { useState, useEffect } from 'react';
import DataTable, { TableColumn } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

// import { tableDataItems } from ""
import { Button, Spinner } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";
import { fechaScreen, toastError, toastSuccess } from "../../../../utils/GeneralUtils";
import { useAxios } from "../../../../hooks/useAxios";
import { GenericKeyValue } from "../../../../types/general/GeneralTypes";
import Documento from "../../../../types/documentos/Documento";
import { ModalResponseUploadPdf } from "./ModalResponseUploadPdf";

interface tableData {
  name? : string;
  selector?: any;
  cell?:any;
  sortable: boolean;
}
interface Props {
  documentos: Documento[];
  reloadFn?: any;
  id: string;
}
interface DocumentoProp extends Documento {
  isLoading:boolean;
}
export const DataTableDocPorProd = ({documentos,reloadFn,id} : Props) => {
  const axiosInstance                       = useAxios();
  const [data, setData]                     = useState<DocumentoProp[] | null>();

  const [idDocumento,setIdDocumento]        = useState<number>();
  const [uploadResponse,setUploadResponse]  = useState<GenericKeyValue<String,String>[]>();
  const [showUploadModal,setShowUploadModal]= useState<boolean>(false);
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {

    

    refreshDataWith(true);
    const selectedFile = e.target.files[0];
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("idDocumento", idDocumento.toString());
      let nombreArchivo = selectedFile.name;
      let arrayNombre =  nombreArchivo.split(".")
      let extencionArchivo = arrayNombre[arrayNombre.length -1].toString().toLocaleUpperCase();
      if (
        extencionArchivo == "PDF"
      ) {
 
          axiosInstance.current.post<GenericKeyValue<String,String>[]>("apiv2/documento/upload" ,formData).then((res) => {
            if (res.data.length === 0) {
              //dataSelected.isLoading = false;
              toastSuccess('Archivo Subido');
              reloadFn(id);
            } else {
              setShowUploadModal(true);
              setUploadResponse(res.data);
              refreshDataWith(false);
            }
            
          }).catch((e) => {
            refreshDataWith(false);
            toastError("El archivo no se puede procesar");
          })

        
        
      } else {
        refreshDataWith(false);
        toastError("El archivo debe ser JPG, PNG, PDF, DOC, DOCX, XLS o XLSX");
      }
  
  };

  const refreshDataWith = (state:boolean) => {
    const dataTmp : DocumentoProp[] = data.map((t:DocumentoProp) => {
      if (t.id === idDocumento) {
        t.isLoading = state;
      }
      return t;
    });
    setData(dataTmp);
  }

  useEffect(() => {
    if (documentos) {
      setData(documentos.map((d) => {
        const res : DocumentoProp = d as any;
        res.isLoading = false;
        return res as any;
      }))
    }
  }, [documentos]);

  const descargar = (doc : Documento) => {
    if (doc.archivos.length>0) {
      downloadModel(doc);
    } else {
      toastError("El documento no tiene archivos asociados")
    }
    
  }
  
  const downloadModel = (doc : Documento) => {
    axiosInstance.current({
      url: '/apiv2/documentos/descarga/' + doc.id,
      method: 'GET',
      responseType: 'blob', // important
    })
      .then((response: { data: BlobPart; }) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${doc.nombre}.pdf`);
        document.body.appendChild(link);
        link.click();
        
      });
 }

  const uploadFile = (id : number) => {
    console.log(id);
  }
  const columns : tableData[] = [
    {
      name: "Nombre",
      selector: row => [row.nombre],
      sortable: true
    },
    {
      name: "Descripción",
      selector: row => [row.descripcion],
      sortable: true
    },
    {
      cell: row =>
        (
          (row.archivos.length>0 ?
          <>
            <Button variant="primary" size="sm"
              onClick={() => descargar(row)}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-download"></i> Descargar
            </Button>
            {/*<button onClick={() => props.click(row.name)}>Delete</button>*/}
          </> : "")
        ),
      sortable:false
    },
    {
      cell: row =>{
        const data : DocumentoProp = row as any;
        return (
          data.tipoDoc === 1 ? 
          <>
            {!data.isLoading ? <Button variant="primary" size="sm"
              onClick={() => {
                setIdDocumento(data.id)
                //document.getElementById('file_upload').val
                document.getElementById('file_upload').click();
              }}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-upload"></i> Actualizar Template
            </Button> : <Spinner animation="border" role="status" className="spinner-border spinner-border-sm"><span className="visually-hidden">Loading...</span></Spinner>}
            {/*<button onClick={() => props.click(row.name)}>Delete</button>*/}
          </> : <></>
        )},
      sortable:false
    }
  ];

  const tableDatas = {
    columns,
    data,
  };
  return (
    <>
    <DataTableExtensions {...tableDatas} >
      <DataTable
        title
        noDataComponent="No se encontraron documentos"
        columns={columns}
        data={data}
        pagination
        highlightOnHover
      />
    </DataTableExtensions>
    <ModalResponseUploadPdf list={uploadResponse} estado={showUploadModal} setShow={setShowUploadModal} />
    <input
            onChange={(e) => handleFileUpload(e)}
            id="file_upload"
            type="file"
            hidden
          />
    </>
    
  );
}



