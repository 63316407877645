import * as React from "react";
import { Breadcrumb, Button, Card, Col, Row, Form, InputGroup,Table } from "react-bootstrap";

import {Grid} from '@material-ui/core';
import { formRequired, InputCustom } from "../../../../../Commons/InputsCustom";
import { SolicitudProducto3 } from "../../../../../../types/productos/SolicitudProducto3";
import { FieldErrorsImpl } from "react-hook-form";

interface Props {
    errors: FieldErrorsImpl<SolicitudProducto3>;
    register:any;
    setValue:any;
}

export const DomicilioComercial: React.FC<Props> = ({errors,register,setValue}) => {

    return (
        <>

        <Grid item xs={12} sm={12} md={6} >
            <Row>
                <Grid item xs={7} sm={7} md={8}>
                    <InputCustom 
                        validationList={[{message:"Requerido",type:"required"},{message:"Máximo 128 caracteres",type:"maxLength"}] as formRequired[]} 
                        errors={errors} id="calleComercial" 
                        label="Calle" 
                        setValue={setValue}
                        register={register("calleComercial", { required:true,maxLength: 128 })} 
                        />
                </Grid>
                <Grid item xs={5} sm={5} md={4}>
                    <InputCustom 
                        validationList={[{message:"Requerido",type:"required"},{message:"Máximo 12 caracteres",type:"maxLength"}] as formRequired[]} 
                        errors={errors} id="alturaComercial" 
                        label="Altura" 
                        setValue={setValue}
                        register={register("alturaComercial", { required:true,maxLength: 12 })} 
                        />
                </Grid>
            </Row>
        </Grid>
        

        <Grid item xs={12} sm={12} md={6} >
            <Row>
                <Grid item xs={4} sm={4} md={4}>
                    <InputCustom 
                        validationList={[{message:"Máximo 12 caracteres",type:"maxLength"}] as formRequired[]} 
                        errors={errors} id="pisoComercial" 
                        label="Piso" 
                        setValue={setValue}
                        register={register("pisoComercial", { maxLength: 12 })} 
                        />
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <InputCustom 
                        validationList={[{message:"Máximo 12 caracteres",type:"maxLength"}] as formRequired[]} 
                        errors={errors} id="dptoComercial" 
                        setValue={setValue}
                        label="Dpto" 
                        register={register("dptoComercial", { maxLength: 12 })} 
                        />
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <InputCustom 
                        validationList={[{message:"Requerido",type:"required"},{message:"Máximo 24 caracteres",type:"maxLength"}] as formRequired[]} 
                        errors={errors} id="codPostalComercial" 
                        setValue={setValue}
                        label="C.P. ampliado" 
                        register={register("codPostalComercial", {required:true, maxLength: 24 })} 
                        />
                </Grid>
            </Row>
        </Grid>

        <Grid item xs={12} sm={12} md={12} style={{marginBottom:"15px"}} >
            <Row>
                <Grid item xs={12} sm={12} md={6}>
                    <InputCustom 
                        validationList={[{message:"Requerido",type:"required"},{message:"Máximo 32 caracteres",type:"maxLength"}] as formRequired[]} 
                        errors={errors} id="localidadComercial" 
                        setValue={setValue}
                        label="Localidad" 
                        register={register("localidadComercial", {required:true, maxLength: 32 })} 
                        />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <InputCustom 
                        validationList={[{message:"Requerido",type:"required"},{message:"Máximo 32 caracteres",type:"maxLength"}] as formRequired[]} 
                        errors={errors} id="provinciaComercial" 
                        setValue={setValue}
                        label="Provincia" 
                        register={register("provinciaComercial", {required:true, maxLength: 32 })} 
                        />
                </Grid>
            </Row>
        </Grid>
        </>
    )
}