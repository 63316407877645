import PerfilEmpresa from "../types/empresas/PerfilEmpresa";
import PerfilCliente from "../types/personas/PerfilCliente";

/**
 * el backend no popula perfilCliente si ese cliente esta en otra empresa. En lugar de venir el objeto viene el id del cliente
 * por lo que busco dentro del listado de empresas el cliente por id donde se encuentre el objeto populado
 * @param list 
 * @param idPerfilCliente 
 * @returns 
 */
export const findPerfilClienteInFecthEmpresas = (list : PerfilEmpresa[], idPerfilCliente : number) : PerfilCliente => {
    let ret = null;
    list.forEach(t => {
        if (t.asociados.length>0) {
            t.asociados.forEach(d => {
                if (isNaN(+d.perfilCliente)) {
                    const cli = ((d.perfilCliente as any) as PerfilCliente);
                    if (cli.id === idPerfilCliente) {
                        ret = cli;
                    }
                }
            })
        }
    });
    return ret;
}