import * as React from "react";
import { useState, useEffect } from 'react';
import Select , { InputProps, SingleValue } from "react-select";
import { Breadcrumb, Button, Card, Col, Row, Form, InputGroup } from "react-bootstrap";
import {
    Paper,
    Box,
    Grid,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem
  } from '@material-ui/core';
import { FieldErrorsImpl } from "react-hook-form";
import { SolicitudProducto3 } from "../../types/productos/SolicitudProducto3";
import { OptionType, tipoCuilCuitList } from "../../data/forms/GeneralData";
import { customStyles } from "../../utils/GeneralUtils";
interface Props {
    id:string;
    label?:string;
    register? : any;
    errors : FieldErrorsImpl;
    validationList?: formRequired[];
    defaultValue?:any;
    onChangeFn?:any;
    idErrorCustom?:string;//si el id error no es el mismo que el id del componente
    Props?: {attr:string,value:any}[];
    autoComplete?: "nope"|"";
    setValue?:any;
}
export interface formRequired {
    type:string;
    message:string;
}
const chkIsRequired = (validationList,setRequited) => {
    validationList && (validationList.forEach( t => {
        if (t.type === 'required') {
            setRequited(true);
        }
    }))
}
export const InputCustom: React.FC<Props> = ({id,label,register,errors,validationList,defaultValue,onChangeFn,idErrorCustom,Props,autoComplete,setValue} : Props) => {
    const [isRequired,setRequited] = useState<boolean>(false);
    let props = {} as InputProps;
    let tmp = {} as InputProps;
    const errId = (idErrorCustom === undefined ? id : idErrorCustom);
    if (defaultValue !== undefined && defaultValue !== null) {
        props["value"] = defaultValue;
    }
    if (Props !== undefined) {
        Props.forEach ( t => {
            props[t.attr] = t.value;
        })
    }
    if (register === undefined) {
        register = tmp;
    }
    useEffect(() => {
        chkIsRequired(validationList,setRequited);
    },[]);
    const onChange = (e) => {
        if (onChangeFn !== null && onChangeFn !== undefined) {
            onChangeFn(e,id);
        }
        if (setValue) {
            setValue(id,e);
        }
    }
    return (
        <>
            <Form.Group className="form-group">
                {label && <p className="mg-b-1 fontSm" >{label}{isRequired && <span className="tx-danger">*</span>}:</p>}
                <Form.Control
                size="sm"
                type="text" 
                id={id}
                autoComplete={autoComplete}
                placeholder={label}
                {...props}
                className={(errors[errId] ? "form-control is-invalid state-invalid" : "form-control")}
                {...register}
                onChange={(d : React.ChangeEvent<HTMLInputElement>) => {onChange(d.target.value)}}
                />
                {
                    validationList && validationList.map((t,index) => 
                        errors[errId] && errors[errId].type.toString() === t.type && <div key={index} className="invalid-feedback">{t.message}</div>
                    )
                }
            </Form.Group>
        </>
    )
}
interface SelectCustomProps {
    id:string;
    label:string;
    register : any;
    errors : FieldErrorsImpl;
    value : any;
    validationList: formRequired[];
    options: OptionType[];
    wrapper: any;
}

interface RadioBooleanProps {
    id:string;
    register : any;
    errors : FieldErrorsImpl<SolicitudProducto3>;
    validationList: formRequired[];
    chgRadio:any;
    val1:any;
    val2:any;
    chk1:boolean;
    chk2:boolean;
    lbl1:string;
    lbl2:string;
    colMd1?:number;//medica col1
    colMd2?:number;//medica col2
}
export const SelectCustom: React.FC<SelectCustomProps> = ({id,label,register,errors,value,validationList,options,wrapper} : SelectCustomProps) => {
    const [isRequired,setRequited] = useState<boolean>(false);
    

    useEffect(() => {
        chkIsRequired(validationList,setRequited);
    },[]);
    return (
        <>
            <p className="mg-b-1 fontSm" >{label}{isRequired && <span className="tx-danger">*</span>}:</p>
            <Select  classNamePrefix="Select2"  placeholder="Seleccionar"
            className={(errors[id] ? "is-invalid state-invalid" : "")}
            {...register}
            value={value} options={options} styles={customStyles} onChange={wrapper}/>
            {
                validationList.map((t,index) => 
                    errors[id] && errors[id].type.toString() === t.type && <div key={index} className="invalid-feedback">{t.message}</div>
                )
            }
        </>
    )
}

export const RadioBooleanCustom: React.FC<RadioBooleanProps> = ({id,register,errors,validationList,chgRadio,val1,val2,chk1,chk2,lbl1,lbl2,colMd1,colMd2} : RadioBooleanProps) => {
    const [isRequired,setRequited]  = useState<boolean>(false);
    const [check1,setCheck1]        = useState<boolean>(chk1);
    const [check2,setCheck2]        = useState<boolean>(chk2);
    const chg = (val) => {
        console.log(val)
        chgRadio(val,id)
        if (val === val1) {
            setCheck1(true);
            setCheck2(false);
        } else if (val === val2) {
            setCheck2(true);
            setCheck1(false);
        }
    }
    useEffect(() => {
        chkIsRequired(validationList,setRequited);
    },[]);

    useEffect(() => {
        setCheck1(chk1)
        setCheck2(chk2)
    },[chk1,chk2]);
    return (
        <>
            <Row >
                <Col lg={(colMd1 === undefined ? 6 : colMd1)} onClick={(e) => {chg(val1)}} style={{marginLeft:"auto"}}>
                    <Form.Check name={id} type="radio" value={val1}
                        label={lbl1}
                        checked={check1}
                        id={"id1" + id}
                        className={(errors[id] ? "is-invalid state-invalid" : "")}
                        {...register}
                    />
                </Col>
                <Col lg={(colMd2 === undefined ? 6 : colMd2)}  onClick={(e) => {chg(val2)}} style={{marginRight:"auto"}}>
                    <Form.Check name={id} type="radio" value={val2}
                        label={lbl2}
                        id={"id2" + id}
                        checked={check2}
                        className={(errors[id] ? "is-invalid state-invalid" : "")}
                        {...register}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12} style={{marginTop:"-10px"}}>
                    <Box className={(errors[id] ? "is-invalid state-invalid" : "")} style={{display:"none"}}></Box>
                {
                    validationList.map((t,index) => (
                        <React.Fragment key={id + " " + index.toString()}>
                        {
                        (errors[id] && errors[id].type.toString() === t.type && <div  className="invalid-feedback" style={{display:"block !important"}}>{t.message}</div>)
                        }
                        </React.Fragment>
                        )
                    )
                }
                </Col>
            </Row>
        </>
    )
}