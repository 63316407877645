import * as React from "react";
import { useState, useEffect } from 'react';
import { useLocation, useParams } from "react-router";
import DatePicker from "react-datepicker";
import { Breadcrumb, Button,Tab,Nav, Card, Col, Row, Form, InputGroup,Table, Spinner } from "react-bootstrap";
import { useForm,Controller } from "react-hook-form";
import Select , { SingleValue } from "react-select";
import {
    Paper,
    Box,
    Grid,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem
  } from '@material-ui/core';
import Producto from "../../../../../types/productos/Producto";
import { Solicitud } from "../../../../../types/productos/SolicitudProducto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown, faCloudArrowUp, faImage, faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { downloadFile, toastError } from "../../../../../utils/GeneralUtils";
import { useAxios } from "../../../../../hooks/useAxios";
import getDocumentMain, { getSolActiveByProduct } from "../../../../../utils/DocumentoUtils";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { DetalleProductoListDocumentos } from "./inc/DetalleProductoListDocumentos";
import DtoDocumentoV2ArchivoUpload from "../../../../../types/documentos/DocumentoV2ArchivoUpload";
import Documento from "../../../../../types/documentos/Documento";
import { BotonArchSubidos } from "./inc/BotonArchSubidos";
import { UploadFileUtil } from "../../../../../utils/UploadFileUtil";
import { GenericKeyValue } from "../../../../../types/general/GeneralTypes";
import { BotonEnviarSolicitud } from "./inc/BotonEnviarSolicitud";
import { BotonBajaSolicitud } from "./inc/BotonBajaSolicitud";
import { EnumWorkflow, Workflow } from "../../../../../types/general/Workflow";
import { getWorkflowObj } from "../../../../../utils/SolicitudUtils";
import ModalArchivosSubidos from "./inc/ModalArchivosSubidos";
  interface Props {
    producto: Producto;
    setActiveKey : (a:string) => void;
  }

  const getEstado = (prod : Producto) : string => {
    let sol : Solicitud = null;
    prod.solicitudes.forEach((e : Solicitud)   => {
        if (sol === null || e.id> sol.id) {
            sol = e;
        }
    });
    if (sol.workflow.id === EnumWorkflow.PRODUCTO_ACTIVO) {
      return "El producto se encuentra activo"
    }
    return sol.workflow.descripcion;
  }
  
 export const DetalleProducto: React.FC<Props> = ({producto,setActiveKey} : Props) => {
  const axiosInstance = useAxios();
  const [docMain,setDocMain]                              = useState<Documento>(getDocumentMain(producto.documentos));
  const [archivosSubidos,setArchivosSubidos]              = useState<DtoDocumentoV2ArchivoUpload[]>(null);
  const [documentosAdicionales,setDocumentosAdicionales]  = useState<Documento[]>([]);
  const [formValues,setFormValues]                        = useState<GenericKeyValue<string,string>[]>([]);
  const [solicitud,setSolicitud]                          = useState<Solicitud>(null);
  const [productoLocal,setProductoLocal]                  = useState<Producto>(producto);
  const [btnLoading,setBtnLoading]                        = useState<boolean>(false);
  const [modalDocumentacion,setModalDocumentacion]        = useState<boolean>(false);
  const  fetchFile = async (idProd: number,idDoc : number) => {
    try {
        const res : {data: BlobPart} = await axiosInstance.current({
          url: '/apiv2/documentoByCliente/descarga/' + idDoc + '/' + idProd ,
          method: 'GET',
          responseType: 'blob', // important
        })
      /*.then((response: { data: BlobPart; }) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${doc.nombre}.pdf`);
        document.body.appendChild(link);
        link.click();
        
      });*/

    
      //const res : {data: BlobPart} = await axiosInstance.current.get();
      return res.data;
    } catch (error) {
      throw error;
    }
  }
    const handleDownloadFile = () => {
      if (docMain === null) {
        toastError("El producto no tiene documentos main");
        return;
      }
      fetchFile(producto.id,docMain.id).then((data) => {
        downloadFile(data,docMain.nombre + ".pdf");
      }).catch ((e) => {
        toastError("El producto no tiene documentos");
      })
      
      
    }

    /**
     * cuando se sube o elimina un documento dentro de un componente, se debe refrescar archivosSubidos
     */
    const handleRefreshDocumentosSubidos = () => {
      fetchDocumentosSubidos(solicitud.id);
      setBtnLoading(false);
    }

    const handleClickBtnUpDocFirmado = (id?:number) => {
      
      setBtnLoading(true);
    }
    const fetchDocumentosSubidos = async (idSolicitud : number) =>{
      const res : {data: DtoDocumentoV2ArchivoUpload[]} = await axiosInstance.current.get('/apiv2/fetchDocUploadsByUserLogued/' + idSolicitud);
      setArchivosSubidos(res.data);
    }
    useEffect(() => {
      
        setSolicitud(getSolActiveByProduct(productoLocal));
        
        const docTmp : number = (getDocumentMain(producto.documentos) !== null ? getDocumentMain(producto.documentos).id : null);
        setDocumentosAdicionales(producto.documentos.filter( t => (t.id !== docTmp)));
        const docKeyValue : GenericKeyValue<string,string> = {"key":"idDocumento","value":docMain.id.toString()};
        setFormValues(formValues=> [...formValues,docKeyValue]);//formvalues para enviar en los upload files
    },[])
    
    useEffect(() => {
      setProductoLocal(producto);
      
    },[producto]);
    useEffect(() => {
      setSolicitud(getSolActiveByProduct(productoLocal));
    },[productoLocal]);

    useEffect(() => {
      if (solicitud) {
        fetchDocumentosSubidos(solicitud.id);
        const solKeyValue : GenericKeyValue<string,string> = {"key":"idSolicitud","value":solicitud.id.toString()};
        setFormValues(formValues => [...formValues,solKeyValue]);//formvalues para enviar en los upload files
      }
    },[solicitud]);
    return (
        <div className="mt-4">
            {productoLocal.solicitudes.length>0 && getWorkflowObj(productoLocal).id === EnumWorkflow.SIN_ENVIAR && <div><p>Para solicitar el producto debe subir la documentación requerida</p></div>}
                <div>
                  <Row>
                    <Col xl={12}>

                        <Table className="mb-0 border-top table-bordered">
                          <tbody>
                            <tr>
                              <th scope="row">Estado de la solicitud</th>
                              <td>{productoLocal.solicitudes.length>0 && getEstado(productoLocal)}</td>
                              <td style={{display:(solicitud && solicitud.workflow && getWorkflowObj(productoLocal).id === EnumWorkflow.SIN_ENVIAR ? "" : "none")}}>
                                {solicitud && solicitud.workflow && getWorkflowObj(productoLocal).id === EnumWorkflow.SIN_ENVIAR && 
                                  <>
                                    <Col>
                                      <BotonEnviarSolicitud archivosSubidos={archivosSubidos} producto={producto} refreshFn={handleRefreshDocumentosSubidos} solicitud={solicitud}/>
                                    </Col>
                                  </>
                                }
                              </td>
                            </tr>
                            {(getWorkflowObj(productoLocal).id === EnumWorkflow.PRODUCTO_ACTIVO || getWorkflowObj(productoLocal).id > 6) && solicitud && solicitud.productoSolicitudRespuesta &&
                              <>
                                <tr>
                                    <td>CBU</td>
                                    <td>{solicitud.productoSolicitudRespuesta.cbu}</td>
                                </tr>
                                <tr>
                                    <td>Número de Caja de Ahorro</td>
                                    <td>{solicitud.productoSolicitudRespuesta.nroCa}</td>
                                </tr>
                                <tr style={{display: (getWorkflowObj(productoLocal).id === EnumWorkflow.PRODUCTO_ACTIVO ? 'table-row' : 'none')}}>
                                    <td></td>
                                    <td>
                                        <Button onClick={() => {setModalDocumentacion(true)}} >
                                          <FontAwesomeIcon icon={faImage}   /> Ver Documentación
                                        </Button>
                                    </td>
                                </tr>
                                {/*<tr style={{display: (getWorkflowObj(productoLocal).id === EnumWorkflow.PRODUCTO_ACTIVO ? 'table-row' : 'none')}}>
                                    <td></td>
                                    <td><BotonBajaSolicitud producto={producto} refreshFn={handleRefreshDocumentosSubidos} solicitud={solicitud} /></td>
                            </tr>*/}
                              </>
                            }
                            {docMain !== null && getWorkflowObj(productoLocal).id !== EnumWorkflow.PRODUCTO_ACTIVO 
                                              && getWorkflowObj(productoLocal).id <= 6 
                                              && getWorkflowObj(productoLocal).id != 5 
                                              &&
                            <>
                            <tr>
                              <td colSpan={3} style={{textAlign:"center",padding:"20px"}}>{/*<b>Documentación Requerida para solicitar el producto</b>*/}</td>
                            </tr>
                            <tr>
                              <th>Documentación Requerida</th>
                              <th>Documentación Subida</th>
                              <th style={{display:(solicitud && solicitud.workflow && getWorkflowObj(productoLocal).id === EnumWorkflow.SIN_ENVIAR ? "" : "none")}}>Subir documentación requerida</th>
                            </tr>
                            <tr>
                              <th scope="row" style={{verticalAlign:"middle",textAlign:"center"}}>
                                    {
                                      docMain.descripcion
                                    }
                                    <div style={{marginTop:"5px"}}>
                                      <Button variant="primary" className="btn ripple" onClick={handleDownloadFile}>
                                          <FontAwesomeIcon icon={faCloudArrowDown} /> Descargar solicitud
                                      </Button>
                                      <Row>
                                        <small>
                                          Descargue la solicitud en formato pdf con los datos del formulario completos.
                                        </small>
                                      </Row>
                                    </div>
                              </th>
                              <td style={{verticalAlign:"middle",textAlign:"center"}}>
                                    <BotonArchSubidos documento={docMain} archivosSubidos={archivosSubidos} refreshFn={handleRefreshDocumentosSubidos} habilitarDelete={(solicitud && solicitud.workflow && getWorkflowObj(productoLocal).id === EnumWorkflow.SIN_ENVIAR)}/>
                              </td>
                              <td style={{display:(solicitud && solicitud.workflow && getWorkflowObj(productoLocal).id === EnumWorkflow.SIN_ENVIAR ? "" : "none")}}>
                              
                                  <Col>
                                    {btnLoading ? <Spinner animation="border" role="status" className="spinner-border spinner-border-sm"><span className="visually-hidden">Loading...</span></Spinner> : <>
                                                        <Button variant="info" className="btn ripple" onClick={() => {document.getElementById('input_file_1').click();}}>
                                                          <FontAwesomeIcon icon={faCloudArrowUp}   /> Subir Documento firmado
                                                        </Button>
                                                        <Row><small>Enviar al banco el pdf firmado digitalmente.</small></Row>
                                                        </>}
                                  </Col>
                                  {/*<Col>
                                    <Button variant="info" className="btn ripple" onClick={() => {document.getElementById('input_file_1').click();}}>
                                        <FontAwesomeIcon icon={faCloudArrowUp}   /> Subir Fotos del Documento
                                    </Button>
                                    <Row><small>
                                    Puede imprimir la solcitud, firmarla manuscrito y enviar la foto de cada hoja.
                                    </small></Row>
                                  </Col>*/}

                              </td>
                            </tr>
                            <DetalleProductoListDocumentos documentos={documentosAdicionales} archivosSubidos={archivosSubidos} producto={productoLocal} onChange={handleRefreshDocumentosSubidos}/>
                            </>}

                          </tbody>
                        </Table>
                        {getWorkflowObj(productoLocal).id == 5 &&
                          <Button variant="primary btn-rounded" className="mt-2" onClick={() => {setActiveKey('tab2')}}>
                              <FontAwesomeIcon icon={faSquarePlus} /> Solicitar Productos
                          </Button>
                        }
                    </Col>
                </Row>
            </div>
            <ModalArchivosSubidos 
              archivosSubidos={archivosSubidos} 
              solicitud={solicitud} 
              modalVisivilidad={modalDocumentacion} 
              setModalVisivilidad={setModalDocumentacion}
              producto={producto} handleRefreshDocumentosSubidos={handleRefreshDocumentosSubidos}
              />
            <UploadFileUtil formValues={formValues} onInitUpload={handleClickBtnUpDocFirmado} extensionAccepted={['jpg','jpeg','png','pdf']} accept="image/png, image/jpeg, image/jpg,application/pdf" txtToast={"Documento Subido"} idInput="input_file_1" onFinish={handleRefreshDocumentosSubidos} path="/apiv2/solicitud/archivoUpload"/>
            {/*<UploadFileUtil formValues={formValues} extensionAccepted={['pdf']} accept="application/pdf" txtToast={"Documento Subido"} idInput="input_file_2" onFinish={handleRefreshDocumentosSubidos} path="/apiv2/solicitud/archivoUpload"/>*/}
        </div>
    );
 }

