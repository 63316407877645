import * as React from "react";
import { useState, useEffect } from 'react';
import { listCaracterUsuEmpresa } from "../../../../data/forms/GeneralData";
import Asociados from "../../../../types/empresas/Asociados"
import { ResponseContainer, tableData } from "../../../../types/general/GeneralTypes";
import PerfilCliente from "../../../../types/personas/PerfilCliente";
import { fechaScreen, getObjectFromList } from "../../../../utils/GeneralUtils";
import DataTable, { TableColumn } from "react-data-table-component";
import { Button, Col, Modal, Row } from "react-bootstrap";
import PerfilEmpresa from "../../../../types/empresas/PerfilEmpresa";
import DataTableExtensions from "react-data-table-component-extensions";
import { Solicitud } from "../../../../types/productos/SolicitudProducto";
import '../../../../assets/css/custom.css'
import DtoDocumentoV2ArchivoUpload from "../../../../types/documentos/DocumentoV2ArchivoUpload"
import { useAxios } from "../../../../hooks/useAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose, faDownload, faXmark } from "@fortawesome/free-solid-svg-icons";
import { EnumWorkflow } from "../../../../types/general/Workflow";
import { ModalViewSolicitudRechazo } from "./ModalViewSolicitudRechazo";
import { ModalViewSolicitudAprobar } from "./ModalViewSolicitudAprobar";
import { ModalHeaderSolicitud } from "./ModalHeader";
import { ModalViewSolicitudActivar } from "./ModalViewSolicitudActivar";
import { ModalViewSolicitudBajaEnProceso } from "./ModalViewSolicitudBajaEnProceso";
import { ModalViewAprobarBaja } from "./ModalViewAprobarBaja";
interface Props {
    solicitud: Solicitud;
    estado: boolean;
    setEstado: any;
    fetchSolicitudesReload: any;
}

interface BlobAttr extends DtoDocumentoV2ArchivoUpload {
  blobFile?:BlobPart;
}
export const ModalViewSolicitudRespuesta = ({solicitud,estado,setEstado,fetchSolicitudesReload} : Props) => {
  const axiosInstance                 = useAxios();
  const [data,setData]                = useState<DtoDocumentoV2ArchivoUpload[]>([]);
  const [loading,setLoading]          = useState<boolean>(true);
  const [estadoRechazo,setEstadoRechazo]              = useState<boolean>(false);
  const [estadoApobar,setEstadoAprobar]               = useState<boolean>(false);
  const [estadoActivar,setEstadoActivar]              = useState<boolean>(false);
  const [estadoBajaEnProceso,setEstadoBajaEnProceso]  = useState<boolean>(false);
  const [estadoBajaConfirmada,setEstadoBajaConfirmada]= useState<boolean>(false);
  const [archSubDoc,setArchSubDoc]                    = useState<BlobAttr[]>([]);//archivos subidos del documento con el blobfile
  console.log(solicitud)
  const fetchDocumentosBySolicitud = () => {
    setLoading(true);
    (axiosInstance !== undefined && axiosInstance.current !== undefined && (
      axiosInstance.current.get<DtoDocumentoV2ArchivoUpload[]>('/apiv2/fetchDocUploadsByRequestAndClient/' + solicitud.id + '/' + solicitud.perfilCliente.id)
        .then(res => {
          populateBlob(res.data);
          
        })
      .catch((err: { response: any; }) => console.dir(err.response))     ))
  }
/**
     * 
     * @param idFile id de documento_v2_archivo_upload
     * @returns returna el blob del archivo subido
     */
const fetchFile = async (idFile : number) => {
  try {
      //
      const res : {data: BlobPart,headers: {["content-type"]:string}} =  await axiosInstance.current({
        url: '/apiv2/documentoByCliente/descarga/' + idFile ,
        method: 'GET',
        responseType: 'blob', // important
      })
      const ret = {"data":res.data,"contentType":res.headers["content-type"]};
      return ret;
  } catch (error) {
    throw error;
  }
}
  /**
     * dado que el listado de doc subida por documento viene sin el blob
     * se debe iterar cada doc subido e ir a buscar el blob
     */
  const populateBlob = async (data: BlobAttr[]) => {
    setArchSubDoc([]);
    data && data.forEach(t => {
        const a = [];
        fetchFile(t.id).then(d => {
            a.push(d.data);
            const url = URL.createObjectURL(new Blob(a));
            if (d.contentType.split("/")[0] === 'image') {
                const element : JSX.Element = React.createElement("img", {src: url, height: "200px",  width: "300px"}, null);
                t.file = element; //populo el listadoi de archivos subidos de este doc para este cliente con el blob
            } else {
                
                t.file = <a >{t.nombreFile}</a>;
                t.blobFile = d.data; //para los pdf, dejo el blob en el array para despues ser leido por handleClickPdf en caso que el cliente haga click en el archivo
            }
            
            setArchSubDoc(archSubDoc => [...archSubDoc,t]);
        });
        
    });
    setLoading(false);

  };
  const ButtonChangeStatus = ({solicitud : Solicitud}) => {
    return (
      <>
      {solicitud.workflow.id === EnumWorkflow.EN_VERIFICACION && <Button variant="danger" onClick={() => handleModals(EnumWorkflow.RECHAZADO)}><FontAwesomeIcon icon={faXmark}></FontAwesomeIcon> Rechazar</Button>}
      {solicitud.workflow.id === EnumWorkflow.EN_VERIFICACION && <Button onClick={() => handleModals(EnumWorkflow.APROBADO)}><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Aprobar</Button>}
      {solicitud.workflow.id === EnumWorkflow.APROBADO && <Button onClick={() => handleModals(EnumWorkflow.PRODUCTO_ACTIVO)}><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Activar Producto</Button>}
      {solicitud.workflow.id === EnumWorkflow.BAJA_SOLICITADA && <Button variant="danger" onClick={() => handleModals(EnumWorkflow.BAJA_EN_PROCESO)}><FontAwesomeIcon icon={faClose}></FontAwesomeIcon> Iniciar la baja el Producto</Button>}
      {solicitud.workflow.id === EnumWorkflow.BAJA_EN_PROCESO && <Button variant="success" onClick={() => handleModals(EnumWorkflow.BAJA_CONFIRMADA)}><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Aprobar la baja el Producto</Button>}
      </>
    );
  }
    const toogleModal = (t:boolean) => {
        setEstado(t);
    }

  const handleModals = (selector : number) => {
    setEstado(false);
    setEstadoRechazo(false);
    setEstadoAprobar(false);
    setEstadoActivar(false);
    setEstadoBajaEnProceso(false);
    setEstadoBajaConfirmada(false);
    if (selector === EnumWorkflow.RECHAZADO) {
      setEstadoRechazo(true);
    } else if (selector === EnumWorkflow.APROBADO) {
      setEstadoAprobar(true);
    } else if (selector === EnumWorkflow.PRODUCTO_ACTIVO) {
      setEstadoActivar(true);
    } else if (selector === EnumWorkflow.BAJA_EN_PROCESO) {
      setEstadoBajaEnProceso(true);
    } else if (selector === EnumWorkflow.BAJA_CONFIRMADA) {
      setEstadoBajaConfirmada(true);
    }
  }
  useEffect(() => {
    if (solicitud) {
      fetchDocumentosBySolicitud();
    }
    
  },[solicitud]);

  useEffect(() => {
    console.log(solicitud)
    
  },[]);
  /**
     * 
     * @param idFile id de documento_v2_archivo_upload
     * @returns returna el blob del archivo subido
     */
  const handleFetchFile = async (doc : DtoDocumentoV2ArchivoUpload) => {
    try {
        //
        const res : {data: BlobPart,headers: {["content-type"]:string}} =  await axiosInstance.current({
          url: '/apiv2/documentoByCliente/descarga/' + doc.id ,
          method: 'GET',
          responseType: 'blob', // important
        });
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${doc.nombreFile}`);
        document.body.appendChild(link);
        link.click();
    } catch (error) {
      throw error;
    }
  }

  const columns : tableData[] = [
    {
      name: "Documentos enviados para la solicitud",
      selector: row => {
          const doc = row as BlobAttr;
          return doc.file;
        },
      sortable: false
    },
    {
      name: "Ver",
      selector: row => {
          const doc = row as DtoDocumentoV2ArchivoUpload;
          return <>
              <p>{doc.documento.nombre}</p>
              <Button size="sm" onClick={() => handleFetchFile(doc)}><FontAwesomeIcon icon={faDownload}/></Button>
          </>
        },
      sortable: false
    }
  ];
  return (
    <>
      {solicitud && (
        <Modal show={estado} size="lg">
            <Modal.Header
            closeButton
            onClick={() => {
                toogleModal(false);
            }}
            >
            <h6>Solicitud {solicitud && solicitud.producto.nombre}</h6>
            </Modal.Header>
            <Modal.Body>
              {solicitud && 
              <>
                
                <ModalHeaderSolicitud solicitud={solicitud}></ModalHeaderSolicitud>
                <Row className="row-lg">
                  <Col lg={12} md={12}>
                {!loading && archSubDoc.length>0 && <DataTable
                  title
                  columns={columns}
                  data={archSubDoc}
                  pagination
                  paginationServer


                />}
                </Col>
              </Row>
              </>
              }
            </Modal.Body>
            <Modal.Footer>
              <ButtonChangeStatus solicitud={solicitud} ></ButtonChangeStatus>
            <Button
                variant="light"
                onClick={() => {
                    toogleModal(false);
                }}
                className="text-center"
            >
                Cerrar
            </Button>

            </Modal.Footer>
        </Modal>)}
        <ModalViewSolicitudRechazo estado={estadoRechazo} fetchSolicitudesReload={fetchSolicitudesReload} setEstado={setEstadoRechazo} solicitud={solicitud} handleModals={handleModals}/>
        <ModalViewSolicitudAprobar estado={estadoApobar} fetchSolicitudesReload={fetchSolicitudesReload} setEstado={setEstadoAprobar} solicitud={solicitud} handleModals={handleModals}/>
        <ModalViewSolicitudActivar estado={estadoActivar} fetchSolicitudesReload={fetchSolicitudesReload} setEstado={setEstadoActivar} solicitud={solicitud} handleModals={handleModals}/>
        <ModalViewSolicitudBajaEnProceso estado={estadoBajaEnProceso} fetchSolicitudesReload={fetchSolicitudesReload} setEstado={setEstadoBajaEnProceso} solicitud={solicitud} handleModals={handleModals}/>
        <ModalViewAprobarBaja estado={estadoBajaConfirmada} fetchSolicitudesReload={fetchSolicitudesReload} setEstado={setEstadoBajaConfirmada} solicitud={solicitud} handleModals={handleModals}/>
    </>
  )
    //
}