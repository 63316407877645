import * as React from "react";
import { useState, useEffect } from 'react';
import DataTable, { TableColumn } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Button, Col, Form, Row } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";


import Searchable from "react-searchable-dropdown";

import { faPen, faPenSquare, faUsersViewfinder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAxios } from "../../../../hooks/useAxios";
import { ResponseContainer } from "../../../../types/general/GeneralTypes";
import { Solicitud } from "../../../../types/productos/SolicitudProducto";
import { IOptionType, workflowList } from "../../../../data/forms/GeneralData";
import { fechaScreen, getObjectFromList } from "../../../../utils/GeneralUtils";
import { ModalViewSolicitudRespuesta } from "./ModalViewSolicitudRespuesta";
import { useParams } from "react-router";


interface tableData {
  name : string;
  selector?: any;
  cell?:any;
  sortable: boolean;
}
interface Props {
  setLoading: any;
  loading:boolean;
}


export const DataTableSolicitudes = ({loading,setLoading} : Props) => {
  const axiosInstance                 = useAxios();
  const [totalRows, setTotalRows]     = useState(0);
  const [perPage, setPerPage]         = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterWf,setFilterWf]        = useState<IOptionType>(null);
  const [workflowListPlus,setWorkflowListPlus]= useState<IOptionType[]>([]);
  const [viewSolicitud, setViewSolicitud] = useState<boolean>(false);
  const [data, setData]                   = useState<Solicitud[]>(null);
  const [solicitud, setSolicitud]         = useState<Solicitud>(null);
  const { id }                            = useParams();
  const idSol                             = (id as any) as number;
  const handleWorkflowListPlus = () => {
    setWorkflowListPlus(workflowListPlus => [...workflowListPlus, {value:null, label:"Ver Todos"}]);
    
    workflowList
    .filter(t => t.value !== 6)
    .forEach(t => {
    setWorkflowListPlus(workflowListPlus => [...workflowListPlus, t]);
    });
    }
 
  const fetchSolicitudes = (page, size = perPage) => {
    setLoading(true);
    (axiosInstance !== undefined && axiosInstance.current !== undefined && (
      axiosInstance.current.get<ResponseContainer<Solicitud[]>>('/apiv2/solicitudes/?page=' + page + '&size=' + size + "&idWorkflow=" + (filterWf ? filterWf.value : "") + "&idWorkflowNotIn=6")
        .then(res => {
          setLoading(true);
          setData(res.data.data);
          setTotalRows(res.data.recordsTotal);
          setLoading(false);
        })
      .catch((err: { response: any; }) => console.dir(err.response))     ))
  }

  const fetchSolicitud = (id : number) => {
    return new Promise((resolve, reject) => {
      setLoading(true);
    (axiosInstance !== undefined && axiosInstance.current !== undefined && (
      axiosInstance.current.get<Solicitud>('/apiv2/solicitud/' + id)
        .then(res => {
          resolve(res.data);
        })
      .catch((err: { response: any; }) => reject())     ))
  });
    
  }

  const fetchSolicitudesReload = () => {
    fetchSolicitudes(currentPage,perPage);
  }

  useEffect(() => {
    fetchSolicitudes(1,10);
    handleWorkflowListPlus();
    if (idSol>0) {
      fetchSolicitud(idSol).then((t : Solicitud) => {
        setSolicitud(t);
        setViewSolicitud(true)
      }
        );
    }
  }, []);
  useEffect(() => {
    fetchSolicitudes(1,10);
  }, [filterWf]);
  const handlePageChange = page => {
    fetchSolicitudes(page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    
    fetchSolicitudes(page,newPerPage);
    setPerPage(newPerPage);
  };

  const handleClickSolicitud = (obj :Solicitud) => {
    setSolicitud(obj);
    setViewSolicitud(true);
  }
  const columns : tableData[] = [
    {
      name: "Cliente",
      selector: row => {
          const sol = row as Solicitud;
          return sol.perfilCliente.familyName + ' ' + sol.perfilCliente.givenName;
        },
      sortable: true
    },
    {
      name: "Producto Solicitado",
      selector: row => {
          const sol = row as Solicitud;
          return sol.producto.nombre
        },
      sortable: true
    },
    {
      name: "CUIL/CUIT",
      selector: row => {
        const sol = row as Solicitud;
        return sol.perfilCliente.cuilCuit;
      },
      sortable: true
    },
    {
      name: "Fecha de Solicitud",
      selector: row => {
        let a = row as Solicitud;
        const fec = new Date(a.fechaSolicitud);
        return fechaScreen(fec);
      },
      sortable: true
    },
    {
      name: "Fecha respuesta",
      selector: row => {
        let a = row as Solicitud;
        if (a.productoSolicitudRespuesta) {
          const fec = new Date(a.productoSolicitudRespuesta.fechaRespuesta);
          return (a.productoSolicitudRespuesta.fechaRespuesta != null ? fechaScreen(fec) : "N/A");
        }
        return "N/A";
      },
      sortable: true
    },
    {
      name: "Estado",
      selector: row => {
        let a = row as Solicitud;
        return a.workflow.descripcion;
      },
      sortable: true
    },
    {
      name:"",
      selector: row => {
        const obj = row as Solicitud;
        
        return (<Button onClick={() => handleClickSolicitud(obj)}><FontAwesomeIcon icon={faPen} /></Button>);
      },
      sortable:false
    }
  ];

  const tableDatas = {
    columns,
    data,
  };
 
  return (
    <>
    <Row className="row-lg">
        <Col lg={4} md={4}>
          <Form.Group className="form-group select2-sm">
          <p className="mg-b-10">Solicitud en estado:</p>
                <Searchable className="form-control select2"
                value="test"
                placeholder="Seleccionar" // by default "Search"
                notFoundText="No result found" // by default "No result found"
                noInput
                options={workflowListPlus}
                onSelect={(value) => {
                  console.log("pasa")
                  let val : number = value as any
                  setFilterWf(getObjectFromList(workflowListPlus,value));
                }}
                listMaxHeight={140} //by default 140
          />
          </Form.Group>

        </Col>
    </Row>
    <Row className="row-lg">
        <Col lg={12} md={12}>
   
      {!loading && data && <DataTable
        title
        columns={columns}
        data={data}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={currentPage}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />}
      </Col>
    </Row>
    {<ModalViewSolicitudRespuesta solicitud={solicitud} estado={viewSolicitud} setEstado={setViewSolicitud} fetchSolicitudesReload={fetchSolicitudesReload}/>}
    </>
    
  );
}



