import { useEffect, useRef } from 'react';
import axios from 'axios';
import type { AxiosInstance } from 'axios';

import { useKeycloak } from '@react-keycloak/web';
import * as React from 'react';

export const useAxios = () : React.MutableRefObject<AxiosInstance> => {
  const axiosInstance = useRef<AxiosInstance>();
  const { keycloak, initialized } = useKeycloak();
  const kcToken = keycloak?.token ?? '';

  useEffect(() => {
    axiosInstance.current = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
      headers: {
        Authorization: initialized ? `Bearer ${kcToken}` : undefined,
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      },
    });
    axiosInstance.current.interceptors.response.use(response => {
      return response;
    }, error => {
      console.log(error)
      if (error.response) {
        if (error.response.status === 403 ) {
            console.log(error.response)
        }
        if (error.response.status === 401) {
          //alert("El acceso no está permitido o la sesión se venció. Por favor volvé a ingresar.")
        }
        if (error.response.status == 400 || error.response.status == 404) {
          //alert(error.response ? error.response.data.message : "Ocurrió un error. Por favor volvé a intentar.")
        }
      } else {
        //alert("Ocurrió un error inesperado. Por favor, volvé a intentar en unos instantes.")
      }
      return Promise.reject(error);
    });
/**
 * , async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 403 && !originalRequest._retry) {
    originalRequest._retry = true;
    const access_token = await refreshAccessToken();            
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
    return axiosApiInstance(originalRequest);
  }
  return Promise.reject(error);
});
 * 
 */

    return () => {
      axiosInstance.current = undefined;
    };
  }, [initialized, kcToken]);

  return axiosInstance;
};