import * as React from "react";
import { Breadcrumb, Button, Card, Col, Row, Form, InputGroup,Table } from "react-bootstrap";

import {Grid} from '@material-ui/core';
import { formRequired, InputCustom, RadioBooleanCustom } from "../../../../../Commons/InputsCustom";
import { SolicitudProducto3 } from "../../../../../../types/productos/SolicitudProducto3";
import { FieldErrorsImpl } from "react-hook-form";
import PerfilCliente from "../../../../../../types/personas/PerfilCliente";

interface Props {
    errors: FieldErrorsImpl<SolicitudProducto3>;
    register:any;
    chgRadios: any;
    cliente: PerfilCliente;
}

export const DatosTablaSolicitud: React.FC<Props> = ({errors,register,chgRadios,cliente}) => {
    const [todoSi,setTodoSi]            = React.useState<boolean>(false);
    const [todoNo,setTodoNo]            = React.useState<boolean>(false);
    const [todoSiPer,setTodoSiPer]      = React.useState<boolean>(false);
    const [todoNoPer,setTodoNoPer]      = React.useState<boolean>(false);
    
    const handleCheckTodo = (val) => {
        cliente.ivaPasibleRet = val;
        cliente.gananciasPasibleRet = val;
        cliente.sussPasibleRet = val;
        cliente.iibbPasibleRet = val;
        setTodoSi(val === 1)
        setTodoNo(val === 0)
        chgRadios(val,"ivaPasibleRet")
        chgRadios(val,"gananciasPasibleRet")
        chgRadios(val,"sussPasibleRet")
        chgRadios(val,"iibbPasibleRet")
    }

    const handleCheckTodoPer = (val) => {
        cliente.ivaPercepcion       = val;
        cliente.gananciasPercepcion = val;
        cliente.sussPercepcion      = val;
        cliente.iibbPercepcion      = val;
        setTodoSiPer(val === 1)
        setTodoNoPer(val === 0)
        chgRadios(val,"ivaPercepcion")
        chgRadios(val,"gananciasPercepcion")
        chgRadios(val,"sussPercepcion")
        chgRadios(val,"iibbPercepcion")
    }
    
    return (
        <>
            <Table responsive className="card-table table-striped table-vcenter text-nowrap mb-0">
                <thead>
                    <tr>
                        <th className="wd-lg-8p">
                            <span></span>
                        </th>
                        <th className="wd-lg-20p">
                            <span>Situación</span>
                        </th>
                        <th className="wd-lg-20p">
                            <span>Pasible de retención</span>
                        </th>
                        <th className="wd-lg-20p">
                            <span>Agente de percepción</span>
                        </th>
                        <th className="wd-lg-20p">
                            <span>Categoria de retención</span>
                        </th>
                        <th className="wd-lg-20p">% Exento</th>
                    </tr>
                </thead>
                <tbody>

                    <tr >
                        <td>
                                I.V.A
                        </td>
                        <td>
                            <InputCustom 
                                errors={errors} id="ivaSituacion"
                                register={register("ivaSituacion", { required: false})}
                                />
                        </td>
                        <td>
                            <RadioBooleanCustom key="ivaPasibleRet_key" chgRadio={chgRadios} chk1={cliente.ivaPasibleRet === 1} chk2={cliente.ivaPasibleRet === 0}
                            errors={errors}
                            id="ivaPasibleRet"
                            lbl1="Si"
                            lbl2="No"
                            register={register("ivaPasibleRet", { required: true})}
                            val1="1"
                            val2="0"
                            validationList={[{message:"Requerido",type:"required"}] as formRequired[]}
                            colMd1={3}
                            colMd2={3}
                                />
                        </td>
                        <td className="text-center">
                        <RadioBooleanCustom key="ivaPercepcion_2" chgRadio={chgRadios} chk1={cliente.ivaPercepcion === 1} chk2={cliente.ivaPercepcion === 0}
                            errors={errors}
                            id="ivaPercepcion"
                            lbl1="Si"
                            lbl2="No"
                            register={register("ivaPercepcion", { required: true})}
                            val1="1"
                            val2="0"
                            validationList={[{message:"Requerido",type:"required"}] as formRequired[]}
                            colMd1={3}
                            colMd2={3}
                                />
                        </td>
                        <td>
                            <InputCustom 
                                errors={errors} id="ivaCatRetencion" 
                                register={register("ivaCatRetencion", { required: false})} 
                                />
                        </td>
                        <td>
                            <InputCustom 
                                errors={errors} id="ivaExento" 
                                register={register("ivaExento", { required: false})} 
                                />
                        </td>
                        </tr>



                        <tr >
                            <td>
                                GANANCIAS
                            </td>
                            <td>
                                <InputCustom 
                                    errors={errors} id="gananciasSituacion"
                                    register={register("gananciasSituacion", { required: false})}
                                    />
                            </td>
                            <td>
                                <RadioBooleanCustom  chgRadio={chgRadios} chk1={cliente.gananciasPasibleRet === 1} chk2={cliente.gananciasPasibleRet === 0}
                                errors={errors}
                                id="gananciasPasibleRet"
                                lbl1="Si"
                                lbl2="No"
                                register={register("gananciasPasibleRet", { required: true})}
                                val1="1"
                                val2="0"
                                validationList={[{message:"Requerido",type:"required"}] as formRequired[]}
                                colMd1={3}
                                colMd2={3}
                                />
                            </td>
                            <td className="text-center">
                            <RadioBooleanCustom  chgRadio={chgRadios} chk1={cliente.gananciasPercepcion === 1} chk2={cliente.gananciasPercepcion === 0}
                                errors={errors}
                                id="gananciasPercepcion"
                                lbl1="Si"
                                lbl2="No"
                                register={register("gananciasPercepcion", { required: true})}
                                val1="1"
                                val2="0"
                                validationList={[{message:"Requerido",type:"required"}] as formRequired[]}
                                colMd1={3}
                                colMd2={3}
                                />
                            </td>
                            <td>
                                <InputCustom 
                                    errors={errors} id="gananciasCatRetencion" 
                                    register={register("gananciasCatRetencion", { required: false})} 
                                    />
                            </td>
                            <td>
                                <InputCustom 
                                    errors={errors} id="gananciasExento" 
                                    register={register("gananciasExento", { required: false})} 
                                    />
                            </td>
                        </tr>



                        <tr >
                            <td>
                                SUSS
                            </td>
                            <td>
                                <InputCustom 
                                    errors={errors} id="sussSituacion"
                                    register={register("sussSituacion", { required: false})}
                                    />
                            </td>
                            <td>
                                <RadioBooleanCustom  chgRadio={chgRadios} chk1={cliente.sussPasibleRet === 1} chk2={cliente.sussPasibleRet === 0}
                                errors={errors}
                                id="sussPasibleRet"
                                lbl1="Si"
                                lbl2="No"
                                register={register("sussPasibleRet", { required: true})}
                                val1="1"
                                val2="0"
                                validationList={[{message:"Requerido",type:"required"}] as formRequired[]}
                                colMd1={3}
                                colMd2={3}
                                />
                            </td>
                            <td className="text-center">
                            <RadioBooleanCustom  chgRadio={chgRadios} chk1={cliente.sussPercepcion === 1} chk2={cliente.sussPercepcion === 0}
                                errors={errors}
                                id="sussPercepcion"
                                lbl1="Si"
                                lbl2="No"
                                register={register("sussPercepcion", { required: true})}
                                val1="1"
                                val2="0"
                                validationList={[{message:"Requerido",type:"required"}] as formRequired[]}
                                colMd1={3}
                                colMd2={3}
                                />
                            </td>
                            <td>
                                <InputCustom 
                                    errors={errors} id="sussCatRetencion" 
                                    register={register("sussCatRetencion", { required: false})} 
                                    />
                            </td>
                            <td>
                                <InputCustom 
                                    errors={errors} id="sussExento" 
                                    register={register("sussExento", { required: false})} 
                                    />
                            </td>
                        </tr>





                        <tr >
                            <td>
                                I.I.B.B
                            </td>
                            <td>
                                <InputCustom 
                                    errors={errors} id="iibbSituacion"
                                    register={register("iibbSituacion", { required: false})}
                                    />
                            </td>
                            <td>
                                <RadioBooleanCustom  chgRadio={chgRadios} chk1={cliente.iibbPasibleRet === 1} chk2={cliente.iibbPasibleRet === 0}
                                errors={errors}
                                id="iibbPasibleRet"
                                lbl1="Si"
                                lbl2="No"
                                register={register("iibbPasibleRet", { required: true})}
                                val1="1"
                                val2="0"
                                validationList={[{message:"Requerido",type:"required"}] as formRequired[]}
                                colMd1={3}
                                colMd2={3}
                                />
                            </td>
                            <td className="text-center">
                            <RadioBooleanCustom  chgRadio={chgRadios} chk1={cliente.iibbPercepcion === 1} chk2={cliente.iibbPercepcion === 0}
                                errors={errors}
                                id="iibbPercepcion"
                                lbl1="Si"
                                lbl2="No"
                                register={register("iibbPercepcion", { required: true})}
                                val1="1"
                                val2="0"
                                validationList={[{message:"Requerido",type:"required"}] as formRequired[]}
                                colMd1={3}
                                colMd2={3}
                                />
                            </td>
                            <td>
                                <InputCustom 
                                    errors={errors} id="iibbCatRetencion" 
                                    register={register("iibbCatRetencion", { required: false})} 
                                    />
                            </td>
                            <td>
                                <InputCustom 
                                    errors={errors} id="iibbExento" 
                                    register={register("iibbExento", { required: false})} 
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td><b>Checkear todo</b></td>
                            <td></td>
                            <td  className="text-center">
                            <Row >
                                <Col lg={3} onChange={(e) => {handleCheckTodo(1)}} style={{marginLeft:"auto"}}>
                                    <Form.Check name="all_si" type="radio" value="1"
                                        label="SI"
                                        checked={todoSi}
                                        id="id1_todo_si"
                                        onChange={(e) => {handleCheckTodo(1)}} 
                                    />
                                </Col>
                                <Col lg={3} onChange={(e) => {handleCheckTodo(0)}} style={{marginRight:"auto"}}>
                                    <Form.Check name="all_no" type="radio" value="0"
                                        label="NO"
                                        checked={todoNo}
                                        id="id1_todo_no"
                                        onChange={(e) => {handleCheckTodo(0)}} 
                                    />
                                </Col>
                            </Row>
                            </td>
                            <td  className="text-center">
                            <Row >
                                <Col lg={3} onChange={(e) => {handleCheckTodoPer(1)}} style={{marginLeft:"auto"}}>
                                    <Form.Check name="all_si_p" type="radio" value="1"
                                        label="SI"
                                        checked={todoSiPer}
                                        id="id1_todo_si_p"
                                        onChange={(e) => {handleCheckTodoPer(1)}} 
                                    />
                                </Col>
                                <Col lg={3} onChange={(e) => {handleCheckTodoPer(0)}} style={{marginRight:"auto"}}>
                                    <Form.Check name="all_no_p" type="radio" value="0"
                                        label="NO"
                                        checked={todoNoPer}
                                        id="id1_todo_no_p"
                                        onChange={(e) => {handleCheckTodoPer(0)}}
                                    />
                                </Col>
                            </Row>
                            </td>
                            <td>

                            </td>
                            <td>
                                
                                </td>
                        </tr>
                </tbody>

                </Table>
        </>
    )
}