import * as React from "react";
import { useState, useEffect } from 'react';
import { useAxios } from "../../../../../../hooks/useAxios";
import Documento from "../../../../../../types/documentos/Documento";
import DtoDocumentoV2ArchivoUpload from "../../../../../../types/documentos/DocumentoV2ArchivoUpload";
import { Button, Modal,Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines, faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

interface Props {
    archivosSubidos : BlobAttr[];
    documento: Documento;
    refreshFn: any;
    habilitarDelete : boolean;
  }
interface BlobAttr extends DtoDocumentoV2ArchivoUpload {
    blobFile?:BlobPart;
}

  /**
   * este componente muestra por un documento requerido para solicitar un prodcuto, los documentos que haya subido el cliente 
   * @param archivosSubidos son los archivos que subio el cliente para este documento, pero sin el blob
   * @returns 
   */
 export const BotonArchSubidos: React.FC<Props> = ({documento,archivosSubidos,refreshFn,habilitarDelete} : Props) => {
    const axiosInstance                         = useAxios();
    const [idDoc,setIdDoc]                      = useState<number>(documento.id);
    const [archSubDoc,setArchSubDoc]            = useState<BlobAttr[]>([]);//archivos subidos del documento con el blobfile
    const [modalVisivilidad,setModalVisivilidad] = useState<boolean>(false);

    const toogleModal = (t:boolean) => {
        setModalVisivilidad(t);
    }
    useEffect(() => {
        setArchSubDoc([]);
    },[]);

    const handleClickPdf = async (id:number) => {
        let docSubido : BlobAttr = archSubDoc.filter( f => (f.id == id))[0];
        let binary : BlobPart;
        if (docSubido === undefined) {

            //const res : {data: BlobPart} = await
            let a : {data: BlobPart} = await fetchFile(id);
            binary = a.data;
        } else {
            binary = docSubido.blobFile;
        }
        const url = window.URL.createObjectURL(new Blob([binary]));
        const link = document.createElement('a');
        link.href = url;
        if (docSubido != undefined) {
            link.setAttribute('download', `${docSubido.nombreFile}`);
        } else {
            link.setAttribute('download', `documento.pdf`);
        }
        
        document.body.appendChild(link);
        link.click();
    }
    /**
     * dado que el listado de doc subida por documento viene sin el blob
     * se debe iterar cada doc subido e ir a buscar el blob
     */
    const populateBlob = async () => {
        setArchSubDoc([]);
        archivosSubidos && archivosSubidos.forEach(t => {
            if (t.documento.id === idDoc) {
                const a = [];
                fetchFile(t.id).then(d => {
                    a.push(d.data);
                    const url = URL.createObjectURL(new Blob(a));
                    if (d.contentType.split("/")[0] === 'image') {
                        const element : JSX.Element = React.createElement("img", {src: url, height: "200px",  width: "300px"}, null);
                        t.file = element; //populo el listadoi de archivos subidos de este doc para este cliente con el blob
                    } else {
                        
                        t.file = <a style={{cursor:"pointer",textDecoration:"underline"}} onClick={() => {handleClickPdf(t.id)}}>{t.nombreFile}</a>;
                        t.blobFile = d.data; //para los pdf, dejo el blob en el array para despues ser leido por handleClickPdf en caso que el cliente haga click en el archivo
                    }
                    
                    setArchSubDoc(archSubDoc => [...archSubDoc,t]);
                });
                
                
            }
        })
      };
    useEffect(() => {
        populateBlob();
    },[archivosSubidos])

   
    /**
     * 
     * @param idFile id de documento_v2_archivo_upload
     * @returns returna el blob del archivo subido
     */
    const fetchFile = async (idFile : number) => {
        try {
            //
            const res : {data: BlobPart,headers: {["content-type"]:string}} =  await axiosInstance.current({
              url: '/apiv2/documentoByCliente/descarga/' + idFile ,
              method: 'GET',
              responseType: 'blob', // important
            })
            const ret = {"data":res.data,"contentType":res.headers["content-type"]};
            return ret;
        } catch (error) {
          throw error;
        }
      }

      /**
       * elimina un archivo
       * @param id id del upload doc documento_v2_archivo_upload
       */
    const handleDeleteUploadDoc = (id : number) => {
        console.log(id)
        toogleModal(false);
        Swal.fire({
            title: "¿Confirma borrar el documento?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#00144C",
            cancelButtonColor: "gray",
            confirmButtonText: "Sí, eliminarlo",
            cancelButtonText: "Cancelar",
            allowOutsideClick: false,
          }).then((result) => {
            
            if (result.isConfirmed) {
                axiosInstance.current({
                    url: '/apiv2/documentoByCliente/' + id ,
                    method: 'DELETE'
                  }).then (() => {
                    refreshFn();
                    //Swal.fire("Archivo eliminado!", "", "success").then(() => {toogleModal(true);});
                    Swal.fire({
                        title: "Archivo eliminado!",
                        icon: "success",
                        showCancelButton: false,
                        confirmButtonText: "Aceptar",
                        allowOutsideClick: true,
                        confirmButtonColor: "#00144C"
                    }).then(() => {toogleModal(true);});
                  })
              
            } else {
                toogleModal(true);
            }
          });
    }
    return (
    <>
    {archSubDoc.length === 0 && <><i className="fa fa-close" title="Haga click en subir documento" style={{color:"red"}}></i> <small>Falta subir este documento</small></>}
    {archSubDoc.length === 1 &&
        <Button variant="info" className="btn ripple" onClick={() => toogleModal(true)}>
            <FontAwesomeIcon icon={faFileLines}   /> Ver Documento Subido 
        </Button>
    }
    {archSubDoc.length > 1 &&
        <Button variant="info" className="btn ripple" onClick={() => toogleModal(true)}>
            <FontAwesomeIcon icon={faFileLines}   /> Ver {archSubDoc.length} Documentos Subidos
        </Button>
    }
    
    <Modal show={modalVisivilidad} size="lg">
        <Modal.Header
        closeButton
        onClick={() => {
            toogleModal(false);
        }}
        >
        <h6>{documento.descripcion}</h6>
        </Modal.Header>
        <Modal.Body>

        <Table className="mb-0 border-top table-bordered">
            <tbody>
                <tr>
                    <th scope="row">Documentación subida</th>
                    <th scope="row" style={{display:(habilitarDelete ? "" : "none")}}></th>
                </tr>
                {
                    
                    archSubDoc.map( (t,index) => (
                       
                        <tr key={index}>
                            <td>{t.file}</td>
                            <td style={{display:(habilitarDelete ? "" : "none")}}>
                            <Button variant="danger" className="btn ripple" onClick={() => handleDeleteUploadDoc(t.id)} title="Eliminar Documento">
                                <FontAwesomeIcon icon={faTrash}   />
                            </Button>
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </Table>

        </Modal.Body>
        <Modal.Footer>
        <Button
            variant="primary"
            onClick={() => {
                toogleModal(false);
            }}
            className="text-center"
        >
            Cerrar
        </Button>

        </Modal.Footer>
    </Modal>
    </>);
 }