import * as React from "react";
import { useState, useEffect } from 'react';
import { useAxios } from "../../../../../../hooks/useAxios";
import Documento from "../../../../../../types/documentos/Documento";
import DtoDocumentoV2ArchivoUpload from "../../../../../../types/documentos/DocumentoV2ArchivoUpload";
import Producto from "../../../../../../types/productos/Producto";
import { Breadcrumb, Button,Tab,Nav, Card, Col, Row, Form, InputGroup,Table, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { BotonArchSubidos } from "./BotonArchSubidos";
import { GenericKeyValue } from "../../../../../../types/general/GeneralTypes";
import { Solicitud } from "../../../../../../types/productos/SolicitudProducto";
import { getSolActiveByProduct } from "../../../../../../utils/DocumentoUtils";
import { UploadFileUtil } from "../../../../../../utils/UploadFileUtil";
import { getWorkflowObj } from "../../../../../../utils/SolicitudUtils";
import { EnumWorkflow } from "../../../../../../types/general/Workflow";
interface Props {
    producto: Producto;
    archivosSubidos : DtoDocumentoV2ArchivoUpload[];
    documentos: Documento[];
    onChange: any;
    
  }
interface DocumentsWithPostValues extends Documento {
  formValues? : GenericKeyValue<string,string>[];
  loading: boolean;
}
 export const DetalleProductoListDocumentos: React.FC<Props> = ({producto,archivosSubidos,documentos,onChange} : Props) => {
  const [documentsCustom,setDocumentsCustom]      = useState<DocumentsWithPostValues[]>([]);
  const [solicitud,setSolicitud]                  = useState<Solicitud>(null);
  const [btnLoading,setBtnLoading]                        = useState<boolean>(false);
  useEffect( () => {
    setSolicitud(getSolActiveByProduct(producto));
  },[producto]);

  useEffect(() => {
    if(solicitud) {
      const list : DocumentsWithPostValues[] = [];
      let ind = 1;
      documentos.forEach (d => {
  
        const formValues : GenericKeyValue<string,string>[] = [{"key":"idDocumento","value":d.id.toString()},{"key":"idSolicitud","value":solicitud.id.toString()}];
        const data : DocumentsWithPostValues = {...d,loading:false};
        data.formValues     = formValues;
        list.push(data);
      })
      setDocumentsCustom(list);
    }
    
  },[documentos,solicitud]);
  
  const handleLocalOnChange = (data : any,id : number) => {
    onChange(data);
    const list      = documentsCustom.filter(t => t.id === id);
    list[0].loading = false;
  }
  const handleInitUpload = (id : number) => {
    //setLoading({ ...loading, [index]: true });
    // Aquí iría el código para realizar la acción deseada
    //setLoading({ ...loading, [index]: false });
    /*const list      = documentsCustom.filter(t => t.id === id);
    list[0].loading = true;
    console.log(documentsCustom)*/
    let res = [];
    documentsCustom.forEach(t => {
      if (t.id === id) {
        t.loading = true;
      }
      res.push(t);
    })
    console.log(res)
    setDocumentsCustom(res);
  }

    return (
    <>
    
        {documentsCustom.map((d, index) => (
          <tr key={index}>
            <th>{d.nombre}</th>
            <td style={{verticalAlign:"middle",textAlign:"center"}}><BotonArchSubidos documento={d} archivosSubidos={archivosSubidos} refreshFn={onChange} habilitarDelete={solicitud && solicitud.workflow && solicitud.workflow.id === EnumWorkflow.SIN_ENVIAR}/></td>
            <td style={{display:(solicitud && solicitud.workflow && solicitud.workflow.id === EnumWorkflow.SIN_ENVIAR ? "" : "none")}}>
              <Col>
              {d.loading ?  <Spinner animation="border" role="status" className="spinner-border spinner-border-sm"><span className="visually-hidden">Loading...</span></Spinner> : 
                <Button variant="info" className="btn ripple" onClick={() => {document.getElementById('input_file_' + d.id + '_' + index + '_1').click();}}>
                  <FontAwesomeIcon icon={faCloudArrowUp}   /> Subir Documento
                </Button>
              }
              </Col>
              
              {/*<Col>
                <Button variant="info" className="btn ripple" onClick={() => {document.getElementById('input_file_' + d.id + '_' + index + '_1').click();}} style={{marginTop:"8px"}}>
                    <FontAwesomeIcon icon={faCloudArrowUp}   /> Subir foto del Documento
                </Button>
              </Col>*/}
              
              
              <UploadFileUtil formValues={d.formValues} onInitUpload={handleInitUpload} extensionAccepted={['jpg','jpeg','png','pdf']} accept="image/png, image/jpeg, image/jpg, application/pdf" id={d.id} txtToast={"Documento Subido"} idInput={'input_file_' + d.id + '_' + index + '_1'} onFinish={handleLocalOnChange} path="/apiv2/solicitud/archivoUpload"/>
              {/*<UploadFileUtil formValues={d.formValues} extensionAccepted={['pdf']} accept="application/pdf" txtToast={"Documento Subido"} idInput={'input_file_' + d.id + '_' + index + '_2'} onFinish={onChange} path="/apiv2/solicitud/archivoUpload"/>*/}
            </td>
          </tr>
        ))}
    
    </>);
 }