import * as React from "react";
import { useState, useEffect } from 'react';
import { useAxios } from "../../../../../../hooks/useAxios";
import DtoDocumentoV2ArchivoUpload from "../../../../../../types/documentos/DocumentoV2ArchivoUpload";
import { Button, Modal,Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faDownload, faFileLines, faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { Solicitud } from "../../../../../../types/productos/SolicitudProducto";
import { BotonBajaSolicitud } from "./BotonBajaSolicitud";
import Producto from "../../../../../../types/productos/Producto";

interface Props {
    archivosSubidos : BlobAttr[];
    solicitud: Solicitud;
    modalVisivilidad:boolean;
    setModalVisivilidad: (a:boolean) => void;
    producto: Producto;
    handleRefreshDocumentosSubidos: any;
  }
interface BlobAttr extends DtoDocumentoV2ArchivoUpload {
    blobFile?:BlobPart;
}

  /**
   * muestra la documentacion subida de la solicitud
   * @param archivosSubidos son los archivos que subio el cliente para este documento, pero sin el blob
   * @returns 
   */
 const ModalArchivosSubidos: React.FC<Props> = ({archivosSubidos,solicitud,modalVisivilidad,setModalVisivilidad,producto,handleRefreshDocumentosSubidos} : Props) => {
    const axiosInstance                         = useAxios();
    const [archSubDoc,setArchSubDoc]            = useState<BlobAttr[]>([]);//archivos subidos del documento con el blobfile

    const toogleModal = (t:boolean) => {
        setModalVisivilidad(t);
    }
    useEffect(() => {
        setArchSubDoc([]);
    },[]);
    /**
         * 
         * @param idFile id de documento_v2_archivo_upload
         * @returns returna el blob del archivo subido
         */
    const fetchFile = async (idFile : number) => {
        try {
            //
            const res : {data: BlobPart,headers: {["content-type"]:string}} =  await axiosInstance.current({
            url: '/apiv2/documentoByCliente/descarga/' + idFile ,
            method: 'GET',
            responseType: 'blob', // important
            })
            const ret = {"data":res.data,"contentType":res.headers["content-type"]};
            return ret;
        } catch (error) {
        throw error;
        }
    }
    const handleClickPdf = async (id:number) => {
        let docSubido : BlobAttr = archSubDoc.filter( f => (f.id == id))[0];
        let binary : BlobPart;
        if (docSubido === undefined) {

            //const res : {data: BlobPart} = await
            let a : {data: BlobPart} = await fetchFile(id);
            binary = a.data;
        } else {
            binary = docSubido.blobFile;
        }
        const url = window.URL.createObjectURL(new Blob([binary]));
        const link = document.createElement('a');
        link.href = url;
        if (docSubido != undefined) {
            link.setAttribute('download', `${docSubido.nombreFile}`);
        } else {
            link.setAttribute('download', `documento.pdf`);
        }
        
        document.body.appendChild(link);
        link.click();
    }
    /**
     * dado que el listado de doc subida por documento viene sin el blob
     * se debe iterar cada doc subido e ir a buscar el blob
     */
    const populateBlob = async () => {
        setArchSubDoc([]);
        archivosSubidos && archivosSubidos.forEach(t => {
                const a = [];
                fetchFile(t.id).then(d => {
                    a.push(d.data);
                    const url = URL.createObjectURL(new Blob(a));
                    if (d.contentType.split("/")[0] === 'image') {
                        const element : JSX.Element = React.createElement("img", {src: url, height: "200px",  width: "300px"}, null);
                        t.file = element; //populo el listadoi de archivos subidos de este doc para este cliente con el blob
                    } else {
                        
                        t.file = <a style={{cursor:"pointer",textDecoration:"underline"}} onClick={() => {handleClickPdf(t.id)}}>{t.nombreFile}</a>;
                        t.blobFile = d.data; //para los pdf, dejo el blob en el array para despues ser leido por handleClickPdf en caso que el cliente haga click en el archivo
                    }
                    
                    setArchSubDoc(archSubDoc => [...archSubDoc,t]);
                });

        })
      };
    useEffect(() => {
        populateBlob();
    },[archivosSubidos])

   

    /**
         * 
         * @param idFile id de documento_v2_archivo_upload
         * @returns returna el blob del archivo subido
         */
    const handleFetchFile = async (doc : DtoDocumentoV2ArchivoUpload) => {
        try {
            //
            const res : {data: BlobPart,headers: {["content-type"]:string}} =  await axiosInstance.current({
            url: '/apiv2/documentoByCliente/descarga/' + doc.id ,
            method: 'GET',
            responseType: 'blob', // important
            });
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${doc.nombreFile}`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
        throw error;
        }
    }

    return (
    <>
    
    
    <Modal show={modalVisivilidad} size="lg">
        <Modal.Header
        closeButton
        onClick={() => {
            toogleModal(false);
        }}
        >
        <h6>Documentación subida</h6>
        </Modal.Header>
        <Modal.Body>

        <Table className="mb-0 border-top table-bordered">
            <tbody>
                <tr>
                    <th scope="row">Documentación subida</th>
                    <th ></th>
                </tr>
                {
                    
                    archSubDoc.map( (t,index) => (
                       
                        <tr key={index}>
                            <td>{t.file}</td>
                            <td >

                                <p>{t.documento.nombre}</p>
                                <Button size="sm" onClick={() => handleFetchFile(t)}><FontAwesomeIcon icon={faDownload}/></Button>

                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </Table>

        </Modal.Body>
        <Modal.Footer>
        <BotonBajaSolicitud producto={producto} refreshFn={handleRefreshDocumentosSubidos} solicitud={solicitud} setModalVisivilidad={setModalVisivilidad} axiosInstance={axiosInstance}/>
        <Button
            variant="light"
            onClick={() => {
                toogleModal(false);
            }}
            className="text-center"
        >
            <FontAwesomeIcon icon={faClose}/> Cerrar
        </Button>

        </Modal.Footer>
    </Modal>
    </>);
 }
 export default ModalArchivosSubidos;