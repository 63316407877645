import * as Keycloak from 'keycloak-js';
/*
const KEYCLOAK_REALM : string | undefined = process.env.REACT_APP_KEYCLOAK_REALM;
const KEYCLOAK_CLIENT_ID : string | undefined = process.env.REACT_APP_KEYCLOAK_CLIENT_ID?.;
*/

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = Keycloak({
    url: process.env.REACT_APP_KEYCLOAK_AUTH_URL,
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
});


export default keycloak;