import { Solicitud } from "../../../../types/productos/SolicitudProducto";
import * as React from "react";
import { useState, useEffect } from 'react';
import { Button, Col, Modal, ModalHeader, Row } from "react-bootstrap";
import { ModalHeaderSolicitud } from "./ModalHeader";
import { formRequired, InputCustom } from "../../../Commons/InputsCustom";
import { useForm,Controller } from "react-hook-form";
import {
    Paper,
    Box,
    Grid,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem
  } from '@material-ui/core';
import { TextareaCustom } from "../../../Commons/TextareaCustom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useAxios } from "../../../../hooks/useAxios";
import { toastError, toastSuccess } from "../../../../utils/GeneralUtils";
import { GenericKeyValue } from "../../../../types/general/GeneralTypes";
interface Props {
    solicitud: Solicitud;
    estado: boolean;
    setEstado: any;
    fetchSolicitudesReload: any;
    handleModals: any
}
interface Respuesta {
    motivoRechazo: string;
}

export const ModalViewAprobarBaja = ({solicitud,estado,setEstado,fetchSolicitudesReload} : Props) => {
    const axiosInstance                     = useAxios();
    const [nombreFile,setNombreFile]        = useState<string>();
    const [errorFile,setErrorFile]          = useState<string>();
    const [form,setForm]                    = useState<any>();
    const toogleModal = (t:boolean) => {
        setEstado(t);
    }
    const { register,  handleSubmit,reset,control,setValue,getValues,clearErrors,trigger,setError,formState: { errors } } = useForm<Respuesta>({
        defaultValues: {
            motivoRechazo: ''
        }});
    
    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files[0];
        const formData = new FormData();
        const extensionAccepted=['jpg','jpeg','png','pdf'];
        formData.append("file", selectedFile);
        //formData.append(t.key, t.value);

        
          let nombreArchivo = selectedFile.name;
          let arrayNombre =  nombreArchivo.split(".")
          let extencionArchivo = arrayNombre[arrayNombre.length -1].toString().toLocaleUpperCase();
          const accept = extensionAccepted.filter(t => (t.toLocaleUpperCase()===extencionArchivo));
            setNombreFile(selectedFile.name);
            setForm(formData);
            setErrorFile("");
           
        }
    const aprobar = (data : Respuesta) => {
        if (nombreFile && nombreFile.length>3) {
            (axiosInstance !== undefined && axiosInstance.current !== undefined && (
                axiosInstance.current.post('apiv2/solicitud/aprobarBaja/' + solicitud.id,form)
                .then(() => {
                    toastSuccess("Baja Aprobada");
                    setEstado(false);
                    fetchSolicitudesReload();
                    setErrorFile("");
                })
                .catch((err: { response: any; }) => {
    
                })     
            ))
        } else {
            setErrorFile("Debe adjuntar el resumen de cuenta");
        }
        
    }
    const onSubmit = (data : Respuesta) => {

        aprobar(data)
    }
    const onInvalid = (errors) => console.error(errors)
    useEffect(() => {
        setNombreFile('');
        setForm(null);
        if (document.getElementById('file_thumbnail')) {
            (document.getElementById('file_thumbnail') as HTMLInputElement).value = '';
        }
        
        setErrorFile("");
      },[solicitud,estado]);
    return (<>
        {solicitud && (
            <form>
        <Modal show={estado} size="lg">
            
            <Modal.Header
            closeButton
            onClick={() => {
                toogleModal(false);
            }}
            >
            <h6>Aprobar Baja {solicitud && solicitud.producto.nombre}</h6>
            </Modal.Header>
            <Modal.Body>
              {solicitud && 
              <>
                
                <ModalHeaderSolicitud solicitud={solicitud}></ModalHeaderSolicitud>

                <Row>
                    <Grid item xs={12} sm={12} md={12}>
                        <Button className="btn btn-primary" type="button" onClick={() => document.getElementById('file_thumbnail').click()} size="sm">
                            <FontAwesomeIcon icon={faUpload}   /> Subir Resumen de Cuenta
                          </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} className="mt-3">
                        {nombreFile}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} className="mt-3">
                        <span style={{color:"red"}}>{errorFile}</span>
                    </Grid>
                    <input
                  onChange={(e) => handleFileUpload(e)}
                  id="file_thumbnail"
                  type="file"
                  hidden
                />
                </Row>
              </>
              }
            </Modal.Body>
            <Modal.Footer>
            <Button variant="success" type="button" onClick={handleSubmit(onSubmit)}><FontAwesomeIcon icon={faXmark}></FontAwesomeIcon> Aprobar Baja</Button>
            <Button
                variant="light"
                onClick={() => {
                    toogleModal(false);
                }}
                className="text-center"
            >
                Cerrar
            </Button>

            </Modal.Footer>
            
        </Modal>
        </form>)}

    </>);
}