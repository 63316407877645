import Axios, { AxiosInstance } from "axios";
import * as React from "react";
import { useState, useEffect } from 'react';
import { InfoContext } from "../../../contexts/InfoContexts";
import { useAxios } from "../../../hooks/useAxios";
import PerfilCliente from "../../../types/personas/PerfilCliente";
import { useClienteLogueado } from "../../../utils/GeneralUtils";
import { CardItem } from "./CardItem";
import { Button, Col, Container, ListGroup, ListGroupItem, Row } from "react-bootstrap";

import Producto from "../../../types/productos/Producto";
import PerfilEmpresa from "../../../types/empresas/PerfilEmpresa";
import { useNavigate, useOutletContext } from "react-router";
import { useKeycloak } from "@react-keycloak/web";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

  
export const DashboardSaenz = () => {
    const { urlImganes }                        = React.useContext(InfoContext);
    const axiosInstance                         = useAxios();
    const [cliLoading,clienteLogueado]          = useClienteLogueado();
    const [isLoading,setIsLoading]              = useState<boolean>(true);
    const [isClienteExists,setIsClienteExists]  = useState<boolean>(false);
    const [cliente,setCliente]                  = useState<PerfilCliente>(null);
    const [productos,setProductos]              = useState<Producto[]>([]);
    const [empresas,setEmpresas]                = useState<PerfilEmpresa[]>();
    const navigate                              = useNavigate();
    const { keycloak, initialized }             = useKeycloak();
    const [isAdminUser,setisAdminUser]          = useState<boolean>(true);
    const fetchClientData                 = () => {
        const response : PerfilCliente = clienteLogueado as PerfilCliente;
        setIsLoading(false);
        if (response !== null) {
            setCliente(response);
            setIsClienteExists(response.id>0);
        }
      };
    React.useEffect(() => {
        fetchClientData();
            
    },[cliLoading]) 
    React.useEffect(() => {
        if (cliente !== null && cliente.id > 0) {
            fetchProductos(cliente.id);
        }
            
    },[cliente]) 

    const fetchProductos = (id : number) : void => {
        axiosInstance.current.get('/apiv2/productos/byCliente/' + id)
        .then((res: { data: Producto[]; }) => {
            setProductos(res.data)
        })
        .catch((err: { response: any; }) => console.dir(err.response))     
    }
    useEffect(() => {
        fetchEmpresas().then((list: PerfilEmpresa[]) => {
            console.log(list)
            setEmpresas(list)
        });

        if (initialized) {
            const roles = keycloak?.tokenParsed.realm_access.roles;
            if (!roles.includes('BACKOFFICE_SAENZ_ONLINE')) {
                setisAdminUser(false);
            }
        }
    },[])
    const fetchEmpresas = async () => {
        const res : {data : PerfilEmpresa[]} = await axiosInstance.current.get('/api/empresasByCliLogueado');
        return res.data;
    }
    const handleClick = (id :number) => {
        // 👇️ navigate to empresa
        navigate(`${process.env.PUBLIC_URL}/empresa/` + id + "/");
    }
    return (
        <>


              {!isLoading && isClienteExists && (
                <>
                    {cliente !== null && 
                        (
                            <div className="page-header">
                            <div>
                                <h2 className="main-content-title tx-24 mg-b-5">Dashboard de {cliente.familyName + ' ' + cliente.givenName}</h2>
                            </div>
                            </div>
                        )
                    }

                    <Row className="row-sm">

                            <Row>
                                <Grid md={12}>
                                    <Col style={{textAlign:'left',marginBottom:"20px",marginRight:"auto"}}>
                                        <Link to={`/frontend/producto/3/solicitar/`}>
                                            <Button variant="primary btn-rounded">
                                                <FontAwesomeIcon icon={faSquarePlus} /> Solicitar Productos
                                            </Button>
                                        </Link>
                                    </Col>
                                </Grid>
                            </Row>

                            {productos && productos.length>0 && <h4>Productos</h4>}
                            {productos ? productos.map((et,index) => {
                        return (<CardItem key={index} id={index} prod={et} urlImganes={urlImganes}/>)
                    }) : ''}
                    
                    </Row>
                    
                                                  

                </>)}

                {empresas && empresas.length>0 && <h4>Mis empresas</h4>}
                    <Row className="row-sm">
                    <div className="example">
                    <ListGroup>
                        {empresas ? empresas.map((em,index) => {
                                return (<ListGroup.Item key={index} action onClick={() => handleClick(em.id)}>{em.razonSocial}</ListGroup.Item>)
                        }) : ''}

                    </ListGroup>
                    </div>
                            
                            
                    </Row>
              {isLoading && <><h4>Cargando Información</h4></>}
              {!isLoading && !isClienteExists && !isAdminUser && (<>
                    <div className="page-header">
                        <h4><div className="alert alert-warning" role="alert">
                        <span className="alert-inner--icon">
                        <i className="fe fe-info me-2"></i>
                        </span>
                        <span className="alert-inner--text">
                        Debe completar sus datos en <strong>Mi Perfil</strong> para poder solicitar productos
                        </span>
                    </div></h4>
                    </div>
                    </>)}

        </>
    )
}