import * as React from "react";
import { useState, useEffect } from 'react';
import DataTable, { TableColumn } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Button, Spinner } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";
import { fechaScreen , getObjectFromList} from "../../../../../src/utils/GeneralUtils";///utils/GeneralUtils
import { useAxios } from "../../../../../src/hooks/useAxios";
import { ResponseContainer } from "../../../../../src/types/general/GeneralTypes";
import PerfilEmpresa from "../../../../types/empresas/PerfilEmpresa";
import {listTipoSociedad,IOptionType} from "../../../../../src/data/forms/GeneralData";//data/forms/GeneralData
import { ModalViewAsociados } from "./ModalViewAsociados";
import Asociados from "../../../../types/empresas/Asociados";
import PerfilCliente from "../../../../types/personas/PerfilCliente";
import { findPerfilClienteInFecthEmpresas } from "../../../../utils/FindInJsonUtil";
interface tableData {
  name : string;
  selector?: any;
  cell?:any;
  sortable: boolean;
}
interface Props {
}
export const DataTableEmpresa = (props : Props) => {
  const axiosInstance                 = useAxios();
  const [totalRows, setTotalRows]     = useState(0);
  const [perPage, setPerPage]         = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading]             = useState(false);
  const [viewAsociados,setViewAsociados]  = useState(false);
  const [asociados,setAsociados]          = useState<Asociados[]>();

  const [data, setData]                   = useState<PerfilEmpresa[]>();

  const openViewAsociados = (obj : PerfilEmpresa) => {
    console.log(obj)
    setAsociados(obj.asociados);
    obj.asociados.forEach (t => {
      const d = (t.perfilCliente as any);
      if (!isNaN(+d)) {
        //console.log(findPerfilClienteInFecthEmpresas(data,d))
        t.perfilCliente = (findPerfilClienteInFecthEmpresas(data,d) as any);
      }

    })
    setViewAsociados(true);
  }

  const fetchEmpresas = (page, size = perPage) => {
    setLoading(true);
    (axiosInstance !== undefined && axiosInstance.current !== undefined && (
      axiosInstance.current.get<ResponseContainer<PerfilEmpresa[]>>('/api/fetchEmpresas?page=' + page + '&size=' + size)
        .then(res => {
          setLoading(true);
          setData(res.data.data);
          setTotalRows(res.data.recordsTotal);
          setLoading(false);
        })
      .catch((err: { response: any; }) => console.dir(err.response))     ))
  }

  useEffect(() => {
    fetchEmpresas(1,10);
  }, []);

  const handlePageChange = page => {
    fetchEmpresas(page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    
    fetchEmpresas(page,newPerPage);
    setPerPage(newPerPage);
  };
  const columns : tableData[] = [
    {
      name: "Razon Social",
      selector: row => [row.razonSocial],
      sortable: true
    },
    {
      name: "Tipo Soc.",
      cell: row => {
          const soc = getObjectFromList(listTipoSociedad,row.tipoSociedad)
          const ret = (soc !== null ? soc.label : "Error");
          return <>{
              ret
            }</>
        },//
      sortable: true
    },
    {
      name: "CUIT",
      selector: row => [row.cuit],
      sortable: true
    },
    {
      name: "FEC. INSC.",
      selector: row => {
        let a = row as PerfilEmpresa;
        const fec = new Date(a.fechaInscripcion)
        return fechaScreen(fec);
      },
      sortable: true
    },
    {
      name: "FEC. ALTA",
      selector: row => {
        let a = row as PerfilEmpresa;
        const fec = new Date(a.fechaAlta)
        return fechaScreen(fec);
      },
      sortable: true
    },
    {
      name:"",
      cell: row =>
        row.asociados.length>0 && isNaN(row.asociados[0].perfilCliente) ? (
          <>
            <Button variant="primary" size="sm"
              onClick={() => openViewAsociados(row)}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-building"></i> Ver Asociados
            </Button>
          </>
        ) : <></>,
      sortable:false
    }
  ];

  const tableDatas = {
    columns,
    data,
  };
  return (
    <>

      {!loading && <>
        <DataTableExtensions {...tableDatas} >
          <DataTable
            title
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </DataTableExtensions></>}
      {
          loading && 
          <Spinner animation="border" role="status">
              <span className="visually-hidden">Cargando...</span>
          </Spinner>
      }
    <ModalViewAsociados asociados={asociados} estado={viewAsociados} setEstado={setViewAsociados}/>
    </>
    
  );
}



