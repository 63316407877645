import * as React from "react";
import { Breadcrumb, Button, Card, Col, Row, Form, InputGroup, Container } from "react-bootstrap";
import {
    Paper,
    Box,
    Grid,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem
  } from '@material-ui/core';
import Producto from "../../../types/productos/Producto";
import { getWorkflowText4Web } from "../../../utils/GeneralUtils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faFileLines,  } from '@fortawesome/free-regular-svg-icons'
import { faFilePdf, faSquarePlus } from '@fortawesome/free-solid-svg-icons'
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import { EnumWorkflow } from "../../../types/general/Workflow";

  interface iCardItem {
    prod: Producto;
    urlImganes : string;
    id:number;
  }

export const CardItem = ({prod,urlImganes,id} : iCardItem) => {
    console.log(prod)
        return (prod !== null ? (
            <Col md={2} >
                <Card className="overflow-hidden custom-card ">
                <img alt="media1" className="img-fluid b-img" src={urlImganes + prod.urlBackend} />{/*style={{maxWidth:"150px"}}*/}
                    <Card.Body style={{padding:"0px 0px 10px 0px"}}>
                        <Container>
                            <Row>
                            <Grid item xs={12} sm={12} md={12}>
                                <Col style={{display:'flex', justifyContent:'center'}}>
                                        <h6>{prod.nombre}</h6>
                                </Col>
                            </Grid>
                            </Row>
                            <Row>
                            <Grid item xs={12} sm={12} md={12}>
                                <Col style={{display:'flex', justifyContent:'center'}}>
                                {/*prod.solicitudes.length > 0 && <>{getWorkflowText4Web(prod.solicitudes[0].workflow)}</>*/}
                                </Col>
                            </Grid>
                            </Row>
                            <Row>
                            <Grid item xs={12} sm={12} md={12}>
                                <Col style={{display:'flex', justifyContent:'center'}}>
                                {prod && prod.solicitudes && prod.solicitudes.length>0 && prod.solicitudes.filter(t => (t.workflow.id !== EnumWorkflow.SIN_ENVIAR && t.workflow.id !== EnumWorkflow.BAJA_CONFIRMADA)).length === 0 && <>
                                    <Link to={`/frontend/producto/${prod.id}/solicitar/`}>
                                        <Button variant="primary" size="sm">
                                            {/*<i className="fas fa-check-square me-1"></i>*/}<FontAwesomeIcon icon={faSquarePlus} /> Solicitar
                                            
                                        </Button>
                                    </Link>
                                    </>
                                }
                                {prod && prod.solicitudes && prod.solicitudes.length>0 && prod.solicitudes.filter(t => (t.workflow.id !== EnumWorkflow.SIN_ENVIAR && t.workflow.id !== EnumWorkflow.BAJA_CONFIRMADA)).length > 0 && <>
                                    <Link to={`/frontend/producto/${prod.id}/solicitar/`}>
                                        <Button variant="primary" size="sm">
                                            <FontAwesomeIcon icon={faFileLines} /> Ver producto
                                        </Button>
                                    </Link>
                                    &nbsp;
                                    
                                    </>
                                }
                                </Col>
                            </Grid>
                            </Row>
                        </Container>   

                        <Card.Text>
                            
                        
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            ) : <></>);
            
      }