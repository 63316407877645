import * as React from "react";
import { useState, useEffect } from 'react';
import { useLocation } from "react-router";
import { useAxios } from "../../../hooks/useAxios";
import { Breadcrumb, Button, Card, Col, Row, Form, InputGroup, Spinner } from "react-bootstrap";
import Select , { SingleValue } from "react-select";
import { useParams,useOutletContext ,useNavigate } from "react-router-dom";
import {
  Paper,
  Box,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem
} from '@material-ui/core';

import Producto from "../../../types/productos/Producto";
import {getTipoPersona} from "../../../types/productos/Producto";
import { DataTableDocPorProd } from "./components/DataTableDocPorProd";

interface Props {}

const DocumentosPorProducto: React.FC<Props> = (props) => {
    const axiosInstance             = useAxios();
    const [loading,setLoading]      = useState(true);
    const { id }                    = useParams();
    const [producto,setProducto]    = useState<Producto>();
    
    const fetchProducto = (idProd) => {
        setLoading(true);
        (axiosInstance !== undefined && axiosInstance.current !== undefined && (
          axiosInstance.current.get<Producto>('/apiv2/producto/' + idProd)
            .then(res => {
              setLoading(true);
              setProducto(res.data);
              setLoading(false);
            })
          .catch((err: { response: any; }) => console.dir(err.response))     ))
      }
    useEffect(() => {
        fetchProducto(id);
      }    
      , [id]);
    
    return <>
        {!loading && <>
            {/* <!-- Page Header --> */}
            <div className="page-header">
                <div>
                <h2 className="main-content-title tx-24 mg-b-5">Documentos del producto "{producto.nombre}" para {getTipoPersona(producto.tipoPersonaId)}</h2>

                </div>

            </div>
        {/* <!-- End Page Header --> */}
            <Row className=" sidemenu-height">
                <Col lg={12}>
                    <Card className="custom-card">
                        <Card.Body>
                            <DataTableDocPorProd documentos={producto.documentos} reloadFn={fetchProducto} id={id}></DataTableDocPorProd>
                        </Card.Body>
                    </Card>
                </Col>
            </Row></>
        }
        {loading && 
            <>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Cargando...</span>
            </Spinner>
            </>
        }
    </>;
}

export default DocumentosPorProducto;