import Documento from "../types/documentos/Documento";
import { EnumTipoDocumento } from "../types/general/GeneralTypes";
import { EnumWorkflow } from "../types/general/Workflow";
import Producto from "../types/productos/Producto";
import { Solicitud } from "../types/productos/SolicitudProducto";

/**
 * la mayoria de los prductos tienen un doc main, es el documento el cual se reemplazan los campos input por los campos que completo el usuario en la web
 * @param list se debe pasar los documentos de un producto, y se devolvera cual de esos doc representa al principal (el pdf que corresponde al formulario web que el usuario completa los datos)
 * @returns de un listado de doc, retorna el primero q sea de tipo main (1)
 */
const getDocumentMain = (list : Documento[]) : Documento => {
    let doc : Documento = null;
    if (list !== null) {
        list.forEach(element => {
            if (element.tipoDoc === EnumTipoDocumento.PDF_DOC_MAIN) {
                doc = element;
            }
        });
    }
    return doc;
}

export const getSolActiveByProduct = (producto : Producto) : Solicitud => {
    let sol : Solicitud = null;
    if (producto.solicitudes && producto.solicitudes.length>0) {
        //el backend ordena las solcitudes por id DESC, si la soliciutd de index 0 (ultima) no esta rechazada, entonces esta es la solicitud donde se debe general el formulario
        //TODO: ver que pasa si desea enviar mas de una vez la misma solicitud
        sol = producto.solicitudes[0].workflow.id !== EnumWorkflow.RECHAZADO ? producto.solicitudes[0] : null;
        if (sol) {
            const sol2 : any = sol as any;
            sol.aLaOrdenDe = sol2.alaOrdenDe;
        }
    }
    return sol;
}

export default getDocumentMain;