import Axios from 'axios'
import * as React from 'react'
import { useAxios } from '../hooks/useAxios';


interface Context {

    urlImganes:string;
}



interface Props { }

export const InfoContext = React.createContext<Context>(null)

const InfoProvider: React.FC<Props> = props => {
   const { children } = props
    const [width, setWidth] = React.useState(window.innerWidth);  
    const [pantallaChica, setPantallaChica] = React.useState(false);
    const axiosInstance = useAxios()   
    const [urlImganes, setUrlImagenes] = React.useState<string>()
   

    React.useEffect(() => {
      axiosInstance.current.get('/api/urlbackend')
          .then((res: { data: any; }) => {              
            setUrlImagenes(res.data)         
          })
          .catch((err: { response: any; }) => console.dir(err.response))     
          
    },[]) 

  
  return (
    <InfoContext.Provider value={{ urlImganes }}>
      {children}
    </InfoContext.Provider>
  )
}

export default InfoProvider;