import * as React from "react";
import { useState, useEffect } from 'react';
import { useLocation } from "react-router";
import DatePicker from "react-datepicker";
import { useAxios } from "../../../hooks/useAxios";
import { Breadcrumb, Button, Card, Col, Row, Form, InputGroup, Spinner } from "react-bootstrap";
import Select , { SingleValue } from "react-select";
import {generoList,tipoDoc,getGeneroName,estadoCivilList,OptionType} from "../../../data/forms/GeneralData";
import {  DataTableEmpresa } from "./components/DataTableEmpresa";
import {ResponseContainer} from "../../../types/general/GeneralTypes"
import {
  Paper,
  Box,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import PerfilCliente from "../../../types/personas/PerfilCliente";
import { any } from "prop-types";
interface Props {}

const EmpresasRegistradas: React.FC<Props> = (props) => {
    const axiosInstance             = useAxios();
    const [loading,setLoading]      = useState(true);
    
    useEffect(() => {
       
      }    
      , []);
    return <>
        {/* <!-- Page Header --> */}
        <div className="page-header">
            <div>
            <h2 className="main-content-title tx-24 mg-b-5">Empresas Registradas</h2>
            {/*<Breadcrumb>
                <Breadcrumb.Item href="#"> Pages </Breadcrumb.Item>
                <Breadcrumb.Item active>Empty Page</Breadcrumb.Item>
            </Breadcrumb>*/}
            </div>

        </div>
      {/* <!-- End Page Header --> */}

    {/* <!-- Row --> */}
        <Row className=" sidemenu-height">
            <Col lg={12}>
                <Card className="custom-card">
                    <Card.Body>
                        <DataTableEmpresa></DataTableEmpresa>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>;
}

export default EmpresasRegistradas;