import { Workflow } from "../types/general/Workflow";
import Producto from "../types/productos/Producto";
import { Solicitud } from "../types/productos/SolicitudProducto";

export const getWorkflowObj = (prod : Producto) : Workflow => {
    let sol : Solicitud = null;
    prod.solicitudes.forEach((e : Solicitud)   => {
        if (sol === null || e.id> sol.id) {
            sol = e;
        }
    });
    return sol.workflow;
  }