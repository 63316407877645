import * as React from "react";
import { SingleValue } from "react-select";
import PerfilCliente from "../types/personas/PerfilCliente";
import { toast, ToastContainer } from "react-toastify";
import {OptionType,IOptionType,listProvincias,listTipoSociedad} from "../data/forms/GeneralData";
import { Breadcrumb, Button, Col, Row, Card, Spinner } from "react-bootstrap";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import { useAxios } from "../hooks/useAxios";
import { data } from "autoprefixer";
import PerfilEmpresa from "../types/empresas/PerfilEmpresa";
import { Workflow } from "../types/general/Workflow";
import { GenericKeyValue } from "../types/general/GeneralTypes";
import { AxiosInstance } from "axios";

interface ChangeProps {
    event: OptionType;
    setEstadoCivil?: (input: any) => void;
    cliente : PerfilCliente;
    setValue : (input: any, input2: any) => void;
    setCliente?: (data : PerfilCliente) => void;
  }
  
export const handleChangeEstadoCivil = ({event, setEstadoCivil, cliente,setValue,setCliente}: ChangeProps) => {
    if (event !== null ) {
        console.log(event)
        setEstadoCivil({"value":event.value,"label":event.value});
        cliente.estadoCivil = event.value as PerfilCliente["estadoCivil"];
        setValue("estadoCivil",event.value as PerfilCliente["estadoCivil"])
        if (setCliente !== undefined) {
          setCliente(cliente);
        }
        
    }
    
    //setValue('tipoDni', (event.target.value as PerfilCliente["tipoDni"]), { shouldValidate: true, shouldDirty: true });
  };
  export const handleChangeTipoCuilCuit = ({event, setTipoCuilCuit, cliente,setValue,trigger}) => {
    if (event !== null ) {
        console.log(event)
        setTipoCuilCuit({"value":event.value,"label":(event.value === "" ? "Seleccionar" : event.value)});//setCuilCuit
 
        
        cliente.tipoCuilCuit = event.value as PerfilCliente["tipoCuilCuit"];
        setValue("tipoCuilCuit",event.value as PerfilCliente["tipoCuilCuit"])

        trigger("tipoCuilCuit");
        console.log(cliente)
    }
    
    //setValue('tipoDni', (event.target.value as PerfilCliente["tipoDni"]), { shouldValidate: true, shouldDirty: true });
  };
  export const toastError = (text) => {
    toast.error(
      <p className="mx-2 tx-16 d-flex align-items-center mb-0">
        {text}
      </p>,
      {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: false,
        theme: "colored",
      }
    );
  }
  
  export const toastSuccess = (text) => {
    toast.success(
        <p className="mx-2 tx-16 d-flex align-items-center mb-0 ">
          {text}
        </p>,
        {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: false,
          theme: "colored",
        }
      );
  }
export const getObjectFromList = (list : IOptionType[],id : number) : IOptionType | null => {
  if (id === null || id === undefined || id === 0) {
    return {"value":0,"label":"Seleccionar"};
  }
  var a : IOptionType[] = list.filter((item: IOptionType) => item.value === id);
  return (a.length === 1 ? a[0] : null);
}
export const customStyles = {
    control: base => ({
      ...base,
      height: 30,
      minHeight: 30
    })
  };


export function useClienteLogueado() : [boolean,PerfilCliente] {
    const [cliente, setCliente] = React.useState<PerfilCliente>(null);
    const [loading,setLoading] =  React.useState<boolean>(false);
    const axiosInstance = useAxios();
    React.useEffect(() => {
      async function fetchCliente() {
        try {

          const res : {data: PerfilCliente} = await axiosInstance.current.get('/api/clienteLogueado');
   
  console.log("pasa useClienteLogueado")
  console.log(res.data)
          setLoading(true);
          if (res.data.fechaNac !== null) {
            res.data.fechaNac = new Date(res.data.fechaNac);
          }
          if (res.data.tipoCuilCuit === null) {
            res.data.tipoCuilCuit = "";
          }
          setCliente(res.data);
        } catch (error) {
          setCliente(null);
        }
      }
  
      if (cliente === null) {
        fetchCliente();
      }
    }, []);
  
    return [loading,cliente];
  }
  

  export function useEmpresaByIdv2() {
    
    const axiosInstance = useAxios();

    async function fetchEmpresa(id : number) {
      try {

        const res : {data: PerfilEmpresa} = await axiosInstance.current.get('/api/empresa/' + id);
 
        return res.data;
      } catch (error) {
        console.log(error)
      }
    }
    return fetchEmpresa;
  }
/**
 * 
 * @param fecha YYYY-MM-DD
 * @returns DD-MM-YYYY
 */
export const fechaScreenFromString = (fecha : string) : string => {
  const arr = fecha.split("-");
  return arr[2]+"/"+arr[1]+"/"+arr[0];
}
/**
 * 
 * @param yyyymmdd 
 * @returns dd/mm/yyyy
 */
export const convertirFormatoFecha =(yyyymmdd: string): string => {
  const year = yyyymmdd.substr(0, 4);
  const month = yyyymmdd.substr(4, 2);
  const day = yyyymmdd.substr(6, 2);

  return `${day}/${month}/${year}`;
}
export const fechaScreen = (date : Date) => {
  if (date) {
    return addCero(date.getDate()) + "/" + addCero(date.getMonth()+1) + "/" + date.getFullYear();
  }
  return "";
}
export function fechaHoraScreen(fechaOriginal) {
  const fecha = new Date(fechaOriginal);
  
  const dia = String(fecha.getDate()).padStart(2, '0');
  const mes = String(fecha.getMonth() + 1).padStart(2, '0');
  const año = fecha.getFullYear();
  const horas = String(fecha.getHours()).padStart(2, '0');
  const minutos = String(fecha.getMinutes()).padStart(2, '0');

  const fechaFormateada = `${dia}/${mes}/${año} ${horas}:${minutos}`;

  return fechaFormateada;
}
export const addCero = (str : string | number) => {
  str = (str.toString())
  if (str.length==1) {
    return "0" + str;
  } else if (str.length==0) {
    return "00";
  }
  return str;
}

export const getWorkflowText4Web = (workflow : Workflow) : string => {
  switch (workflow.id) {
    case 1 :
      return "Solicitado";
    case 2 :
      return "Documentación en revisión";
    case 3 :
      return "Documentación aprobada";
    case 4 :
      return "Adquirido";
    case 5 :
      return "Rechazado";
  }
  return "";
}
interface SpinnerProp {
  txtLoading?: string;
}
export const SpinnerUtil: React.FC<SpinnerProp> = (props) => {
  const txt = (props.txtLoading !== undefined ? props.txtLoading : "Cargando...")
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">{txt}</span>
      </Spinner>
    );
}

export const validateCuit  = (cuit1,cuit2,cuit3) : boolean => {
  
  const len : string = ((cuit1.length !== 0 ? "1" : "") + (cuit2.length !== 0 ? "1" : "") + (cuit3.length !== 0 ? "1" : "") );

  if ((len.length !== 3) || (cuit1.length !== 0 && cuit1.length !== 2) || ((cuit2.length !== 0 && (cuit2.length <7 || cuit2.length >8))) || (cuit3.length !== 0 && cuit3.length > 2)) {
    return false;
  }
  return true;
}

export const getObjectType = (obj : any) : String => {
  const ret = (obj instanceof Array ? "Array" :
            (obj instanceof Date ? "Date" :
                (
                  obj instanceof Function ? "Function" : 
                        (
                            typeof obj
                        )
                )
            ));
  return ret;
}

export const downloadFile = (data: BlobPart,name:string) : void => {

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
}

export const ProductContext = React.createContext({reloadProduct: () => {}});

export const fechaNacValidate = (fecha: Date) => {
  const hoy = new Date(); // Obtener la fecha actual
  const edadMinima = 18; // Edad mínima permitida
  
  // Restar 18 años a la fecha actual
  const fechaMinima = new Date(hoy.getFullYear() - edadMinima, hoy.getMonth(), hoy.getDate());
  
  // Comparar la fecha de nacimiento con la fecha mínima
  if (fecha < fechaMinima) {
    // La fecha de nacimiento es mayor a 18 años
    return true;
  } else {
    // La fecha de nacimiento es menor o igual a 18 años
    return "Debe ser mayor de edad";
  }
};

export const cuilCuitValidate = (cuit :string) => {
  if (!cuit) { return "Campo requerido."; }

   // Eliminar los guiones del CUIT
const cuitSinGuiones = cuit.replace(/-/g, '');

// Verificar que el CUIT tenga 11 dígitos
if (cuitSinGuiones.length !== 11) {
  return "Debe tener 11 dígitos.";
}

// Verificar que todos los caracteres del CUIT sean dígitos
if (!/^\d+$/.test(cuitSinGuiones)) {
  return "Solo se admiten números";
}

// Obtener el digito verificador
const digitoVerificador = parseInt(cuitSinGuiones[10]);

// Calcular el digito verificador esperado
let suma = 0;
const coeficiente = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];

for (let i = 0; i < 10; i++) {
  suma += parseInt(cuitSinGuiones[i]) * coeficiente[i];
}

const resto = suma % 11;
const digitoVerificadorEsperado = resto === 0 ? 0 : resto === 1 ? 9 : 11 - resto;

// Comparar el digito verificador
return (digitoVerificador === digitoVerificadorEsperado ? true : "No valida dígito verificador");
  // return iText[0].toUpperCase() === iText[0];
}