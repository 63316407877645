import * as React from "react";
import { useState, useEffect } from 'react';
import { useAxios } from "../../../../../../hooks/useAxios";
import Documento from "../../../../../../types/documentos/Documento";
import DtoDocumentoV2ArchivoUpload from "../../../../../../types/documentos/DocumentoV2ArchivoUpload";
import Producto from "../../../../../../types/productos/Producto";
import { Button, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { ProductContext, toastError, toastSuccess } from "../../../../../../utils/GeneralUtils";
import { Solicitud } from "../../../../../../types/productos/SolicitudProducto";
interface Props {
    archivosSubidos : DtoDocumentoV2ArchivoUpload[];
    producto: Producto;
    refreshFn: any;
    solicitud: Solicitud;
  }


  /**
   * este componente muestra para un documento requerido el btn de solicitud del producto
   * @param archivosSubidos son los archivos que subio el cliente para este documento, pero sin el blob
   * @returns 
   */
 export const BotonEnviarSolicitud: React.FC<Props> = ({producto,archivosSubidos,solicitud,refreshFn} : Props) => {
    const { reloadProduct }         = React.useContext(ProductContext);//context definido en FormProductChoice
    const axiosInstance             = useAxios();
    const [loading,setLoading]      = useState<boolean>(false);
    const handleValidation = () => {
        let ret : boolean = true;
        if (producto && producto.documentos) {//recorro los documentos que solicita el producto
            producto.documentos.forEach(t => {
                const docsSubidosByDoc : DtoDocumentoV2ArchivoUpload[] = archivosSubidos.filter( a => (a.documento.id == t.id));//filtro los documentos subidos por documento del producto
                if (docsSubidosByDoc.length === 0) {//si el documento del producto no tiene documentos subidos, no puede solicitae el producto
                    ret = false;
                }
            })
        } else {
            return false;
        }
        return ret;
    }
    const test = (d: any) => {
        toastError(d)
    }
    const handleClickSendRequest = () => {
        

        if (!handleValidation()) {
            toastError("Falta subir documentación");
        } else {
            setLoading(true);
            (axiosInstance !== undefined && axiosInstance.current !== undefined && (
                axiosInstance.current.put('/apiv2/solicitud/Chg2Verificacion/' + solicitud.id)
                  .then(res => {
                    toastSuccess("Producto solicitado");
                    reloadProduct();
                    setLoading(false);
                  })
                .catch((err: { response: {data : {message : string}}; }) => {
                    setLoading(false);
                    test(err.response.data.message);
                    }
                )
            ))
        }
        //
        
    }
    return (<>
        {
            loading && 
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Cargando...</span>
            </Spinner>
        }
        { !loading && <Button variant="primary" className="btn ripple" onClick={handleClickSendRequest}>
            <FontAwesomeIcon icon={faPenToSquare}   /> Enviar Solicitud
            </Button>
        }
    </>)
 }