import * as React from "react";
import { useState, useEffect, createContext } from 'react';
import { useLocation, useParams } from "react-router";
import DatePicker from "react-datepicker";
import { Breadcrumb, Button, Card, Col, Row, Form, InputGroup } from "react-bootstrap";
import { useForm,Controller } from "react-hook-form";
import Select , { SingleValue } from "react-select";
import {
    Paper,
    Box,
    Grid,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem
  } from '@material-ui/core';
import SolicitudProductoId3 from "./Solicitud/SolicitudProductoId3";
import { ProductContext, SpinnerUtil, useClienteLogueado } from "../../../utils/GeneralUtils";
import Producto from "../../../types/productos/Producto";
import { useAxios } from "../../../hooks/useAxios";

interface Props {
}


const FormProductChoice: React.FC<Props> = (props) => {
    let { id  }                                 = useParams();
    const [cliLoading,clienteLogueado]          = useClienteLogueado();
    const [loading,setLoading]                  = useState<boolean>(true);
    const [producto,setProducto]                = useState<Producto>();
    const refProd                = React.useRef<Producto>();
    const axiosInstance                         = useAxios();
    
    const fetchProducto = async (idProd : number,idCli : number) =>{
        const res : {data: Producto} = await axiosInstance.current.get('apiv2/productoById/' + idProd + '/byClient/' + (idCli === null ? 0 : idCli));
        setProducto(res.data);
        refProd.current = res.data;
        setLoading(false);
    }

    useEffect(() => {
        if (cliLoading && clienteLogueado) {
            if (!isNaN(+id)) {
                fetchProducto(Number(id),clienteLogueado.id);
            }
            
        }
    },[cliLoading]);

    
    const value = {reloadProduct: () => {
        fetchProducto(Number(id),clienteLogueado.id);
    }}
    const fetchProductRef = async () => {
        await fetchProducto(Number(id),clienteLogueado.id);
    }
    return (
        <>
            {loading && <SpinnerUtil/>}
            {!loading && !isNaN(+id) &&
                (
                    <div className="page-header">
                        <div>
                            <h2 className="main-content-title tx-24 mg-b-5">{producto.nombre}</h2>
                        </div>
                    </div>)
            }
            {!loading && Number(id) === 3 &&  <ProductContext.Provider value={value}><SolicitudProductoId3 cliente={clienteLogueado} producto={refProd.current} fetchProductRef={fetchProductRef}/></ProductContext.Provider>}
        </>
    );
}

export default FormProductChoice;