import { Controller } from "react-hook-form";
import MaskInput from "react-maskinput/lib";
import classNames from 'classnames';

export default function MaskInputCustom({name,control, register}) {
    return <Controller
    name={name}
    control={control}
    rules={register}
    render={({ field, fieldState }) => (
      <MaskInput
        className={classNames('form-control form-control-sm', { 'is-invalid': fieldState.error })}
        alwaysShowMask
        mask="00-00000000-0"
        size={40}
        showMask
        maskChar="_"
        value={field.value ?? ''}
        onChange={(e) => field.onChange(e.target.value)}
      />
    )}
  />
}