import React, { Fragment, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.scss";
import Loader from "./layouts/Loader/Loader";
import AuthProvider from './auth/authProvider';
import PersonasRegistradas from "./components/Pages/Personas/PersonasRegistradas";
import DocumentosPorProducto from "./components/Pages/Personas/DocumentosPorProducto";
import EmpresasRegistradas from "./components/Pages/Empresa/EmpresasRegistradas";
import InfoProvider from "./contexts/InfoContexts";
import Dashboard from "./components/Dashboard/Dashboard";
import { DashboardSaenz } from "./components/Pages/Dashboard/Dashboard";
import FormProductChoice from "./components/Pages/Productos/FormProductChoice";
import Solicitudes from "./components/Pages/Solicitudes/Solicitudes";
import { useKeycloak } from "@react-keycloak/web";
import Setting from "./components/Pages/Settings/Setting";

// Dashboard
const App = React.lazy(() => import("./components/app"));
// AdvanceUi


// Pages
const EmptyPage = React.lazy(() => import("./components/Pages/EmptyPage/EmptyPage"))
const FormPerfilPersona = React.lazy(() => import("./components/Pages/Perfil/FormPerfilPersona.tsx"))
const FormEmpresa = React.lazy(() => import("./components/Pages/Empresa/FormEmpresa.tsx"))
const LufeMainScreen = React.lazy(() => import("./components/Pages/Lufe/lufe.main.screen"))

// coustom pages
const Error505 = React.lazy(() => import("./components/Custompages/Error-505/Error-505"))
const Error404 = React.lazy(() => import("./components/Custompages/Error1-404/Error-404"))

const Custompage = React.lazy(() => import("./components/Custompage"))


const Root = () => {

  const { initialized, keycloak } = useKeycloak();
  const [isBackoffice,setIsBackoffice]        = useState();

  useEffect(() => {
    if (keycloak && initialized) {
      /*console.log("inicializado")
      console.log(keycloak)*/
      keycloak.onTokenExpired = () => keycloak.updateToken(600);
      const roles = keycloak?.tokenParsed.realm_access.roles;

      if (roles && roles.includes('BACKOFFICE_SAENZ_ONLINE')) {
        setIsBackoffice(true)
      } else {
        setIsBackoffice(false)
      }
    }

    
    
    return () => {
      if (keycloak) keycloak.onTokenExpired = () => {};
    };
    
  }, [initialized, keycloak]);

if (!initialized) {
  return <><Loader /></>
}

  return (
    <Fragment>
      <BrowserRouter>
        <React.Suspense fallback={<Loader />}>
          <InfoProvider>
          <Routes>

            <Route path={`${process.env.PUBLIC_URL}/`}
              element={<App />}>
                <Route index element={<DashboardSaenz />} />
              <Route>
                <Route
                  path={`${process.env.PUBLIC_URL}/empty`}
                  element={<EmptyPage />}
                />
                {!isBackoffice &&<>
                <Route
                  path={`${process.env.PUBLIC_URL}/perfil`}
                  element={<FormPerfilPersona />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/dashboard`}
                  element={<DashboardSaenz />}
                />
                <Route path={`${process.env.PUBLIC_URL}/frontend/`}>
                  <Route
                    path={`producto/:id/solicitar`}
                    element={<FormProductChoice />}
                  />
                </Route>
                </>}
                <Route
                  path={`${process.env.PUBLIC_URL}/empresa/registrar`}
                  element={<FormEmpresa />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/empresa/:id/`}
                  element={<FormEmpresa />}
                />
                
                {isBackoffice &&
                <>
                <Route
                  path={`${process.env.PUBLIC_URL}/personas/`}
                  element={<PersonasRegistradas />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/conf/`}
                  element={<Setting />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/backend/producto/:id`}
                  element={<DocumentosPorProducto />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/solicitudes/`}
                  element={<Solicitudes />}
                />
                
                <Route
                  path={`${process.env.PUBLIC_URL}/solicitudes/:id`}
                  element={<Solicitudes />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/empresas/`}
                  element={<EmpresasRegistradas />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/lufe/`}
                  element={<LufeMainScreen />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/*`}
                  element={<Solicitudes />}
                />
                
                </>
                }
              </Route>
              
              
            </Route>

            {/* ........................................Errorpage............................................... */}
            <Route path="*" element={<Error404 />} />
          </Routes>
          </InfoProvider>
        </React.Suspense>
      </BrowserRouter>
    </Fragment>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<AuthProvider><Root /></AuthProvider>);