import * as React from "react";
import { useState, useEffect } from 'react';
import { listCaracterUsuEmpresa } from "../../../../data/forms/GeneralData";
import Asociados from "../../../../types/empresas/Asociados"
import { tableData } from "../../../../types/general/GeneralTypes";
import PerfilCliente from "../../../../types/personas/PerfilCliente";
import { getObjectFromList } from "../../../../utils/GeneralUtils";
import DataTable, { TableColumn } from "react-data-table-component";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
interface Props {
    asociados: Asociados[];
    estado: boolean;
    setEstado: any;
}
export const ModalViewAsociados = ({asociados,estado,setEstado} : Props) => {
    const [data, setData]               = useState<Asociados[]>(asociados);
    
    const toogleModal = (t:boolean) => {
        setEstado(t);
    }

  const columns : tableData[] = [
    {
      name: "Nom/Ape",
      selector: row => {
        let t = row as Asociados;
        let cli = (t.perfilCliente as any) as PerfilCliente
        return cli ? cli.familyName : "";
        },
      sortable: true
    },
    {
        name: "Celular",
        selector: row => {
          let t = row as Asociados;
          let cli = (t.perfilCliente as any) as PerfilCliente
          return cli ? cli.celular : "";
      },
      sortable: true
    },
    {
        name: "Email",
        selector: row => {
          let t = row as Asociados;
          let cli = (t.perfilCliente as any) as PerfilCliente
          return cli ? cli.email : "";
      },
      sortable: true
    },
    {
      name: "Cuit/Cuil",
      selector: row => [row.perfilCliente.cuilCuit],
      sortable: true
    },
    {
      name: "Caracter",
      selector: row => [getObjectFromList(listCaracterUsuEmpresa,row.idCaracter).label],
      sortable: true
    }
  ];


  useEffect(() => {
    console.log(asociados)
    setData(asociados);
  },[asociados]);
  return (
    <>
        <Modal show={estado} size="lg">
            <Modal.Header
            closeButton
            onClick={() => {
                toogleModal(false);
            }}
            >
            <h6>Ver Asociados</h6>
            </Modal.Header>
            <Modal.Body>
              {data && data.length>0 && data[0].perfilCliente &&
                <DataTable
                    title
                    noDataComponent="No se encontraron registros"
                    columns={columns}
                    data={data}
                    pagination
                    highlightOnHover
                />
            }
            </Modal.Body>
            <Modal.Footer>
            <Button
                variant="primary"
                onClick={() => {
                    toogleModal(false);
                }}
                className="text-center"
            >
                <FontAwesomeIcon icon={faClose}/> Cerrar
            </Button>

            </Modal.Footer>
        </Modal>
    </>
  )
    //
}